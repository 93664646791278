import { FieldWrapper } from '@/react/components/refund/refund-management/shared';
import {
  Months,
  PaymentReceiptTypes,
  PurchaseReceiptTypes,
  SupplierType,
} from '@/react/components/refund/refund-request/invoice-step/interfaces';
import { formatDate } from '@/react/utils/date';
import { formatMoney } from '@/react/utils/money';
import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';

const receiptAttachmentsByType = (receipts, type) => {
  return receipts
    ?.filter((receipt) => type.includes(receipt.type))
    ?.flatMap((receipt) => receipt.attachments);
};

export const RefundRequestShow = ({ refundRequest, isFetching }) => {
  return (
    <Box
      sx={({ palette }) => ({
        padding: 2,
        border: `1px solid ${palette.neutral[300]}`,
        backgroundColor: `${palette.neutral[50]}`,
      })}
    >
      <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} mt={1} mb={{ xs: 1, md: 0 }}>
          <Typography
            variant="body1"
            style={{ fontWeight: '600' }}
            color="primary.200"
          >
            Detalhes do pedido
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Data do pagamento
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {formatDate(refundRequest?.payment_date)}
            </Typography>
          </FieldWrapper>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Comprovante de pagamento
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={isFetching}>
              <Typography
                variant="body3"
                style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
              >
                {receiptAttachmentsByType(
                  refundRequest?.receipts,
                  PaymentReceiptTypes
                )?.map((attachment, index) => {
                  return (
                    <Link
                      href={attachment.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      style={{ marginRight: '10px' }}
                    >
                      {attachment.content?.filename}
                    </Link>
                  );
                })}
              </Typography>
            </FieldWrapper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              {refundRequest?.reference_month !== null
                ? 'Valor da mensalidade'
                : 'Valor da compra'}
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {formatMoney(refundRequest?.purchase_amount)}
            </Typography>
          </FieldWrapper>
        </Grid>

        {refundRequest?.reference_month !== null && (
          <>
            <Grid item xs={12} md={2}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Mês de referência
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {Months[refundRequest?.reference_month]}
                </Typography>
              </FieldWrapper>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Nome do fornecedor
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {refundRequest?.supplier_name}
            </Typography>
          </FieldWrapper>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              {refundRequest?.supplier_type === SupplierType.legal_person
                ? 'CNPJ'
                : 'CPF'}
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {refundRequest?.supplier_document}
            </Typography>
          </FieldWrapper>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Valor do pagamento
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {formatMoney(refundRequest?.payment_amount)}
            </Typography>
          </FieldWrapper>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Data da compra
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3">
              {formatDate(refundRequest?.purchase_date)}
            </Typography>
          </FieldWrapper>
        </Grid>

        <Grid item xs={12} md={2}>
          <FieldWrapper loading={isFetching}>
            <Typography variant="body3" fontWeight={600}>
              Comprovante de compra
            </Typography>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid item xs={12} md={10}>
            <FieldWrapper loading={isFetching}>
              <Typography
                variant="body3"
                style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
              >
                {receiptAttachmentsByType(
                  refundRequest?.receipts,
                  PurchaseReceiptTypes
                )?.map((attachment, index) => (
                  <Link
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    style={{ marginRight: '10px' }}
                  >
                    {attachment.content?.filename}
                  </Link>
                ))}
              </Typography>
            </FieldWrapper>
          </Grid>
        </Grid>

        {refundRequest?.banking_info?.pix_key ? (
          <>
            <Grid item xs={12} md={2}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Chave PIX
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {refundRequest?.banking_info?.pix_key}
                </Typography>
              </FieldWrapper>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={2}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Número da conta
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {refundRequest?.banking_info?.account_number}
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} md={2}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Banco
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {refundRequest?.banking_info?.bank}
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} md={2}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Agência
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={10}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {refundRequest?.banking_info?.agency}
                </Typography>
              </FieldWrapper>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
