import * as React from 'react';
import enrollmentInProgressImage from '@/react/assets/images/enrollment-in-progress.svg';

import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

export const EmptyActivitiesList = () => (
  <Card square sx={{ height: '100%' }}>
    <CardContent>
      <Stack alignItems="center" direction="row" gap={2}>
        <Box
          component="img"
          src={enrollmentInProgressImage}
          sx={{ width: 88 }}
        />
        <Typography variant="caption">
          Adicione sua primeira atividade para começar a montar seu plano.
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);
