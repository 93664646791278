import { CheckResourceEligibilityRequest, ResourceTypes } from './interfaces';
import { SubsidyClient } from './subsidy-client';

export const useCheckResourceEligibility = (
  resourceApprovalRequest
) => {
    const fetchCheckResourceEligibility = async (): Promise<boolean> => {
      const payload: CheckResourceEligibilityRequest = {
        resource: {
          type: resourceApprovalRequest.resource.type,
          amount: resourceApprovalRequest.resource.amount,
          sale_type: resourceApprovalRequest.resource.sale_type,
          ...(resourceApprovalRequest.resource.type === ResourceTypes.COURSE && {
            data: {
              course: {
                id: resourceApprovalRequest.resource.data?.course?.id,
                modality: resourceApprovalRequest.resource.data?.course?.modality,
                source: resourceApprovalRequest.resource.data?.course?.source,
                course_type: resourceApprovalRequest.resource.data?.course?.course_type,
                duration: resourceApprovalRequest.resource.data?.course?.duration,
                duration_type: resourceApprovalRequest.resource.data?.course?.duration_type,
                period: resourceApprovalRequest.resource.data?.course?.period,
              },
            },
          }),
        },
      };

      try {
        const response = await SubsidyClient.checkResourceEligibility(payload);
        
        if (response.status === 200) {
          return true;
        }
      } catch (error) {
        return false;
      }
    }

    return fetchCheckResourceEligibility;
}
