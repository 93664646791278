import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { PolicyPreviewProps } from './interfaces';
import { formatMoney } from "@/react/utils/money";

export const PolicyPreview:React.FC<PolicyPreviewProps> = ({policy}) => {

  const availableBalance = policy?.limits.amount_available;

  const handleShowPolicies = () => {
    window.location.assign('/meus-reembolsos/minhas-politicas');
  };

  return (
      <Box sx={({palette}) => ({
        maxHeight: '80px',
        width: { xs: '100%', lg: 'auto' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: `${palette.neutral[50]}`,
        border:`1px solid ${palette.neutral[300]}`,
        p: 2,
      })}>
        {availableBalance && (
          <Stack direction={'column'} gap={1}>
            <Typography variant="body3" color="neutral.800" noWrap>
              Saldo disponível
            </Typography>
            <Typography variant="h6" color="success.700" data-testid="refund-balance">
              {formatMoney(Number(availableBalance))}
            </Typography>
          </Stack>
        )}
        <Button
          color="primary"
          size="small"
          endIcon={<ArrowForward />}
          variant="text"
          onClick={handleShowPolicies}
          sx={({palette})=>({
            borderRadius: 0,
            alignSelf: 'flex-end',
            textDecoration: 'underline',
            ':hover':{
              textDecoration: 'underline',
              color: palette.primary[900],
              backgroundColor: palette.primary[300],
            }
          })}
        >
          {availableBalance && availableBalance !== '0' ? 'Ver regras' : 'Ver regras de reembolso'}
        </Button>
      </Box>
  );
};
