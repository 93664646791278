import React from 'react';
import { Typography } from '@mui/material';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { Plan } from '@/react/data/idp/plans';
import HelpCenterImage from '@/react/assets/images/help-center.svg';

export interface ConfirmDeletePlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: Plan;
}

const handleDeletePlan = async ({ plan, onSuccess, onError }) => {
  try {
    await IdpClient.deletePlan(plan.id);
    onSuccess();
  } catch (error) {
    onError('Ocorreu um erro ao excluir o plano. Tente novamente mais tarde');
    Sentry.captureException(error);
  }
};

export const ConfirmDeletePlanDialog = ({
  open,
  onClose,
  plan,
}: ConfirmDeletePlanDialogProps) => {
  const { toast } = useNotification();

  const listeners = {
    onError: (error: string) => {
      toast({
        content: error,
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      window.location.href = '/pdi/planos';
    },
  };

  return (
    <IdpConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={() => handleDeletePlan({ plan, ...listeners })}
      actionLabels={{
        confirm: 'Excluir plano',
        cancel: 'Voltar',
      }}
      image={HelpCenterImage}
      title="Tem certeza de que quer excluir este plano?"
      subtitle={plan.title}
      description="Todas as informações e o progresso serão removidos, e essa ação não poderá ser desfeita."
    />
  );
};
