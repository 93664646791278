import * as React from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Card,
  Link,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const defaultComponentsMap = {
  // texts
  a: ({ href, children }) => <Link href={href}>{children}</Link>,
  p: ({ node, children }) => {
    // handle checkboxes
    const isCheckbox = node.children.some(
      (child) =>
        child.type === 'element' &&
        child.tagName === 'input' &&
        child.properties.type === 'checkbox'
    );

    if (isCheckbox) {
      return children;
    }

    return (
      <Typography sx={{ mb: 2 }} variant="body2">
        {children}
      </Typography>
    );
  },
  em: ({ children }) => (
    <Typography component="span" sx={{ fontStyle: 'italic' }} variant="body2">
      {children}
    </Typography>
  ),
  strong: ({ children }) => (
    <Typography component="span" sx={{ fontWeight: 'bold' }} variant="body2">
      {children}
    </Typography>
  ),
  b: ({ children }) => (
    <Typography component="span" sx={{ fontWeight: 'bold' }} variant="body2">
      {children}
    </Typography>
  ),
  h1: ({ children }) => (
    <Typography
      component="h1"
      fontWeight="bold"
      gutterBottom
      variant="subtitle1"
    >
      {children}
    </Typography>
  ),
  h2: ({ children }) => (
    <Typography
      component="h2"
      fontWeight="semibold"
      gutterBottom
      variant="subtitle1"
    >
      {children}
    </Typography>
  ),
  h3: ({ children }) => (
    <Typography component="h3" fontWeight="semibold" gutterBottom variant="h4">
      {children}
    </Typography>
  ),
  h4: ({ children }) => (
    <Typography component="h4" fontWeight="bold" gutterBottom variant="h4">
      {children}
    </Typography>
  ),
  h5: ({ children }) => (
    <Typography component="h5" fontWeight="bold" gutterBottom variant="body1">
      {children}
    </Typography>
  ),
  h6: ({ children }) => (
    <Typography component="h6" fontWeight="bold" gutterBottom variant="button">
      {children}
    </Typography>
  ),
  // lists
  ul: ({ children }) => (
    <List
      sx={{
        listStyleType: 'disc',
        mb: 2,
        pl: 3.5,
        py: '0',

        '&  ul': {
          my: 0,
        },
      }}
    >
      {children}
    </List>
  ),
  ol: ({ children }) => (
    <List
      component="ol"
      sx={{
        listStyleType: 'decimal',
        mb: 2,
        pl: 3,
        py: '0',

        '&  ul': {
          my: 0,
          pl: 2,
        },
      }}
    >
      {children}
    </List>
  ),
  li: ({ className, children }) => {
    return (
      <ListItem
        className={className}
        sx={{
          display: 'list-item',
          py: 0,

          '&  p.MuiTypography-root': {
            mb: '0',
          },

          '&.task-list-item': {
            '& > .MuiListItemText-root': {
              position: 'relative',
              pl: 3,

              '& .checkbox-icon': {
                position: 'absolute',
                left: -4,
              },
            },
          },
        }}
        disableGutters
      >
        <Typography component="span" variant="body2">
          {children}
        </Typography>
      </ListItem>
    );
  },
  //tables
  table: ({ children }) => (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">{children}</Table>
    </TableContainer>
  ),
  tbody: ({ children }) => <TableBody>{children}</TableBody>,
  thead: ({ children }) => <TableHead>{children}</TableHead>,
  tr: ({ children }) => <TableRow>{children}</TableRow>,
  td: ({ children }) => <TableCell>{children}</TableCell>,
  th: ({ children }) => (
    <TableCell sx={{ fontWeight: 'bold' }}>{children}</TableCell>
  ),
  // code
  pre: ({ children }) => (
    <Card sx={{ mb: 3, p: 2 }}>
      <Typography
        component="pre"
        sx={{
          m: '0',
          code: {
            backgroundColor: 'transparent',
            border: 0,
            boxShadow: 'none',
            p: '0',
          },
        }}
        variant="body2"
      >
        {children}
      </Typography>
    </Card>
  ),
  code: ({ className, children }) => {
    const languageMatch = /language-(\w+)/.exec(className || '');
    return (
      <Card
        component="code"
        sx={{
          backgroundColor: 'neutral.300',
          fontSize: '0.85em',
          px: 0,
          py: 0.2,
        }}
      >
        {String(children).replace(/\n$/, '')}
      </Card>
    );
  },
  // tasklists
  input: ({ checked, type }) => {
    if (type === 'checkbox') {
      return checked ? (
        <CheckBoxIcon className="checkbox-icon" color="primary" />
      ) : (
        <CheckBoxOutlineBlankIcon className="checkbox-icon" />
      );
    }
  },
};

export const MarkdownRenderer = ({
  componentsMap,
  markdown,
}: {
  componentsMap?: Components;
  markdown: string;
}) => {
  const components = {
    ...defaultComponentsMap,
    ...componentsMap,
  };

  return (
    <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
      {markdown}
    </ReactMarkdown>
  );
};
