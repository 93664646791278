import * as React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';

const StyledList = styled('ol')`
  counter-reset: item;
  list-style: none;
  margin: 0;
  padding-left: ${({ theme }) => theme.spacing(3)};

  li {
    counter-increment: item;
    font-size: ${({ theme }) => theme.typography.body3.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    position: relative;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li:before {
    color: ${({ theme }) => theme.palette.primary[200]};
    content: counter(item) '.';
    display: block;
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    left: ${({ theme }) => theme.spacing(-3)};
    position: absolute;
    top: 0;
  }
`;

export const localStorageKey = 'hide-idp-onboarding-dialog';

export const IdpOnboardingDialog = ({
  callback,
  onClose,
  open: forceOpen,
}: {
  callback?: () => void;
  onClose?: () => void;
  open?: boolean;
}) => {
  const bypassOpen = !!localStorage.getItem(localStorageKey);
  const [open, setOpen] = React.useState(!!forceOpen);
  const [neverOpenAgain, setNeverOpenAgain] = React.useState(bypassOpen);
  const [buttonLabel, setButtonLabel] = React.useState('Fechar');
  const [awaitingCallback, setAwaitingCallback] = React.useState(false);

  React.useEffect(() => {
    if (!!callback) {
      setButtonLabel('Continuar');
    }
  }, [callback]);

  React.useEffect(() => {
    if (!open && forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNeverOpenAgain(event.target.checked);
  };

  const handleNeverOpenAgain = () => {
    if (neverOpenAgain) {
      localStorage.setItem(localStorageKey, 'true');
    } else {
      localStorage.removeItem(localStorageKey);
    }
  };

  const handleClose = async () => {
    handleNeverOpenAgain();

    if (typeof onClose === 'function') {
      await onClose();
    }

    setOpen(false);
  };

  const handleCallback = async () => {
    try {
      setAwaitingCallback(true);
      await callback();
      handleNeverOpenAgain();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setAwaitingCallback(false);
    }
  };

  const buttonAction = !!callback ? handleCallback : handleClose;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Como funciona o plano de desenvolvimento</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: ({ palette }) => palette.grey[500],
          position: 'absolute',
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          p={3}
          sx={{
            p: 3,
            backgroundColor: ({ palette }) => palette.primary['100'],
          }}
        >
          <StyledList>
            <li>
              Ao criar seu plano, você pode receber uma recomendação
              personalizada por Inteligência Artificial, baseada nos seus
              objetivos, ou montar o seu próprio caminho.
            </li>
            <li>
              Defina a duração do plano. Ter um prazo definido ajuda a priorizar
              atividades e manter o foco na execução.
            </li>
            <li>
              Escolha as atividades, cursos e conteúdos que deseja realizar
              dentro do período definido.
            </li>
            <li>
              Com o planejamento pronto, inicie seu plano e acompanhe seu
              progresso. Você poderá marcar as atividades em andamento e as já
              concluídas, facilitando sua organização.
            </li>
            <li>
              Quando se sentir pronto, finalize o plano e celebre seu avanço!
            </li>
          </StyledList>
        </Box>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          data-testid="never-open-again-checkbox"
          control={
            <Checkbox onChange={handleCheckbox} checked={neverOpenAgain} />
          }
          label={<Typography variant="body3">Não mostrar novamente</Typography>}
          sx={{ marginRight: 'auto' }}
        />
        <Button
          color="primary"
          data-testid={!!callback ? 'button-action' : 'button-close'}
          loading={awaitingCallback}
          onClick={buttonAction}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
