import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { BaseDialogTitle } from '@/react/components/dialog/base-dialog-title.component';
import { BaseDialogActions } from '@/react/components/dialog/base-dialog-actions.component';

export type BaseDialogProps = {
  actionsComponent?: React.ReactNode;
  onClose: () => void;
  title?: string | React.ReactNode;
  reverseMobileDirection?: boolean;
} & Omit<DialogProps, 'onClose' | 'title'>;

export const BaseDialog = ({
  actionsComponent,
  children,
  onClose,
  title,
  reverseMobileDirection = false,
  ...dialogProps
}: BaseDialogProps) => {
  const { disableEscapeKeyDown = false } = dialogProps;

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      {...dialogProps}
    >
      <BaseDialogTitle onClose={onClose}>{title}</BaseDialogTitle>
      <DialogContent>{children}</DialogContent>
      <BaseDialogActions
        onClose={onClose}
        reverseMobileDirection={reverseMobileDirection}
      >
        {actionsComponent}
      </BaseDialogActions>
    </Dialog>
  );
};
