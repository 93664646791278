import Accordion from '@/react/components/refund/accordion/accordion.component';
import { AccordionSteps } from '@/react/components/refund/accordion/interfaces';
import { AccordionProvider } from '@/react/components/refund/accordion/state/context';
import { State as AccordionState } from '@/react/components/refund/accordion/state/interfaces';
import ExtensiveOverview from '@/react/components/refund/refund-request/description-step/refund-details/extensive-overview/extensive-overview.component';
import { HistoryDecisionType } from '@/react/data/subsidy/interfaces';
import { Chip, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { ResourceApprovalDescriptionProps, ResumeProps } from './intefaces';

const VariantType = {
  [HistoryDecisionType.REJECTED]: 'negativeRequestResponse',
  [HistoryDecisionType.CANCELED]: 'negativeRequestResponse',
  [HistoryDecisionType.REVISION_REQUIRED]: 'actionNeededAfterRevision',
  [HistoryDecisionType.APPROVED]: 'waitingForConclusion',
  DEFAULT: 'waitingForRevision',
} as const;

const Resume: React.FC<ResumeProps> = ({ resourceApproval }) => {
  const institutionName = resourceApproval?.resource?.course?.institution_name;
  const resourceName = resourceApproval?.name?.split(', ')[0];
  const displayName = [resourceName, institutionName]
    .filter(Boolean)
    .join(' | ');
  const lastHistoryEntry = resourceApproval?.history?.at(-1);

  return (
    <Grid
      container
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
        <Typography
          variant="body1"
          style={{ fontWeight: '600' }}
          color="primary.200"
        >
          Descrição do item
        </Typography>
        <Grid
          item
          xs={12}
          justifyContent="center"
          sx={({ palette, spacing }) => ({
            color: palette.neutral[800],
            marginBottom: spacing(2),
            paddingBottom: spacing(0.5),
            paddingTop: spacing(0.5),
          })}
        >
          <Typography variant="body3">{displayName}</Typography>
        </Grid>
        {lastHistoryEntry && (
          <Grid item xs={12}>
            <Chip
              label={lastHistoryEntry.message}
              variant={
                VariantType[lastHistoryEntry.decision] ?? VariantType.DEFAULT
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const detailAccordionSteps = ({ resourceApprovalsDetails }) => {
  const schema: AccordionSteps = {
    INIT: {
      disabled: false,
      expanded: false,
      renderContent: () => (
        <ExtensiveOverview resourceApproval={resourceApprovalsDetails} />
      ),
      renderHeader: () => (
        <Resume resourceApproval={resourceApprovalsDetails} />
      ),
      status: null,
    },
  };

  return schema;
};

export const ResourceApprovalDetailsAccordion: React.FC<
  ResourceApprovalDescriptionProps
> = ({ resourceApprovalsDetails }) => {
  const steps = React.useMemo(() => {
    return detailAccordionSteps({
      resourceApprovalsDetails,
    });
  }, [resourceApprovalsDetails]);

  const initialAccordionState: AccordionState = {
    steps,
  };

  return (
    <AccordionProvider initialState={initialAccordionState}>
      <Accordion />
    </AccordionProvider>
  );
};
