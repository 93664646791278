import React, { useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import Dialog from '@mui/material/Dialog';
import { styledBoxes } from '../utils/styled-boxes';
import { QuizContext, QuizState, useQuiz } from '../state';
import { QuestionWrapper } from './question-wrapper';
import { componentByKind } from './component-mapping';
import { Stack } from '@mui/material';
import { QuestionNames } from '@/react/components/profile-quiz/editing-profile-settings';

const Styled = styledBoxes('Quiz', {
  Wrapper: {
    width: '100%',
    height: '100%',
  },
  Placeholder: {},
});

export function Quiz(props: QuestionProps) {
  let { className, state, variation, onFinish } = props;

  const [isExploding, setIsExploding] = React.useState(false);

  useEffect(() => {
    if (!state) return;

    return state.observe(
      (s) => {
        return s.activeNodeId;
      },
      async ({ previous, next }) => {
        const finished =
          previous === QuestionNames.presentation_finished && next === null;

        if (finished) {
          setIsExploding(true);
          onFinish();
        }
      }
    );
  }, [state]);

  return (
    <>
      {isExploding && (
        <Stack alignItems="center">
          <ConfettiExplosion zIndex={1} />
        </Stack>
      )}

      {state && (
        <QuizContext value={state}>
          <Styled.Wrapper className={className}>
            <QuestionActive variation={variation} />
          </Styled.Wrapper>
        </QuizContext>
      )}
    </>
  );
}

function QuestionActive(props: { variation: QuizComponentVariation }) {
  let { variation } = props;

  const [{ kind, open }] = useQuiz((state) => {
    const node = state.nodeById[state.activeNodeId];

    return {
      kind: node?.kind,
      open: !!node,
    };
  });

  const children = () =>
    open ? (
      <QuestionWrapper
        kind={kind}
        render={(props) => React.createElement(componentByKind[kind], props)}
      />
    ) : (
      <Styled.Placeholder />
    );

  return variation === 'modal' ? (
    <Dialog open={open}>{children()}</Dialog>
  ) : (
    children()
  );
}

export type QuizComponentVariation = 'modal' | 'inline';

export type QuestionProps = {
  className?: string;
  state?: QuizState | null | undefined;
  variation: QuizComponentVariation;
  onFinish(): void;
};
