import { loadBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { RouterService } from '../router.service';

export const featureLearningPathsGuard: CanActivateChildFn = async () => {
  const routerService = inject(RouterService);
  const { plan } = await loadBootstrapData();

  if (plan?.features?.find((feature) => feature.name === 'learning_paths')) {
    return true;
  }

  routerService.home();
  return false;
};
