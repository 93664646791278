import React, { ReactNode } from 'react';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { Container } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

const DEFAULT_MAX_WIDTH = 'xl';

export const PageContainer = ({
  maxWidth,
  children,
}: {
  maxWidth?: Breakpoint;
  children: ReactNode | ReactNode[];
}) => {
  const { isSmallScreen, isXLargeScreen } = useScreenSizes();

  const calculatedMaxWidth =
    maxWidth || (isXLargeScreen ? DEFAULT_MAX_WIDTH : undefined);

  return (
    <Container fixed={isSmallScreen} maxWidth={calculatedMaxWidth}>
      {children}
    </Container>
  );
};
