import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  ToggleButton,
  Typography,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { ContentBox } from '../shared/content-box.component';

const monthsShortcuts = [6, 12];

export const validationSchema = z.object({
  durationInMonths: z
    .number()
    .min(1, 'Selecione uma opção para continuar.')
    .max(18),
});

const mountDurationOptions = ({ onClick }) => {
  const options = [];
  for (let i = 3; i <= 18; i += 1) {
    options.push(
      <MenuItem key={i} value={i} onClick={onClick}>
        <Typography variant="body2">{i} meses</Typography>
      </MenuItem>
    );
  }
  return options;
};

const ToggleButtonWithSelect = ({ onChange, selected, value }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ButtonText = () => {
    const text = selected ? `${value} meses` : 'Outro período';

    return (
      <>
        <Typography variant="body2">{text}</Typography>
        <ExpandMoreRoundedIcon />
      </>
    );
  };

  return (
    <>
      <ToggleButton value="custom" onChange={handleOpen} selected={selected}>
        <ButtonText />
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl && anchorEl.offsetWidth,
              maxHeight: 260,
            },
          },
        }}
        transitionDuration={0}
      >
        {mountDurationOptions({
          onClick: (event) => {
            onChange(null, event.currentTarget.value);
            handleClose();
          },
        })}
      </Menu>
    </>
  );
};

export const PlanDurationForm: React.ElementType<WizardStepComponent> = ({
  onNext,
}) => {
  const [customDuration, setCustomDuration] = React.useState<boolean>(false);
  const { values } = useWizard();

  const defaultValues = {
    durationInMonths: values[CreatePlanWizardSteps.DURATION] || 0,
  };

  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { durationInMonths } = watch();

  const handleChange = (_, value, isCustomDuration) => {
    setCustomDuration(isCustomDuration);
    if (value !== null) {
      setValue('durationInMonths', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleNext = async (data) => {
    if (onNext) {
      onNext({ stepValues: data.durationInMonths });
    }
  };

  React.useEffect(() => {
    if (durationInMonths && !monthsShortcuts.includes(durationInMonths)) {
      setCustomDuration(true);
    }
  }, [durationInMonths]);

  return (
    <>
      <ContentBox mt={2} p={3}>
        <Typography variant="h6" color="primary.200">
          Você gostaria de realizar o plano em quanto tempo?
        </Typography>

        <Box mt={3}>
          <form onSubmit={handleSubmit(handleNext)}>
            <ToggleButtonGroup
              aria-label="duration in months"
              color="primary"
              exclusive
              fullWidth
              onChange={(_, value) => handleChange(_, value, false)}
              size="large"
              value={durationInMonths}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <ToggleButton
                    value={6}
                    {...(customDuration && { selected: false })}
                  >
                    <Typography variant="body2">6 meses</Typography>
                  </ToggleButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ToggleButton
                    value={12}
                    {...(customDuration && { selected: false })}
                  >
                    <Typography variant="body2">1 ano</Typography>
                  </ToggleButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ToggleButtonWithSelect
                    onChange={(_, value) => handleChange(_, value, true)}
                    selected={customDuration}
                    value={durationInMonths}
                  />
                </Grid>
                {formState.isSubmitted &&
                  formState.errors['durationInMonths'] && (
                    <Grid item xs={12}>
                      <Alert severity="error">
                        {formState.errors['durationInMonths'].message as string}
                      </Alert>
                    </Grid>
                  )}
              </Grid>
            </ToggleButtonGroup>
            <WizardStepActions>
              <Button
                color="primary"
                onClick={() => {
                  trigger();
                }}
                type="submit"
                variant="contained"
              >
                Próximo
              </Button>
            </WizardStepActions>
          </form>
        </Box>
      </ContentBox>
    </>
  );
};
