import * as React from 'react';
import { Grid2 as Grid, Stack, Typography } from '@mui/material';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { Activity, ActivityStatuses, Plan } from '@/react/data/idp/interfaces';
import { ActivityProgress } from '@/react/components/idp/activity-progress.component';
import { ActivitiesList } from '@/react/components/idp/activities-list';
import { Loading as LoadingActivities } from '@/react/components/idp/activities-list/activities-list.component';
import { PlanCard } from '@/react/components/idp/home/components';

const sortActivities = (activities: Activity[]): Activity[] => {
  const sortedActivities = activities.sort((a, b) => {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  const activititesByStatus = sortedActivities.reduce((acc, activity) => {
    acc[activity.status] = acc[activity.status] || [];
    acc[activity.status].push(activity);

    return acc;
  }, {});

  const result = [
    ...(activititesByStatus[ActivityStatuses.IN_PROGRESS] || []),
    ...(activititesByStatus[ActivityStatuses.PENDING] || []),
    ...(activititesByStatus[ActivityStatuses.COMPLETED] || []),
  ];

  return result;
};

export const PlanCardInProgress = ({
  activities,
  loadingActivities,
  plan,
}: {
  activities: Activity[];
  loadingActivities: boolean;
  plan: Plan;
}) => {
  const { desktopSize } = useScreenSizes();
  const hasActivities = activities.length > 0;
  const sortedActivities = sortActivities(activities);

  const activitiesSeparatedByStatus = React.useMemo(() => {
    return Activity.getAmountOfActivitiesByStatus(activities);
  }, [activities]);

  return (
    <PlanCard cardActionLabel="Continuar plano" plan={plan} hideTitle>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Stack spacing={1.5}>
            <Typography variant="body2" fontWeight="bold">
              {plan.title || 'Plano sem título'}
            </Typography>
            <Typography variant="body3">
              <strong>Duração:</strong>{' '}
              {(plan.durationInMonths && `${plan.durationInMonths} meses`) ||
                'Sem duração'}
            </Typography>
            {plan.estimatedEndsAt && (
              <Typography data-testid="planCardInProgressDates" variant="body3">
                <strong>Fim estimado:</strong> {plan.estimatedEndsAt}
              </Typography>
            )}
            {hasActivities && (
              <ActivityProgress
                data={{
                  [ActivityStatuses.IN_PROGRESS]:
                    activitiesSeparatedByStatus[ActivityStatuses.IN_PROGRESS],
                  [ActivityStatuses.COMPLETED]:
                    activitiesSeparatedByStatus[ActivityStatuses.COMPLETED],
                  [ActivityStatuses.PENDING]:
                    activitiesSeparatedByStatus[ActivityStatuses.PENDING],
                }}
                legend={false}
              />
            )}
          </Stack>
        </Grid>
        {desktopSize && (
          <Grid size={{ xs: 12, md: 8 }}>
            {loadingActivities && <LoadingActivities />}
            {hasActivities && (
              <ActivitiesList activities={sortedActivities} limit={3} />
            )}
          </Grid>
        )}
      </Grid>
    </PlanCard>
  );
};
