import React from 'react';
import {
  Button,
  DialogContent,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  TextField,
  Typography,
} from '@mui/material';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { BaseDialogTitle } from '@/react/components/dialog/base-dialog-title.component';
import { BaseDialogActions } from '@/react/components/dialog/base-dialog-actions.component';
import { ActivityResponseType } from '@/react/data/idp/interfaces';
import { createActivitySchema } from '@/react/data/idp/schemas';
import { useCreatePlanActivity } from '@/react/data/idp';

export interface CreateActivityDialogProps
  extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  planId: string;
}

export const CreateActivityDialog = ({
  onClose,
  open,
  planId,
  ...dialogProps
}: CreateActivityDialogProps) => {
  const { createPlanActivity } = useCreatePlanActivity(planId);
  const { control, formState, handleSubmit } = useForm({
    resolver: zodResolver(createActivitySchema),
    defaultValues: {
      description: '',
      title: '',
    },
  });

  const handleSave = async (data: ActivityResponseType) => {
    try {
      await createPlanActivity(data);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        open={open}
        {...dialogProps}
      >
        <BaseDialogTitle onClose={handleClose}>Nova atividade</BaseDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              <Controller
                name="title"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Nome da atividade</FormLabel>
                      <TextField
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        id="title"
                        placeholder="Dê um nome para a sua atividade"
                        variant="outlined"
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid size={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">
                        Descrição
                        <Typography component="span" variant="caption" ml={1}>
                          Opcional
                        </Typography>
                      </FormLabel>
                      <TextField
                        {...field}
                        id="description"
                        multiline
                        placeholder="Adicionar descrição"
                        rows={4}
                        variant="outlined"
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <BaseDialogActions onClose={handleClose}>
          <Button onClick={handleClose} variant="text">
            Cancelar
          </Button>
          <Button
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
            onClick={handleSubmit(handleSave)}
            variant="contained"
          >
            Salvar
          </Button>
        </BaseDialogActions>
      </Dialog>
    </>
  );
};
