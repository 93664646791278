import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from '@/react/components/dialog/confirm-dialog.component';

export interface IdpConfirmDialogProps
  extends Omit<ConfirmDialogProps, 'children'> {
  description: string;
  image?: string;
  subtitle: string;
  title: string;
}

export const IdpConfirmDialog = ({
  actionLabels,
  description,
  image,
  onClose,
  onConfirm,
  open,
  subtitle,
  title,
}: IdpConfirmDialogProps) => {
  return (
    <ConfirmDialog
      actionLabels={actionLabels}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      reverseMobileDirection={true}
    >
      <Box
        component="img"
        src={image}
        sx={{ height: 128, width: 128, margin: '0 auto 8px' }}
      />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {subtitle}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {description}
      </Typography>
    </ConfirmDialog>
  );
};
