const SHOW_PATH = '/comprovante';
const EDIT_PATH = '/editar-comprovante';
const PAYMENT_PATH = '/pagamento';
const VALIDATION_PATH = '/validacao';
const EDIT_RESOURCE_APPROVAL_PATH = '/editar-solicitacao';

export const isShowPath = () => window.location.pathname.endsWith(SHOW_PATH);
export const isEditPath = () => window.location.pathname.endsWith(EDIT_PATH);
export const isPaymentPath = () =>
  window.location.pathname.endsWith(PAYMENT_PATH);
export const isValidationPath = () =>
  window.location.pathname.endsWith(VALIDATION_PATH);
export const isEditResourceApprovalPath = () =>
  window.location.pathname.endsWith(EDIT_RESOURCE_APPROVAL_PATH);
