import React from 'react';
import { Button, Grid2 as Grid, Typography } from '@mui/material';

interface ActionProps {
  label: string;
  description?: string;
  alignRight?: boolean;
  Dialog: (props: any) => React.JSX.Element;
}

export const Action = ({ label, description, Dialog }: ActionProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Grid container spacing={2} className="media-print-remove">
      <Grid size={12}>
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          fullWidth
        >
          {label}
        </Button>
      </Grid>
      {description && (
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption">{description}</Typography>
        </Grid>
      )}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Grid>
  );
};
