import {
  RefundResourceStatus,
  ResourceApproval,
} from '@/react/data/subsidy/interfaces';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';

interface ShouldShowEditButtonProps {
  resourceApproval: ResourceApproval | null;
  isExpanded?: boolean;
}

export const shouldShowEditButton = ({
  resourceApproval,
  isExpanded = false,
}: ShouldShowEditButtonProps): boolean => {
  const isExternalCourse =
    resourceApproval?.resource?.course?.source ===
    ResourceCourseSources.EXTERNAL;

  const isResourcePending =
    resourceApproval?.status === RefundResourceStatus.pending_approval;

  const hasRefundRequest = resourceApproval?.refund_request !== null;

  return (
    isResourcePending && isExternalCourse && !hasRefundRequest && !isExpanded
  );
};
