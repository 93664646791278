import React from 'react';

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
export interface ActionsMenuProps {
  actions: {
    label: string;
    onClick: () => void;
    Icon?: React.ReactNode;
  }[];
  collapsed?: boolean;
}

export const ActionsMenu = ({
  actions,
  collapsed = false,
}: ActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { mobileSize } = useScreenSizes();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="media-print-remove">
      {collapsed || mobileSize ? (
        <IconButton
          id="actions-button"
          aria-label="mais opções"
          aria-controls={open ? 'actions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          id="actions-button"
          aria-label="mais opções"
          aria-controls={open ? 'actions-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          variant="text"
          endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleClick}
        >
          Opções
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {actions.map(({ onClick, label, Icon }) => (
          <MenuItem key={label} onClick={onClick}>
            {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
            <Typography variant="body3">{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
