import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Activity } from '@/react/data/idp/activities';
import { DragHandle as MaterialDragHandle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const reorder = (
  list: Activity[],
  startIndex: number,
  endIndex: number
): Activity[] => {
  const reorderedList = list;
  const [removed] = reorderedList.splice(startIndex, 1);
  reorderedList.splice(endIndex, 0, removed);

  return reorderedList;
};

const DragHandle = styled(MaterialDragHandle)`
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto 0;
`;

interface ReorderActivitiesProps {
  activities: Activity[];
  planId: string;
  updateActivities: (activities: Activity[]) => void;
}

export const ReorderActivities = ({
  activities,
  updateActivities,
}: ReorderActivitiesProps) => {
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const reorderedItems = reorder(
        activities,
        result.source.index,
        result.destination.index
      );

      updateActivities(reorderedItems);
    },
    [activities, updateActivities]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {activities.map((activity, index) => (
              <Draggable
                key={activity.id}
                draggableId={activity.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Box
                      sx={(theme) => ({
                        height: '40px',
                        position: 'relative',
                        border: `1px solid ${theme.palette.neutral[300]}`,
                        py: 1,
                        px: 2,
                      })}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          width: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {index + 1}. {activity.title}
                      </Typography>
                      <DragHandle />
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
