import { Stack, Typography } from '@mui/material';
import React from 'react';
import { PlanCard } from '@/react/components/idp/home/components';

interface PlanCardClosed extends React.ComponentProps<typeof PlanCard> {}

export const PlanCardClosed: React.FC<PlanCardClosed> = ({
  plan,
  children,
}) => {
  return (
    <PlanCard cardActionLabel="Ver plano" plan={plan}>
      <Stack spacing={0}>
        <Typography variant="body3">
          {(plan.durationInMonths && `${plan.durationInMonths} meses`) ||
            'Sem duração'}
        </Typography>
        {children}
      </Stack>
    </PlanCard>
  );
};
