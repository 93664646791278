import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ResourceApprovalDescriptionProps } from './intefaces';
import { formatDate } from '@/react/utils/date';

export const ResourceApprovalRequestData: React.FC<
  ResourceApprovalDescriptionProps
> = ({ resourceApprovalsDetails }) => {
  return (
    <>
      <Box
        p={2}
        my={2}
        sx={({ palette }) => ({
          border: `1px solid ${palette.neutral[300]}`,
          backgroundColor: `${palette.neutral[50]}`,
        })}
      >
        <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: '600' }}
              color="primary.200"
            >
              Dados da solicitação
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Data da solicitação
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {formatDate(resourceApprovalsDetails.requested_at)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Solicitante
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {resourceApprovalsDetails?.user?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography variant="body3" fontWeight={600}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="body3">
              {resourceApprovalsDetails?.user?.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
