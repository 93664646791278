import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { Activity } from './interfaces';
import { useGetPlanKey } from '@/react/data/idp/usePlan';

export function useReorderActivities(planId: string): useReorderActivitiesType {
  const queryClient = useQueryClient();

  const { mutateAsync: reorderActivities } = useMutation({
    mutationFn: async (activities: Activity[]) => {
      const activitiesIds = activities.map((activity) => ({ id: activity.id }));
      return IdpClient.reorderActivities(planId, activitiesIds);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [...useGetPlanKey, planId],
      });
    },
  });

  return {
    reorderActivities,
  };
}

type useReorderActivitiesType = {
  reorderActivities: (activities: Activity[]) => Promise<Activity[]>;
};
