import * as React from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useNotification } from '@/react/components/notification';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';

import { CustomDatePicker } from '@/react/components/date-picker';
import { MoneyField } from '@/react/components/form';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { isEditPath } from '@/react/components/refund/utils/path-utils';
import { PHONE_REGEX } from '@/react/components/refund/utils/phone-regex';
import {
  getFromSession,
  removeFromSession,
  saveToSession,
} from '@/react/components/refund/utils/session-utils';
import StepActions from '@/react/components/wizard/step-actions.component';
import { banksSelectOptions } from '@/react/data/bank';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';

import {
  Months,
  OtherReceiptType,
  PaymentReceipts,
  PurchaseReceipts,
  SupplierType,
  TypeKeyPix,
  TypeOfBankTransfer,
} from '../interfaces';
import FileUpload from './file-upload.component';
import { formatFormValues } from './formValuesHelper';
import { NoAvailableBalance } from './no-available-balance.component';
import { CNPJValidator, CPFValidator } from './validators';
import { CustomTooltip } from './custom-tooltip.component';

const CustonHelpRoundedIcon = styled(HelpRoundedIcon)(({ theme }) => ({
  color: theme.palette.neutral[900],
  marginRight: '8px',
  fontSize: 'small',
  height: '20px',
  width: '20px',
}));

const Tooltips = {
  MONTH: 'month',
  CPF: 'cpf',
};

const parseDate = (dateString) => {
  if (!dateString) return null;

  let parsedDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy', {
    locale: 'pt-BR',
  });

  if (!parsedDate.isValid) {
    parsedDate = DateTime.fromISO(dateString, { locale: 'pt-BR' });
  }

  return parsedDate.isValid ? parsedDate : null;
};

const NextButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    float: 'right',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const defaultValues = {
  supplierDocument: '',
  typeOfReceiptPurchase: '',
  typeOfReceiptPayment: '',
  referenceMonth: '',
  purchaseDate: '',
  paymentDate: '',
  paymentAmount: '',
  purchaseAmount: '',
  holderName: '',
  documentEmployeer: '',
  documentEmployeerText: '',
  supplierName: '',
  supplierType: 'legal_person',
  typeOfBankTransfer: 'pix',
  bank: '',
  pixKey: '',
  pixType: '',
  accountNumber: '',
  agency: '',
  purchaseAttachments: [],
  paymentAttachments: [],
  optionalFiles: [],
};

const mainValidationSchema = z.object({
  holderName: z
    .string()
    .min(1, { message: 'Nome é obrigatório.' })
    .regex(/^\S+\s+\S+.*$/, {
      message: 'Por favor, insira o nome completo (nome e sobrenome).',
    })
    .refine(
      (value) => {
        const parts = value.trim().split(/\s+/);
        return parts.length >= 2 && parts.every((part) => part.length > 1);
      },
      {
        message: 'Cada parte do nome deve ter pelo menos 2 caracteres.',
      }
    ),
  typeOfReceiptPurchase: z.string().min(1, 'Tipo de comprovante obrigatório'),
  typeOfReceiptPayment: z.string().min(1, 'Tipo de comprovante obrigatório'),
  paymentDate: z.string().superRefine((value, ctx) => {
    const [day, month, year] = value.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Data inválida.',
      });
    }
  }),
  purchaseDate: z.string().superRefine((value, ctx) => {
    const [day, month, year] = value.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Data inválida.',
      });
    }
  }),
  paymentAmount: z.coerce
    .number()
    .min(1, 'Valor da mensalidade obrigatório')
    .positive('Valor deve ser maior que zero'),
  purchaseAmount: z.coerce
    .number()
    .min(1, 'Valor pago obrigatório')
    .positive('Valor deve ser maior que zero'),
  supplierName: z.string().min(1, 'Nome do fornecedor obrigatório'),
});

const cnpjSchema = z
  .string()
  .refine((value) => /^\d{14}$/.test(value.replace(/\D/g, '')), {
    message: 'CNPJ deve conter 14 dígitos numéricos.',
  })
  .transform((value) => value.replace(/\D/g, ''))
  .refine((cnpj) => new CNPJValidator().validateDocument(cnpj), {
    message: 'CNPJ inválido.',
  });

const cpfSchema = z
  .string()
  .refine((value) => /^\d{11}$/.test(value.replace(/\D/g, '')), {
    message: 'CPF deve conter 11 dígitos numéricos.',
  })
  .transform((value) => value.replace(/\D/g, ''))
  .refine((cpf) => new CPFValidator().validateDocument(cpf), {
    message: 'CPF inválido.',
  });

const validateCPFSchema = z.object({
  documentEmployeer: cpfSchema,
});

const validateCNPJCPFSchema = z
  .object({
    supplierType: z.nativeEnum(SupplierType, {
      errorMap: () => {
        return { message: 'Tipo de pagamento é obrigatório' };
      },
    }),
    supplierDocument: z.string().transform((value) => value.replace(/\D/g, '')),
  })
  .partial()
  .superRefine((values, ctx) => {
    if (values.supplierType === SupplierType.natural_person) {
      const cpfValidationResult =
        validateCPFSchema.shape.documentEmployeer.safeParse(
          values.supplierDocument
        );

      if (!cpfValidationResult.success) {
        const message = cpfValidationResult.error.format()._errors[0];

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['supplierDocument'],
          message,
        });
      }
    } else if (values.supplierType === SupplierType.legal_person) {
      const cnpjValidationResult = cnpjSchema.safeParse(
        values.supplierDocument
      );
      if (!cnpjValidationResult.success) {
        const message = cnpjValidationResult.error.format()._errors[0];
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['supplierDocument'],
          message,
        });
      }
    }
  });

const bankSchema = z
  .object({
    pixType: z.string(),
    pixKey: z.string(),
    accountNumber: z.string(),
    agency: z.string(),
    bank: z.string(),
    typeOfBankTransfer: z.string().min(1, 'Tipo de pagamento obrigatório'),
  })
  .partial()
  .superRefine(
    (
      { pixType, pixKey, accountNumber, agency, bank, typeOfBankTransfer },
      ctx
    ) => {
      if (TypeOfBankTransfer.ted === typeOfBankTransfer) {
        if (!bank) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Banco obrigatório',
            path: ['bank'],
          });
        }

        if (!accountNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Conta obrigatória',
            path: ['accountNumber'],
          });
        }
        if (!agency) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Agência obrigatória',
            path: ['agency'],
          });
        }
      }

      if (
        [TypeOfBankTransfer.pix].includes(
          typeOfBankTransfer as TypeOfBankTransfer
        )
      ) {
        if (!pixType) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Tipo de chave obrigatório',
            path: ['pixType'],
          });
        }

        if (!pixKey) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Chave obrigatória',
            path: ['pixKey'],
          });
        }

        pixKeyValidation(pixType, pixKey, ctx);
      }
    }
  );

const MAX_MB_FILE_SIZE = 2;

// zod has a limitation with dependent fields, so it's best to have them in separate uploadFilesSchema
// more about this: https://github.com/colinhacks/zod/issues/479#issuecomment-1536233005

const uploadFilesSchema = z.object({
  purchaseAttachments: z
    .array(z.instanceof(File))
    .refine((file) => file?.length > 0, 'Pelo menos um arquivo é necessário.')
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2; // Convertendo para MB
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE; // Verificando se é maior que 2MB
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
  paymentAttachments: z
    .array(z.instanceof(File))
    .refine((file) => file?.length > 0, 'Pelo menos um arquivo é necessário.')
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2;
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE;
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
  optionalFiles: z
    .array(z.instanceof(File))
    .optional()
    .refine((files) => {
      for (let i = 0; i < files.length; i++) {
        const fileSizeInMB = files[i].size / 1024 ** 2;
        const isFileTooLarge = fileSizeInMB > MAX_MB_FILE_SIZE;
        if (isFileTooLarge) {
          return '';
        }
      }
      return true;
    }, ''),
});

const referenceMonthSchema = (resourceApproval) => {
  return z.object({
    referenceMonth: z.string().superRefine((value, ctx) => {
      if (resourceApproval.sale_type === PriceTypes.tuition && !value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Mês de referência obrigatório',
        });
      }
    }),
  });
};

const pixKeyPlaceHolder = (pixType) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      return 'Ex.: 000.000.000-00';
    case TypeKeyPix.email:
      return 'Ex.: email@email.com';
    case TypeKeyPix.phone:
      return 'Ex.: +00 00 00000-0000';
    case TypeKeyPix.random:
      return 'Ex.: 00000000-0000-0000-0000-000000000000';
    default:
      return '';
  }
};

const pixKeyValidation = (pixType, pixKey, ctx) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      if (!cpfSchema.safeParse(pixKey).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'CPF inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.email:
      if (!z.string().email().safeParse(pixKey).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Email inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.phone:
      if (!z.string().regex(PHONE_REGEX).safeParse(pixKey).success) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Número de telefone inválido',
          path: ['pixKey'],
        });
      }
      break;
    case TypeKeyPix.random:
      if (
        !z
          .string()
          .regex(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
          )
          .safeParse(pixKey).success
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Chave aleatória inválida',
          path: ['pixKey'],
        });
      }
    default:
      return false;
  }
};

const pixKeyFormat = (pixType, pixKey) => {
  switch (pixType) {
    case TypeKeyPix.cpf:
      return pixKey.replace(/[^\d]+/g, '');
    case TypeKeyPix.phone:
      return pixKey.replace(/[^\d]+/g, '');
    default:
      return pixKey;
  }
};

// z.intersection does not work to merge schemas here because
// we're using .refine/.superRefine which outputs ZodEffects instead of ZodObject.
// This is poorly documented in the zod documentation.
// As a workaround, we're using .and to merge the schemas.
// More about this:
// https://github.com/colinhacks/zod/issues/2474
// https://github.com/colinhacks/zod/issues/1147

const formValidationSchema = mainValidationSchema
  .and(validateCPFSchema)
  .and(validateCNPJCPFSchema)
  .and(bankSchema)
  .and(uploadFilesSchema);

const FieldSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Typography component="div" variant="body3">
        <Skeleton width="20%" />
      </Typography>
      <Skeleton height={50} variant="rounded" />
    </Stack>
  );
};

const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};

const SendingReceiptsForm: React.FC = () => {
  const { toast } = useNotification();
  const isEditing = isEditPath();
  const sessionKey = WizardStepIds.SENDING_RECEIPTS_FORM;
  const { resourceApproval, subsidyPolicy } = useRefundRequest();
  const { actions, values } = useWizard();
  const formValues = values[WizardStepIds.SENDING_RECEIPTS_FORM];
  const [openTooltip, setOpenTooltip] = React.useState(null);

  const isFirstLoad = React.useRef(true);
  const isEditingMode =
    isEditing && resourceApproval && !!resourceApproval.uuid;

  const bootstrapData = useBootstrapData();
  const loadingBootstrapData = bootstrapData.isFetching;

  const { control, handleSubmit, reset, trigger, watch } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(
      formValidationSchema.and(referenceMonthSchema(resourceApproval))
    ),
  });

  const handleFieldBlur = (fieldName, value) => {
    const currentValues = getFromSession(sessionKey) || {};

    const processedValue = Array.isArray(value)
      ? value.map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
        }))
      : value;

    const updatedValues = { ...currentValues, [fieldName]: processedValue };
    saveToSession(sessionKey, updatedValues);
  };

  const { supplierType, typeOfBankTransfer, pixType } = watch();
  const documentEmployeerValue = watch('documentEmployeer');

  const createReceiptsStructure = async (
    typeOfReceiptPurchase,
    purchaseAttachments,
    typeOfReceiptPayment,
    paymentAttachments,
    optionalFiles = []
  ) => {
    const combinedDocuments = [
      ...purchaseAttachments.map((file) => ({
        receipt_type: typeOfReceiptPurchase,
        attachment: file,
      })),
      ...paymentAttachments.map((file) => ({
        receipt_type: typeOfReceiptPayment,
        attachment: file,
      })),
      ...optionalFiles.map((file) => ({
        receipt_type: OtherReceiptType,
        attachment: file,
      })),
    ];

    if (combinedDocuments.length === 0) {
      return [];
    }

    return combinedDocuments;
  };

  const handleTooltipOpen = (tooltipId) => {
    setOpenTooltip((prevActiveTooltip) =>
      prevActiveTooltip === tooltipId ? null : tooltipId
    );
  };

  const handleNext = async (data) => {
    const formData = { ...data };

    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const receipts = await createReceiptsStructure(
      formData.typeOfReceiptPurchase,
      formData.purchaseAttachments,
      formData.typeOfReceiptPayment,
      formData.paymentAttachments,
      formData.optionalFiles
    );
    formData.pixKey = pixKeyFormat(formData.pixType, formData.pixKey);

    const payload = {
      ...defaultValues,
      ...formData,
      receipts: receipts,
      purchase_date: formData.purchaseDate,
      payment_date: formData.paymentDate,
      payment_amount: formData.paymentAmount,
      resource_approval_uuid: resourceApproval.uuid,
    };

    actions.updateValues({
      [WizardStepIds.SENDING_RECEIPTS_FORM]: payload,
    });
    removeFromSession(sessionKey);

    actions.navigate(WizardStepIds.SENDING_RECEIPTS_SUMMARY);
  };

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const handleInputChangeCPF = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length <= 11) {
      value = value
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }

    e.target.value = value;
  };

  const handleInputChangeCNPJ = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length <= 14) {
      value = value
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }

    e.target.value = value;
  };

  const handleInputChangeSupplierDocument = (e, supplierType) => {
    if (supplierType === SupplierType.natural_person) {
      handleInputChangeCPF(e);
    } else if (supplierType === SupplierType.legal_person) {
      handleInputChangeCNPJ(e);
    }
  };

  const handleInputChangePhoneNumber = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    //add '+' before 55 to the phone number
    if (value.length > 0) {
      value = '+' + value;
    }

    //add space after the country code
    if (value.length > 3) {
      value = value.replace(/^(\+\d{2})(\d)/, '$1 $2');
    }

    //add space after the DDD code
    if (value.length > 6) {
      value = value.replace(/^(\+\d{2}\s\d{2})(\d)/, '$1 $2');
    }

    //add space after the first 5 digits of the phone number
    if (value.length > 12) {
      value = value.replace(/^(\+\d{2}\s\d{2}\s\d{5})(\d)/, '$1-$2');
    }

    if (value.length > 17) {
      value = value.slice(0, 17);
    }

    e.target.value = value;
  };

  const handlePixKeyInputChange = (e, pixType) => {
    switch (pixType) {
      case TypeKeyPix.cpf:
        return handleInputChangeCPF(e);
      case TypeKeyPix.phone:
        return handleInputChangePhoneNumber(e);
      default:
        return;
    }
  };

  const shouldRenderNoAvailableBalance =
    !!subsidyPolicy?.limits.amount_limit_per_cycle_cents &&
    Number(subsidyPolicy?.limits.amount_available) === 0 &&
    !resourceApproval?.refund_request;

  const showErrorToast = () =>
    toast({
      content: 'Informações incorretas, revise os campos indicados',
      type: 'error',
    });

  React.useEffect(() => {
    const fetchAndFormatValues = async () => {
      if (
        !resourceApproval &&
        !formValues &&
        !Object.keys(getFromSession(sessionKey) || {}).length
      ) {
        return;
      }

      const storedValues = getFromSession(sessionKey) || {};

      const baseValues = isEditingMode ? resourceApproval : defaultValues;

      const mergedValues = { ...baseValues, ...storedValues, ...formValues };

      const formattedValues = await formatFormValues(
        resourceApproval,
        mergedValues,
        defaultValues
      );

      if (isFirstLoad.current || isEditingMode) {
        reset(formattedValues);
        isFirstLoad.current = false;
      }
    };

    fetchAndFormatValues();
  }, [sessionKey, formValues, resourceApproval, isEditingMode, reset]);

  return (
    <>
      {shouldRenderNoAvailableBalance ? (
        <NoAvailableBalance />
      ) : (
        <form
          onSubmit={handleSubmit(handleNext, showErrorToast)}
          data-testid="sending-receipts-form"
        >
          <Grid container spacing={3}>
            {resourceApproval?.status ===
              RefundRequestStatus.revision_required && (
              <>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Alert
                      severity="warning"
                      sx={(theme) => {
                        return {
                          '.MuiAlert-icon': {
                            alignSelf: 'center',
                            color: '#886400',
                          },
                          '&& *': {
                            fontSize: theme.typography.body3.fontSize,
                          },
                        };
                      }}
                    >
                      <AlertTitle
                        style={{ fontWeight: '600', marginBottom: 0 }}
                      >
                        Correção necessária
                      </AlertTitle>
                      {resourceApproval?.refund_request?.decision_comment}
                    </Alert>
                  </Stack>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Dados do fornecedor
                </Typography>
                <Typography variant="body3">
                  É a empresa ou pessoa que fez a venda do item.
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="supplierName"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="supplierName">
                          Nome do fornecedor
                        </FormLabel>
                        <TextField
                          {...field}
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur('supplierName', e.target.value);
                          }}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="supplierName"
                          placeholder="Ex: Nome da empresa"
                          variant="outlined"
                          slotProps={{
                            htmlInput: { 'data-testid': 'supplierName-input' },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="supplierType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error}>
                      <Stack spacing={1}>
                        <FormLabel id="supplierType">
                          Tipo de fornecedor
                        </FormLabel>
                        <RadioGroup
                          {...field}
                          aria-labelledby="supplierType"
                          onChange={(e) => {
                            {
                              field.onChange(e.target.value);
                              trigger(['supplierDocument']);
                            }
                          }}
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur('supplierType', field.value);
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                inputProps={
                                  {
                                    'data-testid': 'supplierType-radio1',
                                  } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                              />
                            }
                            label={
                              <Typography variant="body3">
                                Empresa / Pessoa jurídica
                              </Typography>
                            }
                            value={SupplierType.legal_person}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                inputProps={
                                  {
                                    'data-testid': 'supplierType-radio2',
                                  } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                              />
                            }
                            label={
                              <Typography variant="body3">
                                Pessoa física
                              </Typography>
                            }
                            value={SupplierType.natural_person}
                          />
                        </RadioGroup>
                      </Stack>
                      <FormHelperText>
                        {handleErrormessage(error)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  data-testid="supplierDocument-input"
                  control={control}
                  name="supplierDocument"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="supplierDocument">
                            {supplierType === SupplierType.natural_person
                              ? 'CPF'
                              : 'CNPJ do fornecedor'}
                          </FormLabel>
                          <TextField
                            {...field}
                            error={!!error}
                            id="supplierDocument"
                            helperText={handleErrormessage(error)}
                            onChange={(e) => {
                              handleInputChangeSupplierDocument(
                                e,
                                supplierType
                              );
                              field.onChange(e.target.value);
                            }}
                            onBlur={(e) => {
                              field.onBlur();
                              handleFieldBlur(
                                'supplierDocument',
                                e.target.value
                              );
                            }}
                            placeholder={
                              supplierType === SupplierType.legal_person
                                ? 'Ex.: 00.000.000/0001-00'
                                : 'Ex.: 000.000.000-00'
                            }
                            slotProps={{
                              htmlInput: {
                                'data-testid': 'supplierDocument-input',
                              },
                            }}
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Comprovante de compra
                </Typography>
                <Typography variant="body3">
                  É o documento que o fornecedor emite após confirmação do
                  pagamento. Pode ser uma nota fiscal, boleto ou recibo. Para
                  compras internacionais você pode usar invoice.
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="documentEmployeer"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="documentEmployeer">
                          CPF do comprador
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          id="documentEmployeer"
                          helperText={handleErrormessage(error)}
                          onChange={(e) => {
                            handleInputChangeCPF(e);
                            field.onChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur(
                              'documentEmployeer',
                              e.target.value
                            );
                          }}
                          placeholder="Ex.: 012.345.678-90"
                          variant="outlined"
                          slotProps={{
                            htmlInput: {
                              'data-testid': 'documentEmployeer-input',
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}></Grid>

            <Grid item xs={12} sm={4}>
              <Stack spacing={1}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="typeOfReceiptPurchase"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel id="typeOfReceiptPurchase">
                            Tipo de comprovante
                          </FormLabel>
                          <Select
                            {...field}
                            data-testid="typeOfReceiptPurchase-select"
                            displayEmpty={true}
                            error={!!error}
                            labelId="typeOfReceiptPurchase"
                            renderValue={(value) => {
                              return value
                                ? PurchaseReceipts[
                                    value as keyof typeof PurchaseReceipts
                                  ]
                                : 'Selecione';
                            }}
                            onBlur={(e) => {
                              field.onBlur();
                              handleFieldBlur(
                                'typeOfReceiptPurchase',
                                e.target.value
                              );
                            }}
                            variant="outlined"
                          >
                            {Object.entries(PurchaseReceipts).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </Stack>
                        <FormHelperText>
                          {handleErrormessage(error)}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="purchaseDate"
                  render={({ field, fieldState: { error } }) => {
                    const handleBlur = () => {
                      const parsedDate = parseDate(field.value);
                      if (parsedDate) {
                        field.onChange(parsedDate.toFormat('dd/MM/yyyy'));
                      }
                    };
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="purchaseDate">
                            Data da compra
                          </FormLabel>
                          <CustomDatePicker
                            field={{
                              ...field,
                              onBlur: () => {
                                field.onBlur();
                                handleFieldBlur('purchaseDate', field.value);
                              },
                            }}
                            value={parseDate(field.value)}
                            slotProps={{
                              textField: {
                                id: 'purchaseDate',
                                error: !!error,
                                helperText: handleErrormessage(error),
                                inputProps: {
                                  'data-testid': 'purchaseDate-input',
                                },
                                variant: 'outlined',
                                placeholder: `Ex.: ${DateTime.now().toFormat('dd/MM/yy')}`,
                              },
                            }}
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="purchaseAmount"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="purchaseAmount">
                          Valor do item
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          id="purchaseAmount"
                          helperText={handleErrormessage(error)}
                          placeholder="Ex.: R$ 100,00"
                          variant="outlined"
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur('purchaseAmount', e.target.value);
                          }}
                          slotProps={{
                            input: {
                              inputComponent: MoneyField as any,
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  position="start"
                                >
                                  R$
                                </InputAdornment>
                              ),
                            },

                            htmlInput: {
                              currency: 'BRL',
                              'data-testid': 'purchaseAmount-input',
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  name="purchaseAttachments"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1} alignItems={'start'}>
                        <FormLabel htmlFor="purchaseAttachments">
                          Anexos
                        </FormLabel>
                        <FileUpload
                          {...field}
                          required={true}
                          onFilesChange={(files) => {
                            field.onChange(files);
                            handleFieldBlur('purchaseAttachments', files);
                          }}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="purchaseAttachments"
                          defaultFiles={field.value || []}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Comprovante de pagamento
                </Typography>
                <Typography variant="body3">
                  É o documento emitido pelo banco comprovando o pagamento. Pode
                  ser a fatura do cartão ou imagem do comprovante no aplicativo
                  bancário. Só serão aceitos documentos que possuam data da
                  compra, valor e nome do pagador.
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <FieldWrapper loading={loadingBootstrapData}>
                  <Controller
                    control={control}
                    name="typeOfReceiptPayment"
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel id="typeOfReceiptPayment">
                            Tipo de comprovante
                          </FormLabel>
                          <Select
                            {...field}
                            data-testid="typeOfReceiptPayment-select"
                            displayEmpty={true}
                            error={!!error}
                            labelId="typeOfReceiptPayment"
                            renderValue={(value) => {
                              return value
                                ? PaymentReceipts[
                                    value as keyof typeof PaymentReceipts
                                  ]
                                : 'Selecione';
                            }}
                            variant="outlined"
                            onBlur={(e) => {
                              field.onBlur();
                              handleFieldBlur(
                                'typeOfReceiptPayment',
                                e.target.value
                              );
                            }}
                          >
                            {Object.entries(PaymentReceipts).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </Stack>
                        <FormHelperText>
                          {handleErrormessage(error)}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </FieldWrapper>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}></Grid>

            <Grid item xs={12} sm={4}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="paymentDate"
                  render={({ field, fieldState: { error } }) => {
                    const dateValue = parseDate(field.value);
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="paymentDate">
                            Data do pagamento
                          </FormLabel>
                          <CustomDatePicker
                            field={{
                              ...field,
                              onBlur: () => {
                                field.onBlur();
                                handleFieldBlur('paymentDate', field.value);
                              },
                            }}
                            value={
                              dateValue && dateValue.isValid ? dateValue : null
                            }
                            slotProps={{
                              textField: {
                                id: 'paymentDate',
                                error: !!error,
                                helperText: handleErrormessage(error),
                                inputProps: {
                                  'data-testid': 'paymentDate-input',
                                },
                                variant: 'outlined',
                                placeholder: `Ex.: ${DateTime.now().toFormat('dd/MM/yy')}`,
                              },
                            }}
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="paymentAmount"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1}>
                        <FormLabel htmlFor="paymentAmount">
                          Valor registrado no comprovante
                        </FormLabel>
                        <TextField
                          {...field}
                          error={!!error}
                          id="paymentAmount"
                          helperText={handleErrormessage(error)}
                          placeholder="Ex.: R$ 100,00"
                          variant="outlined"
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur('paymentAmount', e.target.value);
                          }}
                          slotProps={{
                            input: {
                              inputComponent: MoneyField as any,
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  position="start"
                                >
                                  R$
                                </InputAdornment>
                              ),
                            },

                            htmlInput: {
                              currency: 'BRL',
                              'data-testid': 'paymentAmount-input',
                            },
                          }}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            {resourceApproval.sale_type === PriceTypes.tuition && (
              <>
                <Grid item xs={12} sm={4}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="referenceMonth"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel id="referenceMonth">
                              Mês de referência
                              <CustomTooltip
                                title="Este é o mês que se refere ao pagamento realizado."
                                open={openTooltip === Tooltips.MONTH}
                                tooltipKey={Tooltips.MONTH}
                                onClick={() =>
                                  handleTooltipOpen(Tooltips.MONTH)
                                }
                                tooltipSx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.neutral[700],
                                  whiteSpace: 'pre-wrap',
                                  fontFamily: 'Assistant',
                                  fontSize: '0.9rem',
                                  fontWeight: 400,
                                  lineHeight: '1.5rem',
                                  width: '280px',
                                }}
                                icon={
                                  <CustonHelpRoundedIcon sx={{ ml: '5px' }} />
                                }
                              />
                            </FormLabel>
                            <Select
                              {...field}
                              error={!!error}
                              data-testid="referenceMonth-select"
                              displayEmpty={true}
                              labelId="referenceMonth"
                              renderValue={(value) => {
                                return value &&
                                  Months[value as keyof typeof Months]
                                  ? Months[value as keyof typeof Months]
                                  : 'Selecione';
                              }}
                              onBlur={(e) => {
                                field.onBlur();
                                handleFieldBlur(
                                  'referenceMonth',
                                  e.target.value
                                );
                              }}
                              variant="outlined"
                            >
                              <MenuItem value="">
                                <em>Selecione</em>
                              </MenuItem>
                              {Object.entries(Months).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </Stack>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  name="paymentAttachments"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1} alignItems={'start'}>
                        <FormLabel htmlFor="paymentAttachments">
                          Anexos
                        </FormLabel>
                        <FileUpload
                          {...field}
                          required={true}
                          onFilesChange={(files) => {
                            field.onChange(files);
                            handleFieldBlur('paymentAttachments', files);
                          }}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="paymentAttachments"
                          defaultFiles={field.value || []}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Outros comprovantes (Opcional)
                </Typography>
                <Typography variant="body3">
                  Utilize esse espaço para adicionar comprovantes adicionais
                  quando solicitados.
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sx={{ padding: 0 }}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  name="optionalFiles"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Stack spacing={1} alignItems={'start'}>
                        <FileUpload
                          {...field}
                          required={false}
                          onFilesChange={(files) => {
                            field.onChange(files);
                            handleFieldBlur('optionalFiles', files);
                          }}
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          id="optionalFiles"
                          defaultFiles={field.value || []}
                        />
                      </Stack>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Typography variant="h6" color="primary.900">
                  Dados bancários
                </Typography>
                <Typography variant="body3">
                  Informe os dados da conta onde deseja receber o reembolso. A
                  conta precisa estar no seu nome.
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="typeOfBankTransfer"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error}>
                      <Stack spacing={1}>
                        <FormLabel id="typeOfBankTransfer">
                          Como deseja receber seu reembolso?
                        </FormLabel>
                        <RadioGroup
                          {...field}
                          aria-labelledby="typeOfBankTransfer"
                          onChange={(e) => {
                            {
                              field.onChange(e.target.value);
                              if (e.target.value === TypeOfBankTransfer.pix) {
                                trigger(['pixType', 'pixKey']);
                              } else if (
                                e.target.value === TypeOfBankTransfer.ted
                              ) {
                                trigger(['bank', 'agency', 'accountNumber']);
                              }
                            }
                          }}
                          onBlur={(e) => {
                            field.onBlur();
                            handleFieldBlur('typeOfBankTransfer', field.value);
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                size="small"
                                inputProps={
                                  {
                                    'data-testid': 'typeOfBankTransfer-radio1',
                                  } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                              />
                            }
                            label={<Typography variant="body3">PIX</Typography>}
                            value={TypeOfBankTransfer.pix}
                          />
                          <FormControlLabel
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="body3">
                                TED / Transferência Bancária
                              </Typography>
                            }
                            value={TypeOfBankTransfer.ted}
                          />
                        </RadioGroup>
                      </Stack>
                      <FormHelperText>
                        {handleErrormessage(error)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="holderName"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="holderName">
                            Nome completo
                          </FormLabel>
                          <TextField
                            {...field}
                            onBlur={(e) => {
                              field.onBlur();
                              handleFieldBlur('holderName', e.target.value);
                            }}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            id="holderName"
                            placeholder="Ex.: Nome Completo"
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FieldWrapper loading={loadingBootstrapData}>
                <Controller
                  control={control}
                  name="documentEmployeerText"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl error={!!error} fullWidth>
                        <Stack spacing={1}>
                          <FormLabel htmlFor="documentEmployeerText">
                            CPF
                            <CustomTooltip
                              title="O CPF para pagamento é o mesmo declarado no comprovante de compra. Não é possível fazer o pagamento para outro CPF."
                              open={openTooltip === Tooltips.CPF}
                              tooltipKey={Tooltips.CPF}
                              onClick={() => handleTooltipOpen(Tooltips.CPF)}
                              tooltipSx={{
                                backgroundColor: (theme) =>
                                  theme.palette.neutral[700],
                                whiteSpace: 'pre-wrap',
                                fontFamily: 'Assistant',
                                fontSize: '0.9rem',
                                fontWeight: 400,
                                lineHeight: '1.5rem',
                                width: '280px',
                              }}
                              icon={
                                <CustonHelpRoundedIcon sx={{ ml: '5px' }} />
                              }
                            />
                          </FormLabel>
                          <TextField
                            {...field}
                            disabled
                            value={documentEmployeerValue}
                            error={!!error}
                            helperText={handleErrormessage(error)}
                            id="documentEmployeerText"
                            onBlur={(e) => {
                              field.onBlur();
                              handleFieldBlur(
                                'documentEmployeerText',
                                e.target.value
                              );
                            }}
                          />
                        </Stack>
                      </FormControl>
                    );
                  }}
                />
              </FieldWrapper>
            </Grid>

            {[TypeOfBankTransfer.pix].includes(
              typeOfBankTransfer as TypeOfBankTransfer
            ) && (
              <>
                <Grid item xs={12}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="pixType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error}>
                          <Stack spacing={1}>
                            <FormLabel id="pixType">
                              Tipo de chave PIX
                            </FormLabel>
                            <RadioGroup
                              {...field}
                              aria-labelledby="pixType"
                              onChange={(e) => {
                                {
                                  field.onChange(e.target.value);
                                  trigger(['pixKey']);
                                }
                              }}
                              onBlur={() => {
                                field.onBlur();
                                handleFieldBlur('pixType', field.value);
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Radio
                                    size="small"
                                    inputProps={
                                      {
                                        'data-testid': 'pixType-radio1',
                                      } as React.InputHTMLAttributes<HTMLInputElement>
                                    }
                                  />
                                }
                                label={
                                  <Typography variant="body3">CPF</Typography>
                                }
                                value={TypeKeyPix.cpf}
                              />
                              <FormControlLabel
                                control={<Radio size="small" />}
                                label={
                                  <Typography variant="body3">Email</Typography>
                                }
                                value={TypeKeyPix.email}
                              />
                              <FormControlLabel
                                control={<Radio size="small" />}
                                label={
                                  <Typography variant="body3">
                                    Telefone
                                  </Typography>
                                }
                                value={TypeKeyPix.phone}
                              />
                              <FormControlLabel
                                control={<Radio size="small" />}
                                label={
                                  <Typography variant="body3">
                                    Chave aleatória
                                  </Typography>
                                }
                                value={TypeKeyPix.random}
                              />
                            </RadioGroup>
                          </Stack>
                          <FormHelperText>
                            {handleErrormessage(error)}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="pixKey"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel htmlFor="pixKey">Chave PIX</FormLabel>
                            <TextField
                              {...field}
                              onChange={(e) => {
                                handlePixKeyInputChange(e, pixType);
                                field.onChange(e.target.value);
                              }}
                              onBlur={(e) => {
                                field.onBlur();
                                handleFieldBlur('pixKey', e.target.value);
                              }}
                              error={!!error}
                              id="pixKey"
                              helperText={handleErrormessage(error)}
                              placeholder={pixKeyPlaceHolder(pixType)}
                              variant="outlined"
                              slotProps={{
                                htmlInput: {
                                  'data-testid': 'pixKey-input',
                                },
                              }}
                            />
                          </Stack>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
              </>
            )}

            {[TypeOfBankTransfer.ted].includes(
              typeOfBankTransfer as TypeOfBankTransfer
            ) && (
              <>
                <Grid item xs={12} sm={12}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="bank"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel htmlFor="bank">Banco</FormLabel>
                            <Autocomplete
                              {...field}
                              data-testid="bank-autocomplete"
                              id="bank"
                              options={banksSelectOptions.banks}
                              getOptionKey={(option) => option.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!error}
                                  helperText={handleErrormessage(error)}
                                  placeholder="Selecione"
                                />
                              )}
                              onChange={(_, data, reason) => {
                                if (reason === 'clear') {
                                  field.onChange(defaultValues.bank);
                                } else if (data) {
                                  field.onChange(data.value);
                                }
                              }}
                              onBlur={() => {
                                field.onBlur();
                                handleFieldBlur('bank', field.value);
                              }}
                              value={
                                banksSelectOptions.banks.find(
                                  (option) => option.value === field.value
                                ) ?? null
                              }
                            />
                          </Stack>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="agency"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel htmlFor="agency">Agência</FormLabel>
                            <TextField
                              {...field}
                              error={!!error}
                              id="agency"
                              placeholder="0001"
                              helperText={handleErrormessage(error)}
                              variant="outlined"
                              onBlur={(e) => {
                                field.onBlur();
                                handleFieldBlur('agency', e.target.value);
                              }}
                              slotProps={{
                                htmlInput: { 'data-testid': 'agency-input' },
                              }}
                            />
                          </Stack>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FieldWrapper loading={loadingBootstrapData}>
                    <Controller
                      control={control}
                      name="accountNumber"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <Stack spacing={1}>
                            <FormLabel htmlFor="accountNumber">
                              Número da conta
                            </FormLabel>
                            <TextField
                              {...field}
                              error={!!error}
                              id="accountNumber"
                              helperText={handleErrormessage(error)}
                              placeholder="01234-5"
                              variant="outlined"
                              onBlur={(e) => {
                                field.onBlur();
                                handleFieldBlur(
                                  'accountNumber',
                                  e.target.value
                                );
                              }}
                              slotProps={{
                                htmlInput: {
                                  'data-testid': 'accountNumber-input',
                                },
                              }}
                            />
                          </Stack>
                        </FormControl>
                      )}
                    />
                  </FieldWrapper>
                </Grid>
              </>
            )}
          </Grid>

          <StepActions>
            <NextButton
              color="primary"
              type="submit"
              variant="contained"
              data-testid="next-button"
            >
              Próximo
            </NextButton>
          </StepActions>
        </form>
      )}
    </>
  );
};

export default SendingReceiptsForm;
