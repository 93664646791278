import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { Profile } from '@/react/data/recommendation/interfaces';
import { ProfileBasedRecommendationActivitiesStatus } from '@/react/data/idp/profile/constants';
import { CourseTypes } from '@/react/data/catalog/interfaces';
import { PlanResponse } from '../plans';

export type RecommendedPlanActivity = {
  id: string;
  name: string;
  reason: string;
  duration: string;
  activity_type: ActivityType;
  course_type: CourseTypes;
};

export enum ActivityType {
  COURSE = 'course',
  OTHER = 'other',
}

export type ProfileBasedRecommendation = {
  current_step: CreatePlanWizardSteps;
  chomsky_response_data: any; // jsonb
  profile_data: any; // jsonb
  developing_skills: any; // jsonb
  recommended_activities: RecommendedPlanActivity[];
  recommended_plan_description: string;
  duration_in_months: number;
  plan: Partial<PlanResponse>;
  user: any; // FIXME: User
  profile: Profile;
  completed_at: string;
  discarded_at: string;
  id: string;
  processing_status: ProfileBasedRecommendationActivitiesStatus;
};
