import * as React from 'react';
import { Grid, Typography, Link, Chip, Button, styled } from '@mui/material';
import { formatMoney } from '@/react/utils/money';
import { formatDate } from '@/react/utils/date';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import {
  Months,
  PaymentReceiptTypes,
  PurchaseReceiptTypes,
  SupplierType,
} from '@/react/components/refund/refund-request/invoice-step/interfaces';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import theme from '@/react/@mui/theme';
import { isValidationPath } from '@/react/components/refund/utils/path-utils';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { messageCancelled } from '@/react/components/refund/utils/messages-from-steps';

const CorrectionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    float: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ShowReceiptsInfo = () => {
  const { resourceApproval, subsidyPolicy } = useRefundRequest();
  const receiptAttachmentsByType = (receipts, type) => {
    return receipts
      ?.filter((receipt) => type.includes(receipt.type))
      ?.flatMap((receipt) => receipt.attachments);
  };

  const href = `/meus-reembolsos/solicitacao/${resourceApproval.uuid}/pedido/${resourceApproval.refund_request.uuid}/editar-comprovante`;

  return (
    <>
      {resourceApproval &&
        [RefundRequestStatus.rejected, RefundRequestStatus.cancelled].includes(
          resourceApproval.status
        ) && (
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              justifyContent="center"
              sx={({ spacing }) => ({
                paddingBottom: spacing('0.5'),
                marginLeft: spacing(2),
                marginBottom: spacing(2),
              })}
            >
              <Chip
                label={`${messageCancelled(resourceApproval)} ${formatDate(resourceApproval?.refund_request?.decided_at)}`}
                variant="negativeRequestResponse"
              />
            </Grid>
          </Grid>
        )}
      <Grid
        container
        rowSpacing={{ xs: 1, md: 2 }}
        sx={({ spacing }) => ({
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        })}
      >
        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Data do pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(resourceApproval?.refund_request?.payment_date) || '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Comprovante de pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="body3"
              style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
            >
              {receiptAttachmentsByType(
                resourceApproval.refund_request?.receipts,
                PaymentReceiptTypes
              )?.map((attachment, index) => (
                <Link
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  style={{ marginRight: '10px' }}
                >
                  {attachment.content?.filename}
                </Link>
              ))}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            {resourceApproval.sale_type === PriceTypes.tuition
              ? 'Valor da mensalidade'
              : 'Valor da compra'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatMoney(resourceApproval.refund_request?.purchase_amount)}
          </Typography>
        </Grid>

        {resourceApproval.sale_type === PriceTypes.tuition && (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Mês de referência
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {Months[resourceApproval.refund_request?.reference_month]}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Nome do fornecedor
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {resourceApproval.refund_request.supplier_name}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            {resourceApproval.refund_request?.supplier_type ===
            SupplierType.legal_person
              ? 'CNPJ'
              : 'CPF'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {resourceApproval.refund_request?.supplier_document}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Valor do pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatMoney(resourceApproval.refund_request?.payment_amount)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Data da compra
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(resourceApproval.refund_request?.purchase_date) || '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Comprovante de compra
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="body3"
              style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
            >
              {receiptAttachmentsByType(
                resourceApproval.refund_request?.receipts,
                PurchaseReceiptTypes
              )?.map((attachment, index) => (
                <Link
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  style={{ marginRight: '10px' }}
                >
                  {attachment.content?.filename}
                </Link>
              ))}
            </Typography>
          </Grid>
        </Grid>

        {resourceApproval.refund_request?.banking_info?.pix_key ? (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Chave PIX
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resourceApproval.refund_request?.banking_info?.pix_key}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Número da conta
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resourceApproval.refund_request?.banking_info?.account_number}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Banco
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resourceApproval.refund_request?.banking_info?.bank}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="body3" fontWeight={600}>
                Agência
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body3">
                {resourceApproval.refund_request?.banking_info?.agency}
              </Typography>
            </Grid>
          </>
        )}

        {[
          RefundRequestStatus.pending_approval,
          RefundRequestStatus.revision_required,
        ].includes(resourceApproval.status) && subsidyPolicy && (
          <Grid item xs={12} md={3} sx={{ mt: '22px', pt: 0 }}>
            {isValidationPath() ? (
              <CorrectionButton
                variant="contained"
                color="primary"
                href={href}
                sx={{ borderRadius: 0 }}
              >
                Corrigir
              </CorrectionButton>
            ) : (
              <Link
                color={theme.palette.primary[200]}
                fontWeight={600}
                href={href}
                rel="noopener noreferrer"
              >
                Editar
              </Link>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
