import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { EmailRegex } from 'powership';
import { Typography } from '@mui/material';

export const FLAG_ACCEPT_EMAIL_MKT = 'accept_communication';

export function QuestionTypeEmailContact({
  value,
  errors,
  setErrors,
  setValue,
}) {
  const hasError = !!errors.length;

  const [emailValue = ''] = value;

  return (
    <Stack gap={3}>
      <Typography variant="body3">
        Nos diga seu e-mail preferido para receber informações e novidades sobre
        a Galena! <br />É para ele que enviaremos as comunicações e instruções
        sobre suas matrículas e cursos, além de promoções especiais.
      </Typography>
      <TextField
        placeholder="Digite o seu e-mail"
        error={hasError}
        value={emailValue}
        onChange={(ev) => {
          const txt = ev.target.value;
          const isValid = EmailRegex.test(txt);

          if (isValid && hasError) {
            setErrors([]);
          } else if (!isValid && !hasError) {
            setErrors(['Email inválido']);
          }

          setValue([txt]);
        }}
      />
      <Typography variant="caption">
        *Ao preencher os dados, você aceita receber comunicações da Galena
      </Typography>
    </Stack>
  );
}
