import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const ContentBox = ({
  children,
  ...props
}: BoxProps & { p?: number }) => {
  const { p: padding, ...otherProps } = props;

  return (
    <Box
      sx={({ palette }) => ({
        backgroundColor: `${palette.neutral[50]}`,
        border: { sm: `1px solid ${palette.neutral[300]}`, xs: 'none' },
        borderRadius: { sm: '4px', xs: '0' },
        boxShadow: {
          xs: `-16px 0 0 ${palette.neutral[50]}, 16px 0 0 ${palette.neutral[50]}`,
          sm: 'none',
        },
      })}
      px={{ xs: '0', sm: padding as number }}
      py={padding as number}
      {...otherProps}
    >
      {children}
    </Box>
  );
};
