import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Skeleton, Stack, Typography } from '@mui/material';

import { ContentBox, TagSelect } from '@/react/components/idp/shared';
import { useRecommendedSkills } from '@/react/data/idp/useRecommendedSkills';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { z } from 'zod';
import { ProfileSkill } from '@/react/data/recommendation/interfaces';

const DEFAULT_MAX_SELECTED = 5;

interface skill {
  label: string;
  value: string;
  confidence: number;
  checked: boolean;
}

export const validationSchema = z.object({
  skills: z
    .object({
      label: z.string(),
      value: z.string(),
      confidence: z.number(),
      checked: z.boolean(),
    })
    .array()
    .min(1, 'Selecione pelo menos uma habilidade para continuar.')
    .max(18),
});

const mapRecommendationsWithCheckedStatus = (
  recommendations: skill[],
  selectedSkills: skill[]
) => {
  const selectedSkillsMap = new Map(
    selectedSkills.map((item) => [item.value, true])
  );
  return recommendations.map((targetItem) => ({
    ...targetItem,
    checked: selectedSkillsMap.get(targetItem.value) ?? targetItem.checked,
  }));
};

const formatSkillsToTagSelectOptions = (
  skills: (ProfileSkill & { checked: boolean })[]
) =>
  skills.map((skill) => ({
    label: skill.name_pt,
    value: skill.name_en,
    confidence: skill.confidence,
    checked: skill.checked,
  }));

const formatTagSelectOptionsToSkills = (skills: skill[]) =>
  skills.map((skill) => ({
    name_en: skill.value,
    name_pt: skill.label,
    confidence: skill.confidence,
  }));

export const PlanSkillSelectionForm: React.ElementType<
  WizardStepComponent & {
    maxSelected?: number;
  }
> = ({ maxSelected = DEFAULT_MAX_SELECTED, onNext, onPrevious }) => {
  const { values } = useWizard();
  const defaultValues = {
    skills: values[CreatePlanWizardSteps.SKILL_SELECTION] || [],
  };
  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { recommendations, isFetching } = useRecommendedSkills();
  const [skills, setSkills] = useState([]);
  const selectedCount = skills
    .map((skill) => skill.checked)
    .filter(Boolean).length;

  const onChange = (index: number) => {
    setSkills((prevOptions) => {
      const isCurrentlyChecked = prevOptions[index].checked;
      if (!isCurrentlyChecked && selectedCount === maxSelected) {
        return prevOptions;
      }

      const newOptions = [...prevOptions];
      newOptions[index].checked = !newOptions[index].checked;

      setValue(
        'skills',
        newOptions.filter((skill) => skill.checked),
        {
          shouldValidate: true,
        }
      );

      return newOptions;
    });
  };

  useEffect(() => {
    const selectedSkills = watch('skills');

    const formattedRecommendationsForTagSelect =
      formatSkillsToTagSelectOptions(recommendations);

    const formattedSelectedSkillsForTagSelect =
      formatSkillsToTagSelectOptions(selectedSkills);

    const formattedRecommendations = (
      selectedSkills.length > 0
        ? mapRecommendationsWithCheckedStatus(
            formattedRecommendationsForTagSelect,
            formattedSelectedSkillsForTagSelect
          )
        : formattedRecommendationsForTagSelect
    ).sort((a, b) => b.confidence - a.confidence);

    setSkills(formattedRecommendations);
  }, [isFetching]);

  const handleNext = async (data) => {
    const formattedSkills = formatTagSelectOptionsToSkills(data.skills);

    if (onNext) {
      onNext({ stepValues: formattedSkills });
    }
  };

  const content = (
    <form onSubmit={handleSubmit(handleNext)}>
      <Stack spacing={2}>
        <TagSelect onChange={onChange} options={skills} />
        <Typography variant="body3">
          Selecionados:{' '}
          <Box
            component="span"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightBold,
            })}
          >
            {selectedCount}/{maxSelected}
          </Box>
        </Typography>
        {formState.isSubmitted && formState.errors['skills'] && (
          <Alert severity="error">
            {formState.errors['skills'].message as string}
          </Alert>
        )}
      </Stack>
      <WizardStepActions>
        <Button color="primary" onClick={onPrevious} variant="text">
          Voltar
        </Button>
        <Button
          onClick={() => {
            trigger();
          }}
          color="primary"
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </WizardStepActions>
    </form>
  );

  return (
    <ContentBox mt={2} p={3}>
      <Box>
        <Typography variant="h6">
          Quais habilidades você deseja desenvolver com este plano?
        </Typography>
        <Typography variant="body3">
          Selecione até 5 habilidades para que dê tempo de desenvolvê-las de
          forma adequada.
        </Typography>
      </Box>
      <Box mt={3}>{isFetching ? <Loading /> : content}</Box>
    </ContentBox>
  );
};

const Loading = () => (
  <Stack
    direction="row"
    flexWrap={'wrap'}
    spacing={2}
    width={'100%'}
    data-testid="tag-select-loading"
  >
    {Array.from({ length: Math.floor(Math.random() * 5) + 3 }).map(
      (_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          width={Math.floor(Math.random() * 200) + 100}
          height={32}
        />
      )
    )}
  </Stack>
);
