import { Component } from '@angular/core';
import { ProductNotFoundPage } from '@/react/components/cursos/product-not-found-page';

@Component({
  selector: 'product-not-found-page',
  templateUrl: './product-not-found-page.component.html',
})
export class ProductNotFoundPageComponent {
  ProductNotFoundPage = ProductNotFoundPage;
}
