import { useMutation } from '@tanstack/react-query';
import { useNotification } from '@/react/components/notification';
import { IdpClient } from './idp-client';
import { ProfileBasedRecommendation } from './interfaces';
import { RecommendedPlanActivity } from './profile/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import * as Sentry from '@sentry/browser';

export const mutationKey = ['useCompleteProfileBasedRecommendation'];

export function useCompleteProfileBasedRecommendation(
  profileBasedRecommendationId: ProfileBasedRecommendation['id']
) {
  const { toast } = useNotification();
  const { mutateAsync: completeProfileBasedRecommendation, isPending } =
    useMutation({
      mutationKey: [...mutationKey, profileBasedRecommendationId],
      mutationFn: async ({
        activities,
        currentStep,
      }: {
        activities?: RecommendedPlanActivity['id'][];
        currentStep: CreatePlanWizardSteps;
      }) => {
        const result = await IdpClient.completeProfileBasedRecommendations(
          profileBasedRecommendationId,
          activities,
          currentStep
        );
        return result;
      },
      onError: (error: any) => {
        Sentry.captureException(error);

        toast({
          content: 'Erro ao criar plano',
          type: 'error',
        });
      },
    });

  return {
    completeProfileBasedRecommendation,
    isPending,
  };
}
