import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import {
  PlanCard,
  PlanCardClosed,
  PlanCardDraft,
  PlanCardInProgress,
} from '@/react/components/idp/home/components';
import { Plan, PlanStatus, PlansByStatus } from '@/react/data/idp/interfaces';
import { usePlanActivities } from '@/react/data/idp';

const hasDuration = (plan: Plan) =>
  Boolean(plan.durationInMonths && plan.startedAt && plan.endedAt);

export const ListPlans = ({ plans }: { plans: PlansByStatus }) => {
  const { activities, isFetching: fetchingActivities } = usePlanActivities(
    plans[PlanStatus.IN_PROGRESS]?.id
  );

  return (
    <Stack spacing={3} data-testid="listPlans">
      {plans[PlanStatus.IN_PROGRESS] && (
        <Stack spacing={2}>
          <Typography variant="h6">Em Andamento</Typography>
          <Box>
            <PlanCardInProgress
              activities={activities}
              loadingActivities={fetchingActivities}
              plan={plans[PlanStatus.IN_PROGRESS]}
            />
          </Box>
        </Stack>
      )}
      {plans[PlanStatus.DRAFT] && (
        <Stack spacing={2}>
          <Typography variant="h6">Rascunho</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <PlanCardDraft
                data-testid="planItemDraft"
                plan={plans[PlanStatus.DRAFT]}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
      {plans[PlanStatus.CLOSED].length > 0 && (
        <Stack spacing={2}>
          <Typography variant="h6">Finalizados</Typography>
          <Grid container spacing={4}>
            {plans[PlanStatus.CLOSED].map((plan: Plan) => (
              <Grid key={plan.id} item xs={12} md={4}>
                <PlanCardClosed plan={plan} data-testid="planItemClosedDates">
                  {hasDuration(plan) && (
                    <Typography variant="body3">
                      {plan.startedAt} - {plan.endedAt}
                    </Typography>
                  )}
                </PlanCardClosed>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </Stack>
  );
};
