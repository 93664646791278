import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { RefundRequestProvider } from '@/react/components/refund/refund-request/state';
import { State as RefundRequestState } from '@/react/components/refund/refund-request/state/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { AccordionProvider } from '@/react/components/refund/accordion/state/context';
import { State as AccordionState } from '@/react/components/refund/accordion/state/interfaces';
import {
  AccordionStep,
  AccordionSteps,
  AccordionStepStatus,
} from '@/react/components/refund/accordion/interfaces';
import {
  RefundEntitiesUuids,
  RefundRequestSteps,
} from '@/react/components/refund/refund-request/interfaces';
import Accordion from '@/react/components/refund/accordion/accordion.component';
import DescriptionStep from '@/react/components/refund/refund-request/description-step/description-step.component';
import DescriptionEditStep from '@/react/components/refund/refund-request/description-step/description-edit-step.component';
import SendingReceiptsStep from '@/react/components/refund/refund-request/invoice-step/sending-receipts-step.component';
import DescriptionSumaryStep from '@/react/components/refund/refund-request/description-step/description-step-summary.component';
import { ShowReceipts } from '@/react/components/refund/refund-request/invoice-step/sending-receipts-form/show-receipts.component';
import PaymentResumeStep from '@/react/components/refund/refund-request/payment-step/payment-resume-step.component';
import {
  RefundRequestStatus,
  RefundResourceStatus,
  ResourceApproval,
} from '@/react/data/subsidy/interfaces';
import { useResourceApproval } from '@/react/data/subsidy/useResourceApproval';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import {
  isEditPath,
  isShowPath,
  isValidationPath,
  isEditResourceApprovalPath,
} from '@/react/components/refund/utils/path-utils';
import ApprovalStep from '@/react/components/refund/refund-request/approval-step/approval-step.component';
import { useSubsidyPolicies } from '@/react/data/subsidy/useSubsidyPolicies';
import { InvoiceValidationSummary } from '@/react/components/refund/refund-request/invoice-validation-step/invoice-validation-summary/invoice-validation-summary.component';
import RefundHeader from '@/react/components/refund/refund-header/refund-header.component';
import { BriefSummary } from './description-step/refund-details/brief-summary/brief-summary.component';
import { ApprovalSummary } from './approval-step/approval-summary/approval-summary.component';
import { HeaderInvoiceAttachment } from './invoice-step/header/header-invoice-attachment.component';
import { determineExpansionInvoiceAccordion } from './invoice-step/header/determine-expansion-invoice-accordion';
import { PaymentHeader } from '@/react/components/refund/refund-request/payment-step/payment-resume/payment-resume.component';

const disableStep = ({
  resourceApproval,
  step,
}: {
  resourceApproval: ResourceApproval;
  step: RefundRequestSteps;
}): boolean => {
  let disabled = true;

  if (!resourceApproval) {
    if (step === RefundRequestSteps.RESOURCE_DESCRIPTION) {
      return false;
    }
    return true;
  }

  const { mappedStatus: refundStatus } = useRefundStatus(resourceApproval);

  if (step === RefundRequestSteps.RESOURCE_DESCRIPTION) {
    disabled = false;
  }

  if (step === RefundRequestSteps.RESOURCE_APPROVAL) {
    disabled = !resourceApproval?.uuid;
  }

  if (step === RefundRequestSteps.INVOICE_ATTACHMENT) {
    const stepsForStep3 = [
      'waitingForRevisionStepTwo',
      'pendingDocumentationStepThree',
      'waitingForDocumentationRevisionStepFour',
      'declinedRefundRequestStepFour',
      'needsRefundRequestRevisionStepFour',
      'pendingRefundPaymentStepFive',
      'canceledRefundRequestStepThree',
      'paidRefundRequestStepFive',
    ];
    disabled =
      !stepsForStep3.includes(refundStatus) && isEditResourceApprovalPath();

    if (
      isEditResourceApprovalPath() ||
      [
        'canceledResourceApprovalStepOne',
        'declinedResourceApprovalStepTwo',
      ].includes(refundStatus)
    ) {
      disabled = true;
    }
  }

  if (step === RefundRequestSteps.INVOICE_VALIDATION) {
    const stepsForStep4 = [
      'canceledRefundRequestStepThree',
      'waitingForDocumentationRevisionStepFour',
      'declinedRefundRequestStepFour',
      'needsRefundRequestRevisionStepFour',
      'pendingRefundPaymentStepFive',
      'paidRefundRequestStepFive',
    ];
    disabled = !stepsForStep4.includes(refundStatus);
  }

  if (step === RefundRequestSteps.PAYMENT) {
    const stepsForStep5 = [
      'pendingRefundPaymentStepFive',
      'paidRefundRequestStepFive',
    ];
    disabled = !stepsForStep5.includes(refundStatus);
  }

  return disabled;
};

const expandStep = ({
  resourceApproval,
  step,
}: {
  resourceApproval: ResourceApproval;
  step: RefundRequestSteps;
}): boolean => {
  let expanded = false;

  if (!resourceApproval) {
    if (step === RefundRequestSteps.RESOURCE_DESCRIPTION) {
      return true;
    }
    return false;
  }

  if (
    step === RefundRequestSteps.RESOURCE_DESCRIPTION &&
    isEditResourceApprovalPath()
  ) {
    expanded = true;
  }

  const { mappedStatus: refundStatus } = useRefundStatus(resourceApproval);

  if (step === RefundRequestSteps.INVOICE_ATTACHMENT) {
    expanded = determineExpansionInvoiceAccordion({
      step,
      resourceApproval,
      refundStatus,
      isShowPath,
      isEditPath,
    });
  }

  return expanded;
};

const stepStatus = ({
  resourceApproval,
  step,
}: {
  resourceApproval: ResourceApproval;
  step: RefundRequestSteps;
}): AccordionStep['status'] => {
  let status = null;

  if (!resourceApproval) {
    return status;
  }
  const { mappedStatus } = useRefundStatus(resourceApproval);

  const { hasRefundRequest, originalStatus: refundStatus } =
    useRefundStatus(resourceApproval);

  // #1
  if (step === RefundRequestSteps.RESOURCE_DESCRIPTION) {
    status = AccordionStepStatus.success;

    if (
      [RefundResourceStatus.cancelled, RefundResourceStatus.rejected].includes(
        resourceApproval.status
      )
    ) {
      status = AccordionStepStatus.error;
    }
  }

  // #2
  if (step === RefundRequestSteps.RESOURCE_APPROVAL) {
    if (hasRefundRequest) {
      status = AccordionStepStatus.success;
    } else {
      if (refundStatus === RefundResourceStatus.approved) {
        status = AccordionStepStatus.success;
      }
      if (refundStatus === RefundResourceStatus.pending_approval) {
        status = AccordionStepStatus.pending;
      }
      if (refundStatus === RefundResourceStatus.revision_required) {
        status = AccordionStepStatus.warning;
      }
      if (
        [
          RefundResourceStatus.cancelled,
          RefundResourceStatus.rejected,
        ].includes(refundStatus)
      ) {
        status = AccordionStepStatus.error;
      }
    }
  }

  // #3
  if (step === RefundRequestSteps.INVOICE_ATTACHMENT) {
    if (hasRefundRequest) {
      if (RefundRequestStatus.revision_required === refundStatus) {
        status = AccordionStepStatus.warning;
      } else {
        status = AccordionStepStatus.success;
      }

      if (
        'canceledRefundRequestStepThree' === mappedStatus &&
        [
          RefundResourceStatus.cancelled,
          RefundResourceStatus.rejected,
          RefundRequestStatus.cancelled,
          RefundRequestStatus.rejected,
        ].includes(refundStatus)
      ) {
        status = AccordionStepStatus.error;
      }
    } else {
      if (RefundResourceStatus.approved === refundStatus) {
        status = AccordionStepStatus.pending;
      }
    }
  }

  // #4
  if (step === RefundRequestSteps.INVOICE_VALIDATION) {
    if (hasRefundRequest) {
      if (RefundRequestStatus.pending_approval === refundStatus) {
        status = AccordionStepStatus.pending;
      }
      if (RefundRequestStatus.revision_required === refundStatus) {
        status = AccordionStepStatus.warning;
      }
      if (
        [RefundRequestStatus.cancelled, RefundRequestStatus.rejected].includes(
          refundStatus
        )
      ) {
        status = AccordionStepStatus.error;
      }
      if (RefundRequestStatus.approved === refundStatus) {
        status = AccordionStepStatus.success;
      }
      if (RefundRequestStatus.paid === refundStatus) {
        status = AccordionStepStatus.success;
      }
    }
  }

  // #5
  if (step === RefundRequestSteps.PAYMENT) {
    if (RefundRequestStatus.approved === refundStatus) {
      status = AccordionStepStatus.pending;
    }
    if (RefundRequestStatus.paid === refundStatus) {
      status = AccordionStepStatus.success;
    }
  }

  return status;
};

// TODO: Refactor this, remove this logic and create
// a function to return the correct component, in another file
const renderStepComponent = ({
  step,
  resourceApproval,
}: {
  step: RefundRequestSteps;
  resourceApproval: ResourceApproval;
}): React.ReactElement => {
  switch (step) {
    case RefundRequestSteps.RESOURCE_DESCRIPTION:
      if (resourceApproval && !isEditResourceApprovalPath()) {
        return <DescriptionSumaryStep />;
      }

      if (
        resourceApproval?.status === RefundResourceStatus.pending_approval &&
        resourceApproval.refund_request === null
      ) {
        return <DescriptionEditStep />;
      }

      return <DescriptionStep />;

    case RefundRequestSteps.RESOURCE_APPROVAL:
      if (resourceApproval) {
        return <ApprovalStep />;
      }
      return null;

    case RefundRequestSteps.INVOICE_ATTACHMENT:
      if (
        (resourceApproval &&
          !isShowPath() &&
          !isValidationPath() &&
          [
            RefundRequestStatus.pending_approval,
            RefundRequestStatus.revision_required,
          ].includes(resourceApproval.status)) ||
        (resourceApproval && !resourceApproval.refund_request?.uuid)
      ) {
        return <SendingReceiptsStep />;
      } else {
        return <ShowReceipts />;
      }

    case RefundRequestSteps.INVOICE_VALIDATION:
      if (resourceApproval) {
        return <InvoiceValidationSummary />;
      }
      return null;

    case RefundRequestSteps.PAYMENT:
      if (resourceApproval) {
        return (
          <PaymentResumeStep
            orderUuid={{
              resourceApprovalUuid: resourceApproval.uuid,
              refundRequestUuid: resourceApproval.refund_request?.uuid,
            }}
          />
        );
      }
      return null;
    default:
      return null;
  }
};

const renderStepHeader = ({
  step,
}: {
  step: RefundRequestSteps;
}): React.ReactElement => {
  const { resourceApproval } = useRefundRequest();
  const hasSubtitle = Boolean(resourceApproval?.uuid);

  const stepDetails = {
    [RefundRequestSteps.RESOURCE_DESCRIPTION]: {
      title: '1. Descrição do pedido',
      subtitle:
        hasSubtitle && !isEditResourceApprovalPath() ? <BriefSummary /> : null,
    },
    [RefundRequestSteps.RESOURCE_APPROVAL]: {
      title: '2. Aprovação do item',
      subtitle: hasSubtitle ? <ApprovalSummary /> : null,
    },
    [RefundRequestSteps.INVOICE_ATTACHMENT]: {
      title: '3. Envio de comprovantes',
      subtitle: hasSubtitle ? <HeaderInvoiceAttachment /> : null,
    },
    [RefundRequestSteps.INVOICE_VALIDATION]: {
      title: '4. Validação de comprovantes',
      subtitle: hasSubtitle ? <InvoiceValidationSummary /> : null,
    },
    [RefundRequestSteps.PAYMENT]: {
      title: '5. Pagamento',
      subtitle: hasSubtitle ? <PaymentHeader /> : null,
    },
  };

  const { title, subtitle } = stepDetails[step] || {};

  return (
    <>
      {title && (
        <Typography sx={{ paddingBottom: '16px' }} variant="h5">
          {title}
        </Typography>
      )}
      {subtitle}
    </>
  );
};

// TODO: Refactor this, remove this logic and create
// a function to return the correct component, in another file
const createAccordionSteps = ({ resourceApproval }) => {
  const schema: AccordionSteps = {
    [RefundRequestSteps.RESOURCE_DESCRIPTION]: {
      disabled: disableStep({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_DESCRIPTION,
      }),
      expanded: expandStep({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_DESCRIPTION,
      }),
      renderContent: () =>
        renderStepComponent({
          resourceApproval,
          step: RefundRequestSteps.RESOURCE_DESCRIPTION,
        }),
      renderHeader: () =>
        renderStepHeader({ step: RefundRequestSteps.RESOURCE_DESCRIPTION }),
      status: stepStatus({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_DESCRIPTION,
      }),
    },
    [RefundRequestSteps.RESOURCE_APPROVAL]: {
      disabled: disableStep({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_APPROVAL,
      }),
      expanded: expandStep({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_APPROVAL,
      }),
      renderContent: () =>
        renderStepComponent({
          resourceApproval,
          step: RefundRequestSteps.RESOURCE_APPROVAL,
        }),
      renderHeader: () =>
        renderStepHeader({ step: RefundRequestSteps.RESOURCE_APPROVAL }),
      status: stepStatus({
        resourceApproval,
        step: RefundRequestSteps.RESOURCE_APPROVAL,
      }),
    },
    [RefundRequestSteps.INVOICE_ATTACHMENT]: {
      disabled: disableStep({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_ATTACHMENT,
      }),
      expanded: expandStep({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_ATTACHMENT,
      }),
      renderContent: () =>
        renderStepComponent({
          resourceApproval,
          step: RefundRequestSteps.INVOICE_ATTACHMENT,
        }),
      renderHeader: () =>
        renderStepHeader({ step: RefundRequestSteps.INVOICE_ATTACHMENT }),
      status: stepStatus({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_ATTACHMENT,
      }),
    },
    [RefundRequestSteps.INVOICE_VALIDATION]: {
      disabled: disableStep({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_VALIDATION,
      }),
      expanded: expandStep({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_VALIDATION,
      }),
      renderContent: () =>
        renderStepComponent({
          resourceApproval,
          step: RefundRequestSteps.INVOICE_VALIDATION,
        }),
      renderHeader: () =>
        renderStepHeader({ step: RefundRequestSteps.INVOICE_VALIDATION }),
      status: stepStatus({
        resourceApproval,
        step: RefundRequestSteps.INVOICE_VALIDATION,
      }),
    },
    [RefundRequestSteps.PAYMENT]: {
      disabled: disableStep({
        resourceApproval,
        step: RefundRequestSteps.PAYMENT,
      }),
      expanded: expandStep({
        resourceApproval,
        step: RefundRequestSteps.PAYMENT,
      }),
      renderContent: () =>
        renderStepComponent({
          resourceApproval,
          step: RefundRequestSteps.PAYMENT,
        }),
      renderHeader: () =>
        renderStepHeader({ step: RefundRequestSteps.PAYMENT }),
      status: stepStatus({
        resourceApproval,
        step: RefundRequestSteps.PAYMENT,
      }),
    },
  };

  return schema;
};

const RefundRequest = () => {
  const { loading: loadingRefundRequest } = useRefundRequest();

  return (
    <Container fixed maxWidth="md">
      <Box sx={{ py: 3 }}>
        {loadingRefundRequest ? (
          <LinearProgress />
        ) : (
          <>
            <RefundHeader />
            <Accordion />
          </>
        )}
      </Box>
    </Container>
  );
};

const RefundRequestWithContext = ({
  refundRequestUuid,
  resourceApprovalUuid,
}: {
  refundRequestUuid?: string;
  resourceApprovalUuid?: string;
}) => {
  const refundEntitiesUuids: RefundEntitiesUuids = {
    refundRequestUuid,
    resourceApprovalUuid,
  };

  const { isFetching: isFetchingRefundOrder, resourceApproval } =
    useResourceApproval(refundEntitiesUuids);
  const { isFetching: isFetchingPolicies, policy } = useSubsidyPolicies();

  const steps = React.useMemo(
    () => createAccordionSteps({ resourceApproval: resourceApproval }),
    [resourceApproval]
  );

  const initialAccordionState = {
    steps,
  } as AccordionState;

  const refundRequestProviderReducer = (state, action) => {
    switch (action.type) {
      case 'SET_SUBSIDY_POLICY':
        return {
          ...state,
          subsidyPolicy: action.payload,
        };
      case 'SET_RESOURCE_APPROVAL':
        return {
          ...state,
          resourceApproval: action.payload,
        };
      case 'SET_LOADING':
        return { ...state, loading: action.payload };
      default:
        return state;
    }
  };
  const initialRefundRequestProviderValues = {
    loading: true,
  } as RefundRequestState;

  const [refundRequestState, dispatch] = React.useReducer(
    refundRequestProviderReducer,
    initialRefundRequestProviderValues
  );

  React.useEffect(() => {
    dispatch({
      type: 'SET_LOADING',
      payload: isFetchingPolicies || isFetchingRefundOrder,
    });

    dispatch({
      type: 'SET_SUBSIDY_POLICY',
      payload: policy,
    });
    dispatch({ type: 'SET_RESOURCE_APPROVAL', payload: resourceApproval });
  }, [isFetchingPolicies, policy, resourceApproval, isFetchingRefundOrder]);

  return (
    <RefundRequestProvider initialState={refundRequestState}>
      <AccordionProvider initialState={initialAccordionState}>
        <RefundRequest />
      </AccordionProvider>
    </RefundRequestProvider>
  );
};

export default RefundRequestWithContext;
