import React from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { PageContainer } from '@/react/components';
import { ListPlans } from '@/react/components/idp/home/components/list-plans.component';
import { usePlans } from '@/react/data/idp';
import { PlanStatus } from '@/react/data/idp/plans';
import { SectionHeader } from '@/react/components/idp/shared';
import { PageSkeleton } from '@/react/components/idp/shared';
import { useSetTitle } from '@/react/hooks/useSetTitle';
import { NewPlanDialogs } from '@/react/components/idp/dialogs';
import {
  IdpOnboardingDialog,
  localStorageKey as idpOnboardingDialogLocalStorageKey,
} from '@/react/components/idp/dialogs/idp-onboarding-dialog.component';
import { HomeEmptyState } from './components/empty-state';

export const PlansHome = () => {
  useSetTitle('Planos de desenvolvimento');
  const { isFetching: fetchingPlans, plans, plansCount } = usePlans();
  const [openNewPlanDialog, setOpenNewPlanDialog] = React.useState(false);
  const [openIdpOnboardingDialog, setOpenIdpOnboardingDialog] =
    React.useState(false);

  const bypassOnboarding = !!localStorage.getItem(
    idpOnboardingDialogLocalStorageKey
  );

  const handleNewPlan = async () => {
    setOpenNewPlanDialog(true);
  };

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingPlans ? (
          <PageSkeleton />
        ) : (
          <>
            <Box>
              <SectionHeader divider>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 2, md: 6 }}
                    width="100%"
                  >
                    <Stack spacing={1} sx={{ flexGrow: 1 }}>
                      <Typography variant="h4">
                        Plano de desenvolvimento
                      </Typography>
                      <Typography variant="body3">
                        Construa um plano claro e estruturado para desenvolver
                        as competências que vão impulsionar sua carreira. Você
                        pode seguir uma recomendação personalizada da nossa
                        Inteligência Artificial, criar seu próprio plano ou
                        ajustá-lo a partir da nossa sugestão.{` `}
                        <Link
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenIdpOnboardingDialog(true);
                          }}
                          variant="body3"
                        >
                          Veja como funciona
                        </Link>
                      </Typography>
                    </Stack>
                    <Box flexShrink={0}>
                      <Button
                        onClick={handleNewPlan}
                        size="large"
                        startIcon={<AddIcon />}
                        variant="contained"
                      >
                        Novo plano
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </SectionHeader>
            </Box>
            {plansCount > 0 && (
              <Box>
                <ListPlans plans={plans} />
              </Box>
            )}
            {plansCount === 0 && <HomeEmptyState />}
          </>
        )}
        <NewPlanDialogs
          onClose={() => setOpenNewPlanDialog(false)}
          open={openNewPlanDialog}
          draftPlan={plans[PlanStatus.DRAFT]}
        />
        <IdpOnboardingDialog
          open={openIdpOnboardingDialog || !bypassOnboarding}
          onClose={() => {
            setOpenIdpOnboardingDialog(false);
          }}
        />
      </Box>
    </PageContainer>
  );
};
