import bgFree from '@/react/assets/images/free.png';
import bgGraduation from '@/react/assets/images/graduation.png';
import bgIncludedInPlan from '@/react/assets/images/included.png';
import bgLanguages from '@/react/assets/images/languages.png';
import bgPostgraduate from '@/react/assets/images/postgraduate.png';
import bgTechnical from '@/react/assets/images/technical.png';

const courseTypesData = {
  full: {
    description: 'Veja os cursos sem custo para você.',
    image: bgIncludedInPlan,
  },
  free: {
    description: 'Explore novos interesses e desenvolva habilidades únicas.',
    image: bgFree,
  },
  graduation: {
    description: 'Dê um passo importante para o seu futuro profissional.',
    image: bgGraduation,
  },
  languages: {
    description: 'Conecte-se com o mundo aprendendo novos idiomas.',
    image: bgLanguages,
  },
  postgraduate: {
    description: 'Aprofunde seu conhecimento e se destaque no mercado.',
    image: bgPostgraduate,
  },
  technical: {
    description: 'Adquira habilidades práticas para uma carreira de sucesso.',
    image: bgTechnical,
  },
};

export const fillCourseTypeData = (
  type: string,
  description?: string,
  image?: string
) => {
  const fallback = courseTypesData[type] || {};
  return {
    description: description || fallback.description || '',
    image: image || fallback.image || '',
  };
};
