import { toLocaleDateString } from '@/react/utils/time';
import {
  PlanResponse,
  PlanStatus,
  PlanType,
} from '@/react/data/idp/plans/interfaces';
import { Activity } from '@/react/data/idp/activities';

import { ProfileBasedRecommendation } from '@/react/data/idp/profile';

export default class Plan implements PlanType {
  activities: Activity[];
  description: string;
  durationInMonths: number;
  endedAt: string;
  estimatedEndsAt: string;
  id: string;
  startedAt: string;
  status: PlanStatus;
  title: string;
  profileBasedRecommendation?: ProfileBasedRecommendation;

  constructor(args: PlanType) {
    const {
      activities,
      id,
      title,
      description,
      durationInMonths,
      startedAt,
      estimatedEndsAt,
      endedAt,
      status,
      profileBasedRecommendation,
    } = args;

    this.activities = activities;
    this.id = id;
    this.title = title;
    this.description = description;
    this.durationInMonths = durationInMonths;
    this.startedAt = toLocaleDateString(startedAt);
    this.estimatedEndsAt = toLocaleDateString(estimatedEndsAt);
    this.endedAt = toLocaleDateString(endedAt);
    this.status = status;
    this.profileBasedRecommendation = profileBasedRecommendation;
  }

  static fromServerPayload(payload: PlanResponse): Plan {
    return new Plan({
      activities: payload.activities,
      description: payload.description,
      durationInMonths: payload.duration_in_months,
      endedAt: payload.ended_at,
      estimatedEndsAt: payload.estimated_ends_at,
      id: payload.id,
      startedAt: payload.started_at,
      status: payload.status,
      title: payload.title,
      profileBasedRecommendation: payload.profile_based_recommendation,
    });
  }
}
