import * as React from 'react';
import { styled } from '@mui/material/styles';

import logoGalenaEdupass from '@/react/assets/logo-nav-galena.svg';

const DEFAULT_WIDTH = 80;

const LogoWrapper = styled('div')(({ theme, sx }) => ({
  ...(Array.isArray(sx) ? sx : [sx]),

  '& img': {
    width: '100%',
  },
}));

type LogoProps = {
  width?: number;
};

export const Logo: React.FC<LogoProps> = ({ width = DEFAULT_WIDTH }) => {
  return (
    <LogoWrapper sx={{ width }}>
      <img src={logoGalenaEdupass} />
    </LogoWrapper>
  );
};
