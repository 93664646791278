import React from 'react';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { Activity } from '@/react/data/idp/activities';
import HelpCenterImage from '@/react/assets/images/help-center.svg';

export interface ConfirmDeleteActivityDialogProps {
  open: boolean;
  onClose: () => void;
  activity: Activity;
}

const handleDeleteActivity = async ({ activity, onSuccess, onError }) => {
  try {
    await IdpClient.deleteActivity(activity.planId, activity.id);
    onSuccess();
  } catch (error) {
    onError(
      'Ocorreu um erro ao excluir o atividade. Tente novamente mais tarde'
    );
    Sentry.captureException(error);
  }
};

export const ConfirmDeleteActivityDialog = ({
  open,
  onClose,
  activity,
}: ConfirmDeleteActivityDialogProps) => {
  const { toast } = useNotification();

  const listeners = {
    onError: (error: string) => {
      toast({
        content: error,
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      window.location.href = `/pdi/planos/${activity.planId}`;
    },
  };

  return (
    <IdpConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={() => handleDeleteActivity({ activity, ...listeners })}
      actionLabels={{
        confirm: 'Excluir Atividade',
        cancel: 'Voltar',
      }}
      image={HelpCenterImage}
      title="Tem certeza de que quer excluir este atividade?"
      subtitle={activity.title}
      description="A atividade será excluída do seu plano e essa ação não poderá ser desfeita."
    />
  );
};
