import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { PlanCard } from '@/react/components/idp/home/components';
import illustrationExplore from '@/react/assets/images/illustration_explore.svg';

interface PlanCardDraft extends React.ComponentProps<typeof PlanCard> {}

export const PlanCardDraft: React.FC<PlanCardDraft> = ({ plan }) => {
  return (
    <PlanCard cardActionLabel="Retomar rascunho" plan={plan}>
      <Box sx={{ backgroundColor: 'neutral.100', p: 2, display: 'flex' }}>
        <Box
          component="img"
          src={illustrationExplore}
          sx={{ width: 88, mr: 2 }}
        />
        <Stack gap={2}>
          <Typography variant="body1" color="primary.900">
            Falta tão pouco...
          </Typography>
          <Typography variant="body3">
            Finalize o seu rascunho e comece a se desenvolver com a Galena.
          </Typography>
        </Stack>
      </Box>
    </PlanCard>
  );
};
