import { useQuery } from '@tanstack/react-query';
import { ResourceApprovalResponse } from '@/react/data/subsidy/interfaces';
import { RefundEntitiesUuids } from '@/react/components/refund/refund-request/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useResourceApproval(requestParams: RefundEntitiesUuids) {
  const result = useQuery<ResourceApprovalResponse>({
    // This query should be disabled if the resource approval uuid is not present.
    // This means that the resource approval is not created yet.
    enabled: !!requestParams.resourceApprovalUuid,
    queryKey: ['useResourceApproval', requestParams],
    queryFn: async ({ signal }) => {
      return SubsidyClient.getResourceApproval({ requestParams, signal });
    },
    refetchOnMount: false,
  });

  const resourceApproval = result.data?.resource_approval || null;

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    isLoading: result.isLoading,
    resourceApproval,
  };
}
