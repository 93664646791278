import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import React, { FC, ReactNode } from 'react';
import { Plan } from '@/react/data/idp/interfaces';

type PlanCard = {
  cardActionLabel?: string;
  children?: ReactNode;
  hideTitle?: boolean;
  plan: Plan;
};

export const createPlanRoute = (plan: Plan): string => {
  return `/pdi/planos/${plan.id}`;
};

const defaultActionLabel = 'Ver Plano';

export const PlanCard: FC<PlanCard> = ({
  cardActionLabel = defaultActionLabel,
  children,
  hideTitle = false,
  plan,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {!hideTitle && (
        <CardHeader
          title={
            <Typography variant="h5">
              {plan.title || 'Plano sem título'}
            </Typography>
          }
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <CardContent
          sx={{ paddingBottom: '0', ...(!hideTitle && { paddingTop: '0' }) }}
        >
          {children}
        </CardContent>
        <CardActions sx={{ marginTop: 'auto' }}>
          <Button
            endIcon={<ArrowForwardRoundedIcon />}
            href={createPlanRoute(plan)}
            variant="text"
          >
            {cardActionLabel}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
