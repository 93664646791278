import { Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Pagination,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';

import { RefundResourceStatus } from '@/react/data/subsidy/interfaces';
import { useResourceApprovalsToReviewList } from '@/react/data/subsidy/useResourceApprovalsToReviewList';

type VariantType = 'contained' | 'outlined' | 'text';
interface RowAction {
  variantType: VariantType;
  text: string;
  href: string;
}

const ListResourceApprovalDataGrid = () => {
  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterIdentifier, setFilterIdentifier] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('desc');
  const [loading, setLoading] = useState(false);

  const requestParams = {
    status: filterStatus,
    page,
    per_page: 10,
    identifier: filterIdentifier,
    order: sortDirection,
  };

  const { resourceApprovals, totalPages, isFetching, totalItems, error } =
    useResourceApprovalsToReviewList(requestParams);

  useEffect(() => {
    if (totalPages) {
      setTotalPageCount(totalPages);
    }
  }, [page, totalPages, filterStatus, filterIdentifier]);

  const customPagination = () => {
    const apiRef = useGridApiContext();
    return (
      <Pagination
        color="primary"
        count={totalPageCount}
        page={page}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const handlePageChange = (params: { page: number }) => {
    setPage(params.page + 1);
  };

  const handleFilterChange = (_event, newValue) => {
    if (typeof newValue === 'string') {
      setLoading(true);
      setFilterIdentifier('');
      setFilterStatus(newValue);
      setPage(1);
    }
  };

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  const handleRowAction = ({ resourceApprovalUuid, status }): RowAction => {
    const currentPath = window.location.pathname;
    let action: RowAction;

    if (status === RefundResourceStatus.pending_approval) {
      action = {
        variantType: 'contained',
        text: 'Analisar pedido',
        href: `${currentPath}/${resourceApprovalUuid}/avaliar-pedido`,
      };
    } else {
      action = {
        variantType: 'outlined',
        text: 'Ver detalhes',
        href: `${currentPath}/${resourceApprovalUuid}/detalhes`,
      };
    }

    return action;
  };

  const tabLabels = [
    { label: 'Todos', value: 'all' },
    { label: 'Pendentes', value: 'pending' },
    { label: 'Aprovados', value: 'approved' },
    { label: 'Recusados', value: 'rejected' },
    { label: 'Cancelados', value: 'canceled' },
  ];

  const handleFilterIdentifier = () => {
    setFilterIdentifier(inputValue);
    setPage(1);
  };

  const handleSortChange = (sort: any) => {
    if (sort.length > 0) {
      const newDirection = sort[0].sort || 'desc';
      setSortDirection(newDirection);
      setPage(1);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));
  };

  const columns: GridColDef[] = [
    {
      field: 'requested_at',
      headerName: 'Data de Solicitação',
      flex: 1,
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString('pt-BR');
      },
    },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.user?.name;
      },
    },
    {
      field: 'email',
      headerName: 'Usuário',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.user?.email;
      },
    },
    {
      field: 'requested_refundable_amount',
      headerName: 'Valor reembolsável',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span data-testid={`currency-value-${params.row.uuid}`}>
            {formatCurrency(params.value)}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      sortable: false,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        const { variantType, text, href } = handleRowAction({
          resourceApprovalUuid: params.row.uuid,
          status: params.row.status,
        });
        return (
          <Button href={href} variant={variantType} sx={{ borderRadius: 0 }}>
            {text}
          </Button>
        );
      },
    },
  ];

  const customLocaleText = {
    columnMenuFilter: 'Filtrar',
    columnMenuSelectAll: 'Selecionar todos',
    columnMenuUnselectAll: 'Desmarcar todos',
    columnMenuItemsPerPage: 'Itens por página:',
    columnMenuSortAsc: 'Ordenar crescente',
    columnMenuSortDesc: 'Ordenar decrescente',
    columnMenuHideColumn: 'Ocultar coluna',
    columnMenuManageColumn: 'Gerenciar colunas',
    noRowsLabel: 'Nenhuma solicitação encontrada',
  };

  return (
    <>
      <Box
        display="contents"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        p={2}
        gap={2}
      >
        <Box
          display="flex"
          gap={1}
          flexGrow={1}
          maxWidth={{ xs: '100%', md: 'auto' }}
        >
          <TextField
            label="Pesquise por usuário"
            variant="outlined"
            size="medium"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleFilterIdentifier();
              }
            }}
            sx={{ flexShrink: 0, width: 'auto', borderRadius: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ pl: 3 }}
                    aria-label="search"
                    onClick={handleFilterIdentifier}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            onClick={handleFilterIdentifier}
          >
            Pesquisar
          </Button>
        </Box>

        <Tabs
          value={filterStatus}
          onChange={handleFilterChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            width: { xs: '100%', md: 'auto' },
            marginTop: { xs: '15px' },
          }}
        >
          {tabLabels.map(({ label, value }) => (
            <Tab
              key={nanoid()}
              label={label}
              value={value}
              sx={({ palette }) => ({
                border: `1px solid ${palette.neutral?.[300] || '#E0E0E0'}`,
                background: palette.neutral?.[50] || '#FAFAFA',
                color: palette.primary?.[900] || '#1A202C',
                '&.Mui-selected': {
                  background: palette.primary?.[100] || '#E3F2FD',
                  border: `1px solid ${palette.primary?.[900] || '#1A202C'}`,
                  borderBottom: `1px solid ${palette.primary?.[900] || '#1A202C'}`,
                },
              })}
            />
          ))}
        </Tabs>
      </Box>

      {isFetching && !error && <LinearProgress />}
      <Box sx={{ width: '100%', overflowX: 'auto', mt: 2 }}>
        <Paper sx={{ width: '100%', minWidth: 800, overflow: 'hidden', p: 2 }}>
          {loading && <LinearProgress />}
          <DataGrid
            rows={(Array.isArray(resourceApprovals)
              ? resourceApprovals
              : []
            ).map((row) => ({ ...row, id: row.uuid }))}
            rowCount={totalPageCount * 10}
            loading={isFetching}
            columns={columns}
            autoHeight
            pagination
            paginationMode="server"
            paginationModel={{ pageSize: 10, page: page - 1 }}
            slots={{
              pagination: customPagination,
            }}
            onPaginationModelChange={(params) => handlePageChange(params)}
            onSortModelChange={handleSortChange}
            disableRowSelectionOnClick
            localeText={customLocaleText}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ListResourceApprovalDataGrid;
