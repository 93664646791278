import { useQuery } from '@tanstack/react-query';
import { RecommendedActivity } from '../idp/activities/interfaces';
import { RecommendationClient } from './recommendation-client';

interface useRecommendedActivitiesProps {
  recommendedActivity: RecommendedActivity | null;
  error: boolean;
  isFetching: boolean;
}

export const queryKey = ['useRecommendedActivities'];

export const useRecommendedActivities = ({
  activityId,
  enabled = true,
}: {
  activityId: string;
  enabled?: boolean;
}): useRecommendedActivitiesProps => {
  const result = useQuery<RecommendedActivity>({
    enabled,
    queryKey: [...queryKey, activityId],
    queryFn: async () => {
      return RecommendationClient.getRecommendedActivities(activityId);
    },
    refetchOnMount: false,
  });

  const recommendedActivity = result.data || null;

  return {
    recommendedActivity,
    error: !!result.error,
    isFetching: result.isFetching,
  };
};
