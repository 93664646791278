import React, { ReactNode } from 'react';
import { Box, Stack, Typography, ToggleButton } from '@mui/material';

interface RecommendationOptionProps {
  value: string;
  title: string;
  description: string;
  selected: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  icon: ReactNode;
}

export const RecommendationOption = ({
  value,
  title,
  description,
  selected,
  onChange,
  icon,
}: RecommendationOptionProps) => {
  return (
    <ToggleButton value={value} selected={selected} onChange={onChange}>
      <Stack
        sx={{
          textAlign: 'left',
        }}
        spacing={0}
        width="100%"
        height="100%"
      >
        <Box
          sx={{
            height: 48,
            width: 48,
            margin: '0 0 8px',
            padding: '10px',
            backgroundColor: '#FCFDB3',
          }}
        >
          {icon}
        </Box>
        <Typography variant="body3" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body3">{description}</Typography>
      </Stack>
    </ToggleButton>
  );
};
