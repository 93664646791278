import React from 'react';
import {
  Typography,
  Stack,
  Skeleton
} from '@mui/material';

const FieldSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Typography component="div" variant="body1">
        <Skeleton width="20%" />
      </Typography>
    </Stack>
  );
};
  
export const FieldWrapper = ({ loading, children }) => {
  return loading ? <FieldSkeleton /> : children;
};
