import * as React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import { Box, Typography } from '@mui/material';

import { BaseDialog } from '@/react/components/dialog/base-dialog.component';
import HelpCenterAsset from '@/react/assets/images/help-center.svg';

interface ActionsProps {
  actions: (ButtonProps & { label: string })[];
}

const Actions = ({ actions }: ActionsProps) => {
  return (
    <>
      {actions.map(({ label, ...props }) => (
        <Button {...props} key={label}>
          {label}
        </Button>
      ))}
    </>
  );
};

interface ConfirmDiscardRecommendationDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinueLater: () => void;
  onConfirm: () => Promise<void>;
}
export const ConfirmDiscardRecommendationDialog = ({
  open,
  onCancel,
  onContinueLater,
  onConfirm,
}: ConfirmDiscardRecommendationDialogProps) => {
  return (
    <BaseDialog
      actionsComponent={
        <Actions
          actions={[
            { label: 'Cancelar', variant: 'text', onClick: onCancel },
            {
              label: 'Continuar depois',
              variant: 'outlined',
              onClick: onContinueLater,
            },
            {
              label: 'Descartar plano',
              variant: 'contained',
              onClick: onConfirm,
              color: 'error',
            },
          ]}
        />
      }
      reverseMobileDirection={true}
      disableEscapeKeyDown
      onClose={onCancel}
      open={open}
    >
      <Box
        component="img"
        src={HelpCenterAsset}
        sx={{ height: 128, width: 128, margin: '0 auto' }}
      />
      <Typography variant="h6" gutterBottom>
        Tem certeza de que deseja sair?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Você ainda não concluiu a criação do seu plano. Se sair, perderá o que
        fez até agora.
      </Typography>
    </BaseDialog>
  );
};
