import * as React from 'react';

import { Grid2 as Grid } from '@mui/material';

import { LearningTypeCard, LearningTypeCardProps } from '../learning-type-card';

export type LearningTypeListProps = {
  data: LearningTypeCardProps[];
  buttonsText?: string;
};

export const LearningTypeList = ({
  data,
  buttonsText,
}: LearningTypeListProps) => (
  <Grid container spacing={{ xs: 2, sm: 4 }}>
    {data?.map((courseType, idx) => (
      <Grid size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={idx}>
        <LearningTypeCard {...courseType} buttonText={buttonsText} />
      </Grid>
    ))}
  </Grid>
);
