import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployeeRefundGuard } from '@/services/guards/employee-refund.guard';
import { TermsGuard } from '@/services/guards/terms.guard';

import { TermsComponent } from './terms/terms.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { ManageDependentsComponent } from './dependents/manage-dependents.component';
import { MyRefundsPendingComponent } from './my-refunds/refunds-pending/refunds-pending.component';
import { RefundFormComponent } from './refund-form/refund-form.component';
import { ColaboradorRoutes } from './colaborador.routes';
import { OverviewComponent } from './my-refunds/overview/overview.component';
import { ManageRefundsComponent } from './manage-refunds/manage-refunds.component';
import { RefundRequestComponent } from './refund-request/refund-request.component';
import { RefundPoliciesComponent } from './refund-policies/refund-policies.component';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { SubsidiesGuard } from '@/services/guards/subsidies.guard';
import { SubsidiesCreateRefundGuard } from '@/services/guards/subsidies.create-refund.guard';

const routes: Routes = [
  {
    path: 'terms',
    component: TermsComponent,
  },

  {
    path: '',
    canActivate: [TermsGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/cursos',
      },
      {
        path: ColaboradorRoutes.MANAGE_DEPENDENTS,
        component: ManageDependentsComponent,
      },
      {
        path: ColaboradorRoutes.MY_SUBSIDIES,
        canActivate: [SubsidiesGuard],
        children: [
          {
            path: '',
            component: ManageRefundsComponent,
          },
          {
            path: ColaboradorRoutes.REFUND_REQUEST,
            component: RefundRequestComponent,
            canActivate: [SubsidiesCreateRefundGuard],
          },
          {
            path: ColaboradorRoutes.REFUND_POLICIES,
            component: RefundPoliciesComponent,
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.REFUND_DESCRIPTION}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.RESOURCE_DESCRIPTION },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.REFUND_APPROVAL}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.RESOURCE_APPROVAL },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.REFUND_INVOICE}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.INVOICE_ATTACHMENT },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.REFUND_VALIDATION}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.INVOICE_VALIDATION },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.REFUND_PAYMENT}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.PAYMENT },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/pedido/:refundRequestUuid/${ColaboradorRoutes.REFUND_DESCRIPTION}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.RESOURCE_DESCRIPTION },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/pedido/:refundRequestUuid/${ColaboradorRoutes.REFUND_INVOICE}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.INVOICE_ATTACHMENT },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/pedido/:refundRequestUuid/${ColaboradorRoutes.REFUND_FORM_EDIT}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.INVOICE_EDIT },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/pedido/:refundRequestUuid/${ColaboradorRoutes.REFUND_VALIDATION}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.INVOICE_VALIDATION },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/pedido/:refundRequestUuid/${ColaboradorRoutes.REFUND_PAYMENT}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.PAYMENT },
          },
          {
            path: `solicitacao/:resourceApprovalUuid/${ColaboradorRoutes.RESOURCE_APPROVAL_FORM_EDIT}`,
            component: RefundRequestComponent,
            data: { step: RefundRequestSteps.RESOURCE_DESCRIPTION },
          },
        ],
      },
      {
        path: 'alterar_senha',
        component: AlterarSenhaComponent,
      },
      {
        path: '',
        canActivate: [EmployeeRefundGuard],
        children: [
          {
            path: 'refund_form',
            component: RefundFormComponent,
          },
          {
            path: ColaboradorRoutes.MY_REFUNDS,
            children: [
              {
                path: ColaboradorRoutes.PENDING,
                component: MyRefundsPendingComponent,
              },
              {
                path: ColaboradorRoutes.OVERVIEW,
                component: OverviewComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ColaboradorRoutingModule {}
