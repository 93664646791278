import React from 'react';
import { debounce } from '@mui/material/utils';
import {
  Autocomplete,
  FormControl,
  Stack,
  Typography,
  Grid2 as Grid,
  Skeleton,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { CatalogFilters } from '@/react/data/catalog/interfaces';
import { formatDuration } from '@/react/utils/time';
import { useBootstrapData } from '@/react/data/bootstrap';
import { useCatalogSearchForRefund } from '@/react/data/catalog/useCatalogSearchForRefund';
import { useInstitutionsSearch } from '@/react/data/institutions/useInstitutionsData';

const defaultValues = {
  course: null,
  courseType: '',
  institution: null,
};

const formValidationSchema = z.object({
  course: z.object(
    {
      id: z.number(),
      label: z.string(),
      offerId: z.number(),
    },
    {
      errorMap: () => {
        return { message: 'Curso obrigatório' };
      },
    }
  ),
  institution: z.object(
    {
      name: z.string(),
      id: z.number(),
    },
    {
      errorMap: () => {
        return { message: 'Instituição de ensino obrigatória' };
      },
    }
  ),
});

const FieldSkeleton = () => (
  <FormControl fullWidth>
    <Stack spacing={1}>
      <Typography component="div" variant="body3">
        <Skeleton width="20%" />
      </Typography>
      <Skeleton height={53} variant="rounded" />
    </Stack>

    <FormHelperText />
  </FormControl>
);

const FieldWrapper = ({ loading, children }) =>
  loading ? <FieldSkeleton /> : children;

const formatCourseLabel = (course) => {
  const formatCourseDuration = ({ duration: { type, quantity } }) =>
    formatDuration(type, quantity);

  const formattedDuration = formatCourseDuration(course);
  return `${course.course_name}, ${course.course_type.label}, ${formattedDuration}, ${course.modalities.map(({ label }) => label).join(',')}`;
};

const mountCourseOptions = (courses) =>
  courses.map((course) => {
    const label = formatCourseLabel(course);

    return {
      courseType: course.course_type,
      id: course.id,
      label,
      modality: course.modalities[0],
      offerId: course.offer_id,
      rawCourse: course,
    };
  });

const handleErrormessage = (error) => {
  return error ? error.message : '';
};
const DEBOUNCE_CATALOG_SEARCH_MS = 500;

export const CourseSelection = ({
  onSubmit,
}: {
  onSubmit: (course) => void;
}) => {
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema),
  });
  const [selectedCourse, setSelectedCourse] = React.useState({});
  const [catalogSearchTerm, setCatalogSearchTerm] = React.useState('');
  const bootstrapData = useBootstrapData();
  const { institutions, isFetching: institutionsLoading } =
    useInstitutionsSearch();

  const loadingBootstrapData = bootstrapData.isFetching || institutionsLoading;

  const { course, institution } = watch();
  const catalogSearchFilters = {} as Partial<CatalogFilters>;

  if (institution) {
    catalogSearchFilters.institutions = [institution.id];
  }
  if (catalogSearchTerm) {
    catalogSearchFilters.terms = catalogSearchTerm;
  }
  const { catalog: catalogData, isFetching: fetchingCatalog } =
    useCatalogSearchForRefund(catalogSearchFilters);
  const debounceCatalogSearchTerm = React.useMemo(
    () =>
      debounce((value) => {
        setCatalogSearchTerm(value);
      }, DEBOUNCE_CATALOG_SEARCH_MS),
    []
  );

  const courseOptions = mountCourseOptions(catalogData.items || []);

  return (
    <form
      onSubmit={handleSubmit(() => {
        onSubmit(selectedCourse);
      })}
      id="course-selection-form"
    >
      <Stack spacing={2}>
        <FieldWrapper loading={false}>
          <Controller
            control={control}
            name="institution"
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <Stack spacing={1}>
                  <FormLabel htmlFor="institution">
                    Instituição de Ensino
                  </FormLabel>
                  <Autocomplete
                    {...field}
                    data-testid="institution-autocomplete"
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    id="institution"
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    onChange={(_, option, reason) => {
                      if (reason === 'clear') {
                        reset({ ...defaultValues });
                      }
                      if (reason === 'selectOption') {
                        reset({ ...defaultValues, institution: option });
                      }
                    }}
                    openOnFocus
                    options={institutions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        placeholder="Selecione"
                      />
                    )}
                    value={institution}
                  />
                </Stack>
              </FormControl>
            )}
          />
        </FieldWrapper>
        <FieldWrapper loading={loadingBootstrapData}>
          <Controller
            control={control}
            name="course"
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} fullWidth>
                <Stack spacing={1}>
                  <FormLabel htmlFor="course">Curso</FormLabel>
                  <Autocomplete
                    {...field}
                    autoSelect
                    clearOnBlur
                    data-testid="course-name-autocomplete"
                    disabled={!institution}
                    fullWidth
                    id="course"
                    includeInputInList
                    isOptionEqualToValue={(a, b) => {
                      return a.id === b.id;
                    }}
                    loading={fetchingCatalog}
                    loadingText="Carregando..."
                    noOptionsText={
                      !catalogSearchTerm
                        ? 'Digite o nome de um curso'
                        : 'Não encontramos nenhum curso com esses parâmetros.'
                    }
                    onBlur={() => {
                      setCatalogSearchTerm('');
                    }}
                    onChange={(_, option, reason) => {
                      const keepFields = {
                        ...defaultValues,
                        institution,
                      };
                      if (reason === 'clear') {
                        reset({ ...keepFields }, { keepDefaultValues: true });
                      }
                      if (reason === 'selectOption') {
                        reset(
                          {
                            ...keepFields,
                            course: option,
                            courseType: option.courseType.type,
                          },
                          { keepDefaultValues: true }
                        );
                        setSelectedCourse({
                          ...option.rawCourse,
                        });
                      }
                    }}
                    onInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        debounceCatalogSearchTerm(value);
                      }
                    }}
                    openOnFocus
                    options={courseOptions}
                    renderInput={(params) => {
                      return (
                        <TextField
                          error={!!error}
                          helperText={handleErrormessage(error)}
                          placeholder="Selecione"
                          {...params}
                        />
                      );
                    }}
                    value={course}
                  />
                </Stack>
              </FormControl>
            )}
          />
        </FieldWrapper>
      </Stack>
    </form>
  );
};
