import React, { useState } from 'react';

import { CatalogCourse } from '@/react/data/catalog/interfaces';
import { RecommendedPlanActivity } from '@/react/data/idp/profile/interfaces';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Stack, ToggleButton, Typography } from '@mui/material';
import { useSelectedCourses } from '@/react/components/idp/context/selected-courses';
import { RecommendedCoursesDialog } from '../../dialogs/recommended-courses-dialog';
import { RecommendedCoursesButton } from '../../shared/recommended-courses-button';
import { SelectedCourseCard } from '../../shared/selected-course-card';

interface RecommendedActivityCardProps {
  activity: RecommendedPlanActivity;
  isChecked: boolean;
  onToggle: (selected?: boolean) => void;
  selectedCourse: CatalogCourse | undefined;
}

const checkBoxSx = (theme) => ({
  color: theme.palette.primary[700],
});

const Icon = ({ isChecked }) =>
  isChecked ? (
    <CheckBoxIcon sx={checkBoxSx} />
  ) : (
    <CheckBoxOutlineBlankIcon sx={checkBoxSx} />
  );

export const RecommendedActivityCard = ({
  activity,
  isChecked,
  onToggle,
  selectedCourse,
}: RecommendedActivityCardProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { addCourse, removeCourse } = useSelectedCourses();

  const handleClick = () => {
    onToggle();
  };

  const handleRemoveCourse = () => {
    removeCourse(activity.id);
  };

  return (
    <>
      <ToggleButton
        value={activity.id}
        selected={isChecked}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-start',
          }}
        >
          <Icon isChecked={isChecked} />
          <Stack
            sx={{
              textAlign: 'left',
              ml: { md: 2 },
            }}
            spacing={0}
            width="100%"
          >
            <Typography variant="body3" fontWeight="bold">
              {activity.name}
            </Typography>
            <Typography variant="body3">{activity.reason}</Typography>
            {selectedCourse ? (
              <Box mt={2}>
                <SelectedCourseCard
                  course={selectedCourse}
                  removable
                  onRemove={handleRemoveCourse}
                />
              </Box>
            ) : (
              <>
                {activity.activity_type === 'course' && (
                  <Box mt={2}>
                    <RecommendedCoursesButton
                      onClick={() => setIsDialogOpen(true)}
                      courseType={activity.course_type}
                    />
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Box>
      </ToggleButton>
      <RecommendedCoursesDialog
        activityId={activity.id}
        onClose={() => setIsDialogOpen(false)}
        onSave={(course) => {
          addCourse(activity.id, course);
          onToggle(true);
        }}
        open={isDialogOpen}
      />
    </>
  );
};
