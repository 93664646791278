import * as React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

export type BaseDialogTitleProps = {
  onClose: () => void;
} & DialogTitleProps;

export const BaseDialogTitle = ({
  children,
  onClose,
}: BaseDialogTitleProps) => {
  return (
    <DialogTitle>
      <Stack
        sx={{
          alignItems: 'flex-start',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        {children && <Box sx={{ flex: 1 }}>{children}</Box>}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: ({ palette }) => palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};
