import React from 'react';

import { ContentBox } from '../../shared';
import { Box, Stack, Typography } from '@mui/material';
import enrollmentInProgress from '@/react/assets/images/enrollment-in-progress.svg';

export const HomeEmptyState = () => {
  return (
    <ContentBox p={3}>
      <Stack alignItems="center" direction="column" gap={2}>
        <Box component="img" src={enrollmentInProgress} />
        <Typography variant="h6" color="primary.900">
          Ainda não existem planos aqui...
        </Typography>
        <Typography variant="body3">
          Clique no botão ‘Novo plano’ para começar a sua jornada de
          aprendizado.
        </Typography>
      </Stack>
    </ContentBox>
  );
};
