import React from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import ListResourceApprovalDataGrid from './home/list-resource-approval-data-grid';

export const RefundManagement = () => {
  const { isSmallScreen } = useScreenSizes();

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: isSmallScreen ? '24px' : '32px' }}
      >
        <Grid item xs={12} sm={8} md={9} marginBottom={1}>
          <Typography
            component="h1"
            gutterBottom
            color="primary.200"
            variant="subtitle1"
          >
            Gestão de Reembolso
          </Typography>
          <Typography component="p" color="neutral.800" variant="body3">
            Gerencie, aprove e acompanhe todos os pedidos de reembolso.
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <ListResourceApprovalDataGrid />
      </Grid>
    </Container>
  );
};

export default RefundManagement;
