import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { FieldWrapper } from '@/react/components/refund/refund-management/shared';
import { formatDate } from '@/react/utils/date';

export const RefundRequestHistoryDetails = ({ refund_request, isFetching }) => {
  const findApprovedHistory = (refund_request) => {
    const approvedItem = refund_request?.history?.find(
      (item) => item.approved === true
    );
    return approvedItem
      ? { message: approvedItem.message, approved: approvedItem.approved }
      : null;
  };

  const ApprovedMessages = ({ history }: { history: any[] }) => {
    return (
      <Box sx={{ width: '100%' }}>
        {history?.length > 0 ? (
          history.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <Typography variant="body3" display="block">
                {item.message}
              </Typography>
              <Typography variant="body3" display="block">
                {item.decision_comment}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body3">Nenhum histórico disponível</Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={({ palette }) => ({
          padding: 2,
          border: `1px solid ${palette.neutral[300]}`,
          backgroundColor: `${palette.neutral[50]}`,
          my: 2,
        })}
      >
        <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} mt={1} mb={2.5}>
            <Typography
              variant="body1"
              style={{ fontWeight: '600' }}
              color="primary.200"
            >
              Histórico
            </Typography>
          </Grid>
          <Grid container rowSpacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={3}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Solicitação do item
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={9}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {formatDate(refund_request?.created_at) || '--'}
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} md={3}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Aprovação do item
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={9}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {findApprovedHistory(refund_request)?.message || '--'}
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} md={3}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Envio de comprovantes
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={9}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3">
                  {formatDate(refund_request?.created_at) || '--'}
                </Typography>
              </FieldWrapper>
            </Grid>

            <Grid item xs={12} md={3}>
              <FieldWrapper loading={isFetching}>
                <Typography variant="body3" fontWeight={600}>
                  Validação de comprovantes
                </Typography>
              </FieldWrapper>
            </Grid>
            <Grid item xs={12} md={9}>
              <FieldWrapper loading={isFetching}>
                <ApprovedMessages history={refund_request?.history} />
              </FieldWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
