import { ShowPlan } from '@/react/components/idp/show-plan';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';

@Component({
  selector: 'app-show-plan',
  templateUrl: './show-plan.component.html',
})
export class IdpShowPlanComponent implements OnInit {
  IdpShowPlanComponent = ShowPlan;
  showPlanProps: ComponentProps<typeof ShowPlan> = {
    activityId: undefined,
    planId: undefined,
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.showPlanProps = {
        activityId: params.activityId,
        planId: params.id,
      };
    });
  }
}
