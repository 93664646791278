import React, { useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import HelpCenterImage from '@/react/assets/images/help-center.svg';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { ContentBox } from '@/react/components/idp/shared';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CatalogCourse } from '@/react/data/catalog/interfaces';
import { ProfileBasedRecommendation } from '@/react/data/idp/profile';
import { useRedoRecommendation } from '@/react/data/idp/useRedoRecommendation';
import { useSetTitle } from '@/react/hooks/useSetTitle';
import { mutationKey as useCompleteProfileBasedRecommendationMutationKey } from '@/react/data/idp/useCompleteProfileBasedRecommendation';
import { useWizard } from '@/react/components/wizard/state';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { SelectedCoursesProvider } from '@/react/components/idp/context/selected-courses';
import { RecommendedActivityCard } from './recommended-activity-card.component';
import { useSelectedCourses } from '@/react/components/idp/context/selected-courses';

type SelectedActivity = {
  [key: string]: boolean;
};

type SelectedCourses = {
  [key: string]: CatalogCourse;
};

export const formValidationSchema = z.object({
  selectedActivities: z
    .record(z.string(), z.custom<CatalogCourse>().nullable())
    .optional(),
});

const parseDefaultValuesFromActivities = (recommendedActivities) =>
  recommendedActivities.reduce((acc, activity) => {
    acc[activity.id] = true;
    return acc;
  }, {});

const parseActivitiesToServerPayload = (
  selectedActivities: SelectedActivity,
  selectedCourses: SelectedCourses
) => {
  const payload = Object.keys(selectedActivities).map((activityId) => {
    const selectedCourse = selectedCourses[activityId];
    return {
      id: activityId,
      selected_course: selectedCourse || null,
    };
  });

  return payload;
};

export const RecommendationReviewComponent: React.ElementType<
  WizardStepComponent & {
    profileBasedRecommendation: Partial<ProfileBasedRecommendation>;
  }
> = ({ onNext, profileBasedRecommendation }) => {
  useSetTitle('Recomendação');
  const { values } = useWizard();
  const { selectedCourses } = useSelectedCourses();
  const initialValues =
    values[CreatePlanWizardSteps.RECOMMENDATION_REVIEW] || [];

  const isCreatingPlan = !!useIsMutating({
    mutationKey: [
      ...useCompleteProfileBasedRecommendationMutationKey,
      profileBasedRecommendation.id,
    ],
  });
  const { redoRecommendation } = useRedoRecommendation(
    profileBasedRecommendation.id,
    (recommendation) => {
      window.location.assign(`/pdi/planos/recomendacoes/${recommendation.id}`);
    }
  );
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const recommendedActivities =
    profileBasedRecommendation.recommended_activities || [];

  const defaultValues = {
    selectedActivities: parseDefaultValuesFromActivities(initialValues),
  };

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(formValidationSchema),
  });

  const handleNext = (data) => {
    if (onNext) {
      const parsedActivitites = parseActivitiesToServerPayload(
        data.selectedActivities,
        selectedCourses
      );
      onNext({
        selectedActivities: parsedActivitites,
      });
    }
  };

  const isActivitySelected = (activityId: string) =>
    activityId in selectedActivities;

  const selectedActivities: SelectedActivity =
    watch('selectedActivities') || {};

  const addActivity = (activityId: string) => {
    const newSelectedActivities = { ...selectedActivities, [activityId]: true };
    setValue('selectedActivities', newSelectedActivities, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const removeActivity = (activityId: string) => {
    const updatedSelectedActivitites = { ...selectedActivities };
    delete updatedSelectedActivitites[activityId];

    setValue('selectedActivities', updatedSelectedActivitites, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleToggleActivity = ({
    activityId,
    selected,
  }: {
    activityId: string;
    selected?: boolean;
  }) => {
    if (selected === true) {
      addActivity(activityId);
      return;
    }

    if (selected === false) {
      removeActivity(activityId);
      return;
    }

    return isActivitySelected(activityId)
      ? removeActivity(activityId)
      : addActivity(activityId);
  };

  const endOfSentenceRegex = /\. |\.$/;
  const sentences = (
    profileBasedRecommendation.recommended_plan_description || ''
  )
    .split(endOfSentenceRegex)
    .filter((sentence) => sentence.trim() !== '');

  return (
    <ContentBox mt={2} p={3}>
      <Box>
        <Typography variant="h6">
          Aqui está a nossa recomendação de plano pra você! 🎉
        </Typography>

        {sentences.map((sentence, index) => (
          <Typography variant="body3" mt={2} key={index}>
            {sentence}.
          </Typography>
        ))}
      </Box>

      <Box sx={{ py: 3 }}>
        <form onSubmit={handleSubmit(handleNext)}>
          <ToggleButtonGroup
            aria-label="plans recommendation review"
            color="primary"
            fullWidth
            size="large"
            value={Object.keys(selectedActivities)}
          >
            <Grid container spacing={2}>
              {recommendedActivities?.map((activity) => {
                return (
                  <Grid size={{ xs: 12 }} key={activity.id}>
                    <RecommendedActivityCard
                      activity={activity}
                      isChecked={isActivitySelected(activity.id)}
                      selectedCourse={selectedCourses[activity.id]}
                      onToggle={(selected) =>
                        handleToggleActivity({
                          activityId: activity.id,
                          selected,
                        })
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </ToggleButtonGroup>
          <WizardStepActions>
            <Button
              color="primary"
              onClick={() => setIsConfirmModalOpen(true)}
              variant="text"
            >
              Refazer a recomendação
            </Button>
            <Button
              color="primary"
              loading={isCreatingPlan}
              type="submit"
              variant="contained"
            >
              Criar Plano
            </Button>
          </WizardStepActions>
        </form>
      </Box>
      <IdpConfirmDialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={redoRecommendation}
        actionLabels={{
          confirm: 'Refazer a recomendação',
          cancel: 'Voltar',
        }}
        image={HelpCenterImage}
        title="Tem certeza de que deseja refazer a recomendação?"
        subtitle="As sugestões atuais serão descartadas, e você precisará responder ao questionário novamente."
        description="Lembre-se: é possível adicionar manualmente outras atividades após esta etapa de recomendação."
      />
    </ContentBox>
  );
};

export const RecommendationReview = (props) => (
  <SelectedCoursesProvider>
    <RecommendationReviewComponent {...props} />
  </SelectedCoursesProvider>
);
