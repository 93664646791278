import { useQuery } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { Plan, PlansByStatus, PlanStatus } from './interfaces';

const queryKey = ['usePlans'];

export function usePlans(params: {} = {}): usePlansType {
  const result = useQuery<Plan[]>({
    queryKey: [...queryKey, params],
    queryFn: async () => {
      return IdpClient.plans(params);
    },
    refetchOnMount: false,
  });

  const plansCount = result.data?.length || 0;

  const inProgressPlans = result.data?.find(
    (plan) => plan.status === PlanStatus.IN_PROGRESS
  );
  const draftPlans = result.data?.find(
    (plan) => plan.status === PlanStatus.DRAFT
  );
  const closedPlans = result.data?.filter(
    (plan) => plan.status === PlanStatus.CLOSED
  );

  const plans = {
    [PlanStatus.DRAFT]: draftPlans,
    [PlanStatus.IN_PROGRESS]: inProgressPlans,
    [PlanStatus.CLOSED]: closedPlans,
  };

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    plans,
    plansCount,
  };
}

type usePlansType = {
  error: boolean;
  isFetching: boolean;
  plans: PlansByStatus;
  plansCount: number;
};
