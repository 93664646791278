import { radiuses } from '../tokens';

const MuiDialog = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: radiuses.medium,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-end',
        height: 'calc(100vh - 65px)',
        margin: 0,
        width: '100%',
        borderRadius: 0,
      },
    }),
  },
};

export default MuiDialog;
