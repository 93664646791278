import React from 'react';
import { BaseDialog } from '@/react/components/dialog/base-dialog.component';
import { Box, Button, Typography } from '@mui/material';
import StartJourney from '@/react/assets/images/start-journey.svg';

export const CourseLimitExceededDialog = ({ onClose }) => (
  <BaseDialog
    onClose={onClose}
    open={true}
    actionsComponent={
      <Button onClick={onClose} variant="contained">
        Entendi
      </Button>
    }
  >
    <Box mb={4} component="img" src={StartJourney} />
    <Typography variant="body1" fontWeight="bold" mb={1}>
      Acreditamos que há um limite de cursos a serem realizados simultaneamente
      com qualidade para garantir o melhor aproveitamento da sua experiência. 
    </Typography>
    <Typography variant="body2">
      Você atingiu esse limite. Se já concluiu algum dos seus cursos e deseja
      iniciar uma nova matrícula, entre em contato com nosso atendimento.
    </Typography>
  </BaseDialog>
);
