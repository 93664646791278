import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/utils/money-parser';
import StepActions from '@/react/components/wizard/step-actions.component';
import ResourceApprovalCreatedDialog from '@/react/components/refund/refund-request/resource-approval-created-dialog.component';
import { useNotification } from '@/react/components/notification';
import { openToast } from '@/react/components/refund/utils/openToast';
import { useRefundableValueByResource } from '@/react/data/subsidy/useRefundableValueByResource';
import {
  possibleFormStepIds,
  formatCreateResourceApproval,
  formatUpdateResourceApproval,
  handleGoBack
} from '@/react/components/refund/refund-request/description-step/refund-summary/utils';


export const RefundableValue = ({
  isValid,
  value,
}: {
  isValid: boolean;
  value: number;
}) => {
  return (
    <Box
      component="span"
      sx={({ palette }) => ({
        color: !isValid ? palette.error.main : 'inherit',
      })}
    >
      {formatMoney(value)}
    </Box>
  );
};

const initialState = {
  isSubmitting: false,
  resourceApprovalCreated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CREATED':
      return { ...state, resourceApprovalCreated: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

const ResourceApprovalSummary = () => {
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const { actions: accordionActions } = useAccordion();
  const { subsidyPolicy, resourceApproval } = useRefundRequest();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { toast } = useNotification();
  const filledStep = possibleFormStepIds.find((stepId) => wizardValues[stepId]);
  const formValues = wizardValues[filledStep] ?? {};
  const formattedApproval = formatCreateResourceApproval({
    formValues,
    policyUuid: subsidyPolicy?.uuid,
    formType: filledStep,
  });

  const {
    data: refundableValue,
    isLoading,
    error,
  } = useRefundableValueByResource(formattedApproval, subsidyPolicy.uuid);

  const valid = refundableValue !== undefined && refundableValue > 0;

  const totalValueParsed = parseFloat(formattedApproval.resource.amount);

  const [resourceApprovalUUID, setResourceApprovalUUID] = React.useState(null);

  const handleCreateRefund = async () => {
    if (!valid) {
      return;
    }

    const requestPayload = formatCreateResourceApproval({
      formValues,
      policyUuid: subsidyPolicy.uuid,
      formType: filledStep,
    });

    dispatch({ type: 'SET_SUBMITTING', payload: true });
    dispatch({ type: 'SET_CREATED', payload: false });

    try {
      let resource = null;
      let messageToToast = '';
      let resourceApprovalUuid = null;
      if (
        resourceApproval &&
        (resourceApproval?.uuid !== '' || resourceApproval?.uuid !== null)
      ) {
        const requestPayloadUpdate = formatUpdateResourceApproval({
          formValues,
          courseId: resourceApproval?.resource?.course?.id,
          formType: filledStep,
        });

        resource = await SubsidyClient.updateResourceApproval({
          requestParams: {
            uuid: resourceApproval?.uuid,
            ...requestPayloadUpdate,
          },
        });
        messageToToast = 'Reembolso atualizado com sucesso';
        resourceApprovalUuid = resourceApproval?.uuid;
      } else {
        resource = await SubsidyClient.createResourceApproval(requestPayload);
        messageToToast = 'Reembolso solicitado com sucesso';
        resourceApprovalUuid = resource.resource_approval.uuid;
      }

      if (!!resource) {
        setResourceApprovalUUID(resourceApprovalUuid);
        dispatch({ type: 'SET_SUBMITTING', payload: false });
        dispatch({ type: 'SET_CREATED', payload: true });
      }

      openToast({
        toast,
        message: messageToToast,
        type: 'success',
      });

      accordionActions.close(RefundRequestSteps.RESOURCE_DESCRIPTION);
    } catch (error) {
      openToast({
        toast,
        message: error?.response?.data?.error,
        type: 'error',
      });

      dispatch({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  return (
    <>
      <Stack spacing={2} my={2}>
        <Typography component="div" variant="body3">
          Calculado de acordo com as regras de reembolso da sua política. O
          reembolso final depende também do seu saldo disponível.
        </Typography>
      </Stack>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          p: 2,
          mt: 3,
        }}
        data-testid={`${WizardStepIds.SUMMARY}`}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Custo total do item
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {formatMoney(totalValueParsed)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor máximo reembolsável
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {isLoading ? (
                  <Skeleton width={60} />
                ) : error ? (
                  <span style={{ color: 'red' }}>Erro: {error.message}</span>
                ) : (
                  <RefundableValue isValid={valid} value={refundableValue!} />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <StepActions>
        <Button
          color="primary"
          onClick={() => handleGoBack(wizardActions, wizardValues)}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          disabled={!valid}
          loading={state.isSubmitting}
          onClick={() => handleCreateRefund()}
          variant="contained"
        >
          {resourceApproval?.uuid ? 'Editar' : 'Enviar para aprovação'}
        </Button>
      </StepActions>
      <ResourceApprovalCreatedDialog
        actionsVisibility={{
          waitApproval: !subsidyPolicy.configuration.auto_approval_enabled,
        }}
        open={state.resourceApprovalCreated}
        resourceApprovalUUID={resourceApprovalUUID}
      />
    </>
  );
};

export default ResourceApprovalSummary;
