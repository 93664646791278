import { createActivitySchema } from '@/react/data/idp/schemas';
import { z } from 'zod';
import { CatalogCourse, CourseTypes } from '../../catalog/interfaces';
import { ActivityType } from '../profile/interfaces';

type CreateActivityInterface = z.infer<typeof createActivitySchema>;

enum ActivityStatuses {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

const ActivityStatusLabel = {
  [ActivityStatuses.PENDING]: 'A fazer',
  [ActivityStatuses.IN_PROGRESS]: 'Em andamento',
  [ActivityStatuses.COMPLETED]: 'Feito',
};

export type RecommendedActivity = {
  id: string;
  name: string;
  reason: string;
  category: string;
  checksum: string;
  duration: {
    type: string;
    unit: number;
  };
  confidence: string;
  created_at: string;
  updated_at: string;
  course_type: CourseTypes;
  discarded_at: string;
  activity_type: ActivityType;
  activity_based_course: {
    catalog_courses: CatalogCourse[];
  };
};

type ActivityResponseType = {
  description: string;
  id: string;
  plan_id: string;
  recommended_data?: string;
  recommended_activity?: RecommendedActivity;
  recommended_id?: string;
  resource_data?: CatalogCourse;
  resource_id?: number;
  resource_url?: string;
  status: ActivityStatuses;
  title: string;
};

type ActivitiesResponseType = {
  activities: ActivityResponseType[];
};

export {
  ActivityStatuses,
  ActivityStatusLabel,
  type ActivitiesResponseType,
  type ActivityResponseType,
  type CreateActivityInterface,
};
