import * as React from 'react';
import { Wizard } from '@/react/components/wizard';
import { WizardProvider, useWizard } from '@/react/components/wizard/state';
import { WizardSteps } from '@/react/components/wizard/interfaces';
import { State as WizardState } from '@/react/components/wizard/state/interfaces';
import { ConfirmFinishPlanDialog } from '@/react/components/idp/dialogs/confirm-finish-plan-dialog.component';
import { Plan } from '@/react/data/idp/plans';
import { FinishingPlanDialog } from '@/react/components/idp/dialogs/finishing-plan-dialog.component';
import { FinishedPlanDialog } from '@/react/components/idp/dialogs/finished-plan-dialog.component';

export interface FinishPlanProps {
  onClose: () => void;
  open: boolean;
  plan: Plan;
}

export enum StepsIds {
  CONFIRMING = 'confirming',
  FINALIZING = 'finalizing',
  FINALIZED = 'finalized',
}

const FinishPlan = ({ onClose, open, plan }: FinishPlanProps) => {
  const { actions } = useWizard();

  const steps: WizardSteps = {
    [StepsIds.CONFIRMING]: {
      component: () => (
        <ConfirmFinishPlanDialog
          onClose={onClose}
          open={open}
          plan={plan}
          onConfirm={() => actions.navigate(StepsIds.FINALIZING)}
        />
      ),
    },
    [StepsIds.FINALIZING]: {
      component: () => (
        <FinishingPlanDialog
          open={open}
          onNext={() => actions.navigate(StepsIds.FINALIZED)}
        />
      ),
    },
    [StepsIds.FINALIZED]: {
      component: () => (
        <FinishedPlanDialog
          onClose={() => window.location.assign('/pdi/planos')}
          open={open}
        />
      ),
    },
  };

  return <Wizard steps={steps} />;
};

export const CreateFinishPlanWithContext = (
  finishPlanProps: FinishPlanProps
) => {
  const initialState: WizardState = {
    currentStepId: StepsIds.CONFIRMING,
    values: {},
  };

  return (
    <WizardProvider initialState={initialState}>
      <FinishPlan {...finishPlanProps} />
    </WizardProvider>
  );
};
