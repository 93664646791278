import * as React from 'react';

import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';

import { LearningTypeList } from '@/react/components';
import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';
import KnowledgeAreaList from './knowledge-area-list.component';
import { CursosSection } from './section.component';
import { fillCourseTypeData } from './utils/default-course-list-data';

const PlanGallery = () => {
  const { data: fetchedData = {} } = useQuery({
    queryKey: ['bootstrapData'],
    queryFn: () =>
      restClient.get('/users/bootstrap-data').then((res) => res.data.data),
  });

  const taxonomy = fetchedData.taxonomy || {};
  const courseTypes = taxonomy.course_types || [];
  const knowledgeAreas =
    taxonomy.knowledge_areas?.filter((knowledgeArea) =>
      knowledgeArea.features?.some(
        (feature) => feature.name === 'full_included'
      )
    ) || [];

  const courseTypesWithLink = courseTypes.map((courseType) => {
    const { description, image } = fillCourseTypeData(
      courseType.type,
      courseType.description,
      courseType.image
    );

    return {
      ...courseType,
      link: `cursos/novo_catalogo?course_types=${courseType.type}&feature_full=true`,
      description,
      image,
    };
  });

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}&feature_full=true`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <CursosSection
          title="Confira abaixo o que está incluso no seu plano"
          subtitle="No seu plano você tem acesso a diversas bolsas e cursos na
              plataforma Galena sem custo!"
        >
          <LearningTypeList data={courseTypesWithLink} />
        </CursosSection>
        <CursosSection>
          <KnowledgeAreaList data={knowledgeAreasWithLink} />
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};

export default PlanGallery;
