import React from 'react';
import * as Sentry from '@sentry/browser';

import { PlanInDraftDialog } from '@/react/components/idp/dialogs';
import {
  IdpOnboardingDialog,
  localStorageKey as idpOnboardingDialogLocalStorageKey,
} from '@/react/components/idp/dialogs/idp-onboarding-dialog.component';

import { IdpClient } from '@/react/data/idp/idp-client';
import { Plan } from '@/react/data/idp/plans';

export interface NewPlanDialogProps {
  onClose: () => void;
  open: boolean;
  draftPlan?: Plan;
}

export const NewPlanDialogs = ({
  onClose,
  open,
  draftPlan,
}: NewPlanDialogProps) => {
  const [openHowIdpWorksDialog, setOpenHowIdpWorksDialog] =
    React.useState(false);

  const handleStartNew = () => {
    if (bypassOnboarding) {
      handleStartOnboarding();
    } else {
      setOpenHowIdpWorksDialog(true);
    }
  };

  const bypassOnboarding = !!localStorage.getItem(
    idpOnboardingDialogLocalStorageKey
  );

  const redirectToNewPlan = () => {
    window.location.assign('/pdi/planos/recomendacoes');
  };

  const handleStartOnboarding = async () => {
    if (!draftPlan) {
      return redirectToNewPlan();
    }

    try {
      await IdpClient.deletePlan(draftPlan.id);

      redirectToNewPlan();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  React.useEffect(() => {
    if (!draftPlan && bypassOnboarding && open) {
      redirectToNewPlan();
    }

    if (!draftPlan && !openHowIdpWorksDialog && !bypassOnboarding && open) {
      setOpenHowIdpWorksDialog(true);
    }
  }, [open]);

  return (
    <>
      <PlanInDraftDialog
        callback={handleStartNew}
        onClose={onClose}
        open={open && !!draftPlan}
        plan={draftPlan}
      />
      <IdpOnboardingDialog
        callback={handleStartOnboarding}
        onClose={onClose}
        open={open && !bypassOnboarding && openHowIdpWorksDialog}
      />
    </>
  );
};
