const MuiToggleButton = {
  styleOverrides: {
    root: ({ theme }) =>
      theme.unstable_sx({
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      }),
  },
};

export default MuiToggleButton;
