import * as React from 'react';
import { Button, Stack } from '@mui/material';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';

import { useScreenSizes } from '@/react/utils/useScreenSizes';

export type BaseDialogActionsProps = {
  onClose: () => void;
  reverseMobileDirection?: boolean;
} & DialogActionsProps;

const DefaultActions = ({ onClose }) => {
  return (
    <Button color="primary" onClick={onClose} variant="outlined">
      Fechar
    </Button>
  );
};

export const BaseDialogActions = ({
  children,
  onClose,
  reverseMobileDirection,
  ...props
}: BaseDialogActionsProps) => {
  const { isSmallScreen } = useScreenSizes();

  const mobileActionsDirection = reverseMobileDirection
    ? 'column-reverse'
    : 'column';
  const actionsDirection = isSmallScreen ? mobileActionsDirection : 'row';
  const actions = children || <DefaultActions onClose={onClose} />;

  return (
    <DialogActions {...props}>
      <Stack
        direction={actionsDirection}
        justifyContent={isSmallScreen ? 'center' : 'flex-end'}
        sx={{
          width: '100%',

          '& > *': {
            width: isSmallScreen ? '100%' : 'auto',
          },
        }}
        spacing={1}
      >
        {actions}
      </Stack>
    </DialogActions>
  );
};
