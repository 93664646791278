import React from 'react';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PageContainer } from '@/react/components';
import {
  SectionHeader,
} from '@/react/components/refund/refund-management/shared';
import { useRefundRequest } from '@/react/data/subsidy/useRefundRequest';
import { RefundRequestShow } from './refund-request-show.component';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import ConfirmRefundRequestApproveDialog from './confirm-refund-request-approve-dialog.component';
import { useDecidedRefundRequest } from '@/react/data/subsidy/useDecideRefundRequest';
import { useNotification } from '@/react/components/notification';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import { RefundRequestHistoryDetails } from './refund-request-history-details';

const handleBackButton = (resource_approval_uuid) => {
  window.location.assign(
    `/empresa/gestao-de-reembolsos/${resource_approval_uuid}/detalhes`
  );
};

export const RefundRequestDetails = ({
  resourceApprovalUuid,
  refundRequestUuid,
}: {
  resourceApprovalUuid: string;
  refundRequestUuid: string;
}) => {
  const { refundRequest, isFetching } = useRefundRequest(refundRequestUuid);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isSmallScreen } = useScreenSizes();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { toast } = useNotification();
  const { decideRefundRequest } = useDecidedRefundRequest();

  const canOverrideRefundDecision = () => {
    const enable_override_refund_decision = refundRequest?.policies.some(
      (policy) => policy.enable_override_refund_decision
    );
    const allowedStatus = [
      RefundRequestStatus.pending_approval,
      RefundRequestStatus.rejected,
    ];

    return (
      enable_override_refund_decision &&
      allowedStatus.includes(refundRequest?.status)
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDecision = (comment: string) => {
    handleDecideRefundRequest(comment);
    handleCloseDialog();
    handleCloseMenu();
  };

  const handleDecideRefundRequest = async (comment?: string) => {
    try {
      await decideRefundRequest({
        refund_request_uuid: refundRequestUuid,
        decision_comment: comment,
      });

      toast({
        content:
          'Reembolso aprovado!\nO usuário receberá um e-mail informando sobre a aprovação.',
        type: 'success',
      });
    } catch (error) {
      toast({
        content:
          'Erro ao processar a solicitação. Tentar novamente em instantes.',
        type: 'error',
      });
    }
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionHeader
          title="Detalhes do reembolso"
          handleBackNavigation={() => handleBackButton(resourceApprovalUuid)}
        />
        {!isFetching && canOverrideRefundDecision() && (
          <>
            {isSmallScreen ? (
              <IconButton
                onClick={handleClick}
                size="large"
                data-testid="options-button"
              >
                <MoreVertIcon />
              </IconButton>
            ) : (
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Opções
              </Button>
            )}
          </>
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenDialog}>Aprovar reembolso</MenuItem>
      </Menu>
      <ConfirmRefundRequestApproveDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDecision}
      />
      <RefundRequestShow
        refundRequest={refundRequest}
        isFetching={isFetching}
      />
      <RefundRequestHistoryDetails
        refund_request={refundRequest}
        isFetching={isFetching}
      />
    </PageContainer>
  );
};
