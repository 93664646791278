import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import EnrollmentSuccess from '@/react/assets/images/enrollment-success.svg';
import { BaseDialog } from '@/react/components/dialog/base-dialog.component';

export interface FinishedPlanDialogProps {
  onClose: () => void;
  open: boolean;
}

export const FinishedPlanDialog = ({
  onClose,
  open,
}: FinishedPlanDialogProps) => {
  return (
    <BaseDialog
      transitionDuration={0}
      open={open}
      onClose={onClose}
      actionsComponent={
        <Button onClick={onClose} variant="text">
          Fechar
        </Button>
      }
    >
      <Box
        component="img"
        src={EnrollmentSuccess}
        sx={{ height: 160, width: 160, margin: '0 auto 8px' }}
      />
      <Typography variant="h6" gutterBottom>
        Plano concluído com sucesso!
      </Typography>
      <Typography variant="body2" gutterBottom>
        Parabéns por essa conquista! Agora é hora de dar início a novos desafios
        e continuar evoluindo o seu aprendizado.
      </Typography>
    </BaseDialog>
  );
};
