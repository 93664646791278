import { useQuery } from '@tanstack/react-query';
import {
  ResourceApprovalsToReviewResponse,
  RequestFilteredRefundsList,
} from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useResourceApprovalsToReviewList(
  requestParams: RequestFilteredRefundsList
) {
  const result = useQuery<ResourceApprovalsToReviewResponse>({
    queryKey: ['useResourceApprovalsToReviewList', requestParams],
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      return await SubsidyClient.getResourceApprovalsToReviewList({
        requestParams,
        signal,
      });
    },
  });

  const resourceApprovals = result.data?.resource_approvals || [];
  const totalPages = result.data?.metadata.total_pages || 0;
  const totalItems = result.data?.metadata.total_items || 0;

  return {
    resourceApprovals,
    totalPages,
    totalItems,
    isFetching: result.isFetching,
    error: !!result.error,
  };
}
