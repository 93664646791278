import { useQuery } from '@tanstack/react-query';
import { CourseTypes, Institution } from '../catalog/interfaces';
import { CatalogClient } from '../catalog/catalog-client';

type useInstitutionsLogoType = {
  error: boolean;
  isFetching: boolean;
  institutions: Institution[];
};

export const useInstitutionsLogoKey = ['useInstitutionsLogo'];

export function useInstitutionsLogo(
  courseType: CourseTypes
): useInstitutionsLogoType {
  const { data, isFetching, error, refetch } = useQuery<Institution[]>({
    queryKey: [...useInstitutionsLogoKey, courseType],
    queryFn: async () => {
      return CatalogClient.getInstitutionByCourseType({ courseType });
    },
    refetchOnMount: false,
  });

  const institutions = data;

  return {
    error: !!error,
    isFetching,
    institutions,
  };
}
