import * as React from 'react';
import { styled } from '@mui/material/styles';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as Sentry from '@sentry/browser';

import {
  Activity,
  ActivityStatuses,
  ActivityStatusLabel,
  PlanStatus,
} from '@/react/data/idp/interfaces';
import { useUpdatePlanActivityStatus } from '@/react/data/idp';

type StyledSelectProps = {
  activityStatus: ActivityStatuses;
};

type ActivityStatusSelectProps = {
  activity: Activity;
  planId: string;
  planStatus: PlanStatus;
};

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'activityStatus',
})<StyledSelectProps>(({ theme, activityStatus }) => ({
  backgroundColor: Activity.getActivityStatusColor(
    activityStatus,
    theme.palette
  ).backgroundColor,
  color: Activity.getActivityStatusColor(activityStatus, theme.palette).color,

  '& .MuiSelect-select': {
    padding: theme.spacing(0, 1),
  },

  '& .MuiSelect-icon': {
    color: 'inherit',
  },
}));

const statusOptions = Object.entries(ActivityStatusLabel).map(
  ([value, label]) => ({
    value,
    label,
  })
);
export const ActivityStatusSelect = ({
  activity,
  planId,
  planStatus,
}: ActivityStatusSelectProps) => {
  const { updatePlanActivityStatus, isPending: isUpdatingStatus } =
    useUpdatePlanActivityStatus(planId);
  const [activityStatus, setActivityStatus] = React.useState<ActivityStatuses>(
    activity.status
  );

  React.useEffect(() => {
    setActivityStatus(activity.status);
  }, [activity.status]);

  const handleChangeStatus = async (event: SelectChangeEvent) => {
    try {
      await updatePlanActivityStatus({
        activityId: activity.id,
        status: event.target.value as ActivityStatuses,
      });
      setActivityStatus(event.target.value as ActivityStatuses);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <StyledSelect
      activityStatus={activityStatus}
      disabled={isUpdatingStatus || planStatus === PlanStatus.CLOSED}
      label="Status"
      onChange={handleChangeStatus}
      renderValue={(selected) =>
        ActivityStatusLabel[selected as ActivityStatuses]
      }
      size="small"
      value={activityStatus}
      variant="standard"
    >
      {statusOptions.map(({ label, value }) => (
        <MenuItem 
          key={value} 
          value={value}
          onClick={(event) => event.stopPropagation()}
        >
          {label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
