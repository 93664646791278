import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubsidyClient } from './subsidy-client';
import { DecideRefundRequest } from '@/react/data/subsidy/interfaces';
import { useRefundRequestQueryKey } from '@/react/data/subsidy/useRefundRequest';

export const useDecidedRefundRequest = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: decideRefundRequest, isPending } = useMutation(
    {
      mutationFn: (input: DecideRefundRequest) => {
        return SubsidyClient.decideRefundRequest({request: input});
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: useRefundRequestQueryKey(variables.refund_request_uuid),
        });
      },
    }
  );
  
  return { isPending, decideRefundRequest };
};
