import { CourseTypes } from '@/react/data/catalog/interfaces';
import { useInstitutionsLogo } from '@/react/data/idp/useInstitutionsLogo';
import {
  Avatar,
  AvatarGroup,
  AvatarProps,
  Button,
  Skeleton,
} from '@mui/material';
import React from 'react';

const InstitutionAvatar = ({
  bgcolor,
  ...props
}: AvatarProps & { bgcolor?: string }) => (
  <Avatar
    {...props}
    sx={(theme) => ({
      bgcolor,
      borderRadius: 1,
      borderColor: theme.palette.common.white,
      height: 28,
      width: 28,
    })}
    slotProps={{ img: { sx: { height: 'auto' } } }}
  />
);

export const RecommendedCoursesButton = ({
  courseType,
  onClick,
  fullWidth,
  disabled,
}: {
  courseType: CourseTypes;
  onClick: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
}) => {
  const { institutions } = useInstitutionsLogo(courseType);

  const onClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Button
      component="span"
      sx={{ width: fullWidth ? '100%' : 'fit-content' }}
      variant="outlined"
      onClick={onClickButton}
      disabled={disabled}
      size="large"
    >
      {institutions && (
        <AvatarGroup spacing={25} max={3} variant="square" sx={{ mr: 1 }}>
          {institutions?.map((institution) => (
            <InstitutionAvatar
              id={`logo-${institution?.id}`}
              key={institution?.id}
              alt={`${institution?.name} logo`}
              src={institution?.logo_url}
              bgcolor={institution?.logo_background_color}
            />
          ))}
        </AvatarGroup>
      )}
      {!institutions && (
        <Skeleton variant="rounded" sx={{ width: 50.29, height: 34, mr: 1 }} />
      )}
      Ver cursos recomendados
    </Button>
  );
};
