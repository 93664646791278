import * as React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { SxProps, Theme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactNode } from 'react';

interface CustomTooltipProps {
  title: string;
  open: boolean;
  tooltipKey: string;
  onClick: (key: string) => void;
  icon?: ReactNode;
  tooltipSx?: SxProps<Theme> | ((theme: Theme) => SxProps<Theme>);
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  title,
  open,
  tooltipKey,
  onClick,
  icon,
  tooltipSx,
}) => {
  return (
    <Tooltip
      open={open}
      title={title}
      placement="bottom-start"
      onClick={() => onClick(tooltipKey)}
      slotProps={{
        tooltip: {
          sx: {
            ...tooltipSx,
          },
        },
        popper: {
          disablePortal: true,
          sx: {
            transform: 'none !important',
            marginTop: '20px !important',
          },
        },
      }}
      slots={{
        transition: Zoom,
      }}
    >
      <span style={{ alignItems: 'center' }}>
        {icon || <InfoOutlinedIcon />}
      </span>
    </Tooltip>
  );
};
