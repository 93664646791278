import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  DialogContent,
  Divider,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useIsMutating } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { BaseDialogActions } from '@/react/components/dialog/base-dialog-actions.component';
import { BaseDialogTitle } from '@/react/components/dialog/base-dialog-title.component';
import { CourseSelectionDialog } from '@/react/components/idp/course-selection/course-selection-dialog';
import { ConfirmDeleteActivityDialog } from '@/react/components/idp/dialogs';
import {
  ActionsMenu,
  ActivityStatusSelect,
  AiChip,
} from '@/react/components/idp/shared';
import { usePlanActivity, useUpdatePlanActivity } from '@/react/data/idp';
import {
  Activity,
  ActivityResponseType,
  PlanStatus,
} from '@/react/data/idp/interfaces';
import { createActivitySchema } from '@/react/data/idp/schemas';
import { mutationKey as useUpdatePlanActivityStatusMutationKey } from '@/react/data/idp/useUpdatePlanActivityStatus';
import { CatalogCourse } from '@/react/data/catalog/interfaces';
import { RecommendedCoursesDialog } from '../dialogs/recommended-courses-dialog';
import { RecommendedCoursesButton } from '../shared/recommended-courses-button';
import { SelectedCourseCard } from '../shared/selected-course-card';

export interface CreateActivityDialogProps
  extends Omit<DialogProps, 'onClose'> {
  activityId: string;
  onClose: () => void;
  planId: string;
  planStatus: PlanStatus;
}

const Loading = () => (
  <Grid container spacing={2}>
    <Grid size={12}>
      <Skeleton variant="rounded" height={80} />
    </Grid>
    <Grid size={12}>
      <Skeleton variant="rounded" height={140} />
    </Grid>
  </Grid>
);

const DialogHeader = ({
  activity,
  loading,
  planId,
  planStatus,
}: {
  activity: Activity;
  loading: boolean;
  planId: string;
  planStatus: PlanStatus;
}) => {
  const [openConfirmDialog, setOpenConfirDialog] = React.useState(false);
  const actions = [
    {
      label: 'Deletar Atividade',
      onClick: () => setOpenConfirDialog(true),
      Icon: <DeleteIcon />,
    },
  ];

  if (loading) {
    return <Skeleton variant="text" />;
  }

  const shouldShowActivityStatus = planStatus === PlanStatus.IN_PROGRESS;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {shouldShowActivityStatus && (
            <ActivityStatusSelect
              activity={activity}
              planId={planId}
              planStatus={planStatus}
            />
          )}
        </Typography>
        <ActionsMenu collapsed actions={actions} />
      </Box>
      <ConfirmDeleteActivityDialog
        activity={activity}
        onClose={() => setOpenConfirDialog(false)}
        open={openConfirmDialog}
      />
    </>
  );
};

export const ShowActivityDialog = ({
  activityId,
  onClose,
  open,
  planId,
  planStatus,
  ...dialogProps
}: CreateActivityDialogProps) => {
  const [openAddCourseDialog, setOpenAddCourseDialog] = React.useState(false);
  const [openRecommendedCoursesDialog, setOpenRecommendedCoursesDialog] =
    React.useState(false);

  const isMutatingPlanActivityStatus = useIsMutating({
    mutationKey: useUpdatePlanActivityStatusMutationKey,
  });
  const { activity, isFetching: fetchingActivity } = usePlanActivity({
    activityId,
    planId,
  });
  const [selectedCourse, setSelectedCourse] =
    React.useState<CatalogCourse | null>(activity?.resource_data || null);

  const { updatePlanActivity, isPending: isUpdatingPlanActivity } =
    useUpdatePlanActivity(activity);
  const { control, formState, handleSubmit, reset } = useForm({
    resolver: zodResolver(createActivitySchema),
    defaultValues: {
      description: '',
      title: '',
    },
  });

  React.useEffect(() => {
    reset({
      description: activity?.description || '',
      title: activity?.title || '',
    });
    setSelectedCourse(activity?.resource_data || null);
  }, [activity]);

  const handleSave = async (data: ActivityResponseType) => {
    try {
      const updatedActivity = {
        ...data,
        resource_data: selectedCourse || null,
        resource_id: selectedCourse?.id || null,
      };

      await updatePlanActivity(updatedActivity);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setSelectedCourse(null);
    onClose();
  };

  const disableForm = fetchingActivity || !!isMutatingPlanActivityStatus;

  const renderContent = () => {
    if (fetchingActivity) {
      return <Loading />;
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel htmlFor="title">Nome da atividade</FormLabel>
                    <TextField
                      {...field}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      id="title"
                      placeholder="Dê um nome para a sua atividade"
                      variant="outlined"
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">
                      Descrição
                      <Typography component="span" variant="caption" ml={1}>
                        Opcional
                      </Typography>
                    </FormLabel>
                    <TextField
                      {...field}
                      id="description"
                      multiline
                      placeholder="Adicionar descrição"
                      rows={4}
                      variant="outlined"
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>
          {activity?.recommended_activity && (
            <Grid size={12}>
              <AiChip />
            </Grid>
          )}
          <Grid size={12}>
            <Divider />
          </Grid>

          {selectedCourse ? (
            <>
              <SelectedCourseCard
                course={selectedCourse}
                removable
                onRemove={() => setSelectedCourse(null)}
              />
              <Typography variant="caption">
                Comece a estudar agora mesmo e faça a sua matrícula.{' '}
                <Link href={`cursos/${selectedCourse.id}`} target="_blank">
                  Ir para a página do curso
                </Link>
              </Typography>
            </>
          ) : (
            <Grid size={12}>
              <Typography variant="h6">Incluir curso</Typography>
              <Typography variant="body3" mb={2}>
                Adicione um curso para sua atividade.
              </Typography>
              {activity?.recommended_activity?.activity_type === 'course' && (
                <RecommendedCoursesButton
                  fullWidth
                  onClick={() => setOpenRecommendedCoursesDialog(true)}
                  courseType={activity?.recommended_activity?.course_type}
                />
              )}
              <Button
                fullWidth
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={() => setOpenAddCourseDialog(true)}
                sx={{ mt: 2 }}
                size="large"
              >
                Buscar na plataforma
              </Button>
            </Grid>
          )}
        </Grid>
        {openRecommendedCoursesDialog && (
          <RecommendedCoursesDialog
            activityId={activity.recommended_activity?.id}
            open={openRecommendedCoursesDialog}
            onClose={() => setOpenRecommendedCoursesDialog(false)}
            onSave={(course) => {
              setSelectedCourse(course);
            }}
          />
        )}
        {openAddCourseDialog && (
          <CourseSelectionDialog
            open={openAddCourseDialog}
            onClose={() => setOpenAddCourseDialog(false)}
            onSelect={(course) => {
              setSelectedCourse(course);
            }}
          />
        )}
      </>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      open={open}
      {...dialogProps}
    >
      <BaseDialogTitle onClose={handleClose}>
        <DialogHeader
          activity={activity}
          loading={fetchingActivity}
          planId={planId}
          planStatus={planStatus}
        />
      </BaseDialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <BaseDialogActions onClose={() => {}}>
        <Button onClick={handleClose} variant="text">
          Cancelar
        </Button>
        <Button
          disabled={!formState.isValid}
          loading={disableForm || isUpdatingPlanActivity}
          onClick={handleSubmit(handleSave)}
          variant="contained"
        >
          Salvar
        </Button>
      </BaseDialogActions>
    </Dialog>
  );
};
