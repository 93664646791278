import React from 'react';
import { Button, Typography } from '@mui/material';

import { BaseDialog } from '@/react/components/dialog';
import { Plan } from '@/react/data/idp/plans';

export interface PlanInDraftDialogProps {
  callback: () => void;
  onClose: () => void;
  open: boolean;
  plan: Plan;
}

export const PlanInDraftDialog = ({
  callback,
  onClose,
  open,
  plan,
}: PlanInDraftDialogProps) => {
  const handleResumeDraft = () =>
    window.location.assign(`/pdi/planos/${plan.id}`);

  const actions = React.useMemo(() => {
    return (
      <>
        <Button onClick={handleResumeDraft} variant="contained">
          Retomar rascunho
        </Button>
        <Button onClick={callback} variant="contained">
          Começar novo
        </Button>
      </>
    );
  }, [callback, handleResumeDraft]);

  return (
    <BaseDialog
      actionsComponent={actions}
      data-testid="plan-in-draft-dialog"
      onClose={onClose}
      open={open}
      title="Você já possui um rascunho em aberto"
    >
      <Typography variant="body3">
        Deseja retomar o rascunho atual ou iniciar um novo? Se optar por começar
        um novo, o rascunho em aberto será excluído.
      </Typography>
    </BaseDialog>
  );
};
