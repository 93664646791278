import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SwapVert from '@mui/icons-material/SwapVert';
import { Button, Grid2 as Grid, Skeleton, Typography } from '@mui/material';

import { EmptyActivitiesList } from '@/react/components/idp/activities-list/empty-activities-list.component';
import { CreateActivityDialog } from '@/react/components/idp/create-activity';
import { ReorderActivitiesDialog } from '@/react/components/idp/reorder-activities';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { Activity } from '@/react/data/idp/activities';
import { PlanStatus } from '@/react/data/idp/plans';
import Plan from '@/react/data/idp/plans/plan.model';
import { SelectedCoursesProvider } from '@/react/components/idp/context/selected-courses';

enum ComponentStates {
  EMPTY = 'EMPTY',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NULL = 'NULL',
}

interface ActivitiesListProps {
  activities: Activity[];
  loading?: boolean;
  plan: Plan;
}

interface ActivitiesActionsProps {
  planId: string;
  planStatus: PlanStatus;
  activities: Activity[];
  isReorderable?: boolean;
}

const ActivitiesActions = ({
  planId,
  activities,
  isReorderable,
}: ActivitiesActionsProps) => {
  const [openCreateActivityDialog, setOpenCreateActivityDialog] =
    React.useState(false);
  const [openReorderActivitiesDialog, setOpenReorderActivitiesDialog] =
    React.useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateActivityDialog(true)}
          variant="contained"
        >
          Adicionar atividade
        </Button>
        {isReorderable && (
          <Button
            onClick={() => setOpenReorderActivitiesDialog(true)}
            startIcon={<SwapVert />}
            variant="outlined"
          >
            Reordenar Atividades
          </Button>
        )}
        <Button
          onClick={window.print}
          startIcon={<SaveAltIcon />}
          variant="outlined"
        >
          Salvar PDF
        </Button>
      </Grid>
      {openCreateActivityDialog && (
        <CreateActivityDialog
          open={openCreateActivityDialog}
          onClose={() => setOpenCreateActivityDialog(false)}
          planId={planId}
        />
      )}
      <ReorderActivitiesDialog
        open={openReorderActivitiesDialog}
        onClose={() => setOpenReorderActivitiesDialog(false)}
        planId={planId}
        activities={activities}
      />
    </>
  );
};

export const ActivitiesCardList = ({
  activities,
  loading = false,
  plan,
}: ActivitiesListProps) => {
  const [componentState, setComponentState] = useState<ComponentStates>(
    ComponentStates.NULL
  );

  const renderState = {
    [ComponentStates.LOADING]: (
      <Grid container data-testid="loading-activities">
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid size={{ xs: 12, md: 4 }} key={index}>
            <Skeleton height={130} key={index} variant="rectangular" />
          </Grid>
        ))}
      </Grid>
    ),
    [ComponentStates.LOADED]: (
      <>
        {activities.map((activity) => (
          <Grid size={{ xs: 12, md: 4 }} key={activity.id}>
            <ActivityCard
              activity={activity}
              disableClick={plan.status === PlanStatus.CLOSED}
              key={activity.id}
              planId={plan.id}
              planStatus={plan.status}
            />
          </Grid>
        ))}
      </>
    ),
    [ComponentStates.EMPTY]: (
      <Grid size={{ xs: 12, md: 4 }}>
        <EmptyActivitiesList />
      </Grid>
    ),
  };

  useEffect(() => {
    if (loading) {
      return setComponentState(ComponentStates.LOADING);
    }

    if (activities?.length > 0) {
      setComponentState(ComponentStates.LOADED);
    }
    if (activities?.length === 0) {
      setComponentState(
        plan.status !== PlanStatus.CLOSED
          ? ComponentStates.EMPTY
          : ComponentStates.NULL
      );
    }
  }, [loading, activities]);

  return componentState === ComponentStates.NULL ? null : (
    <Grid container spacing={3}>
      <Grid
        size={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">
          Atividades {activities.length >= 1 ? `(${activities?.length})` : ''}
        </Typography>
      </Grid>
      {plan.status !== PlanStatus.CLOSED && (
        <Grid className="media-print-remove" size={12}>
          <ActivitiesActions
            planId={plan.id}
            planStatus={plan.status}
            isReorderable={activities.length > 1}
            activities={activities}
          />
        </Grid>
      )}
      {renderState[componentState]}
    </Grid>
  );
};
