import { CatalogCourse } from '@/react/data/catalog/interfaces';
import React, { createContext, useContext, useState } from 'react';

interface SelectedCoursesContextProps {
  addCourse: (activityId: string, course: CatalogCourse) => void;
  removeCourse: (activityId: string) => void;
  selectedCourses: { [key: string]: CatalogCourse | null };
}

const SelectedCoursesContext = createContext<
  SelectedCoursesContextProps | undefined
>(undefined);

export const useSelectedCourses = () => {
  const context = useContext(SelectedCoursesContext);
  if (!context) {
    throw new Error(
      'useSelectedCourses must be used within SelectedCoursesProvider'
    );
  }
  return context;
};

interface SelectedCoursesProviderProps {
  children: React.ReactNode;
  initialState?: { [key: string]: CatalogCourse };
}

export const SelectedCoursesProvider: React.FC<
  SelectedCoursesProviderProps
> = ({ children, initialState = {} }) => {
  const [selectedCourses, setSelectedCourses] = useState<{
    [key: string]: CatalogCourse;
  }>(initialState);

  const addCourse = (activityId, course: CatalogCourse | null) => {
    setSelectedCourses((prev) => ({
      ...prev,
      [activityId]: course,
    }));
  };

  const removeCourse = (activityId) => {
    setSelectedCourses((prev) => {
      const updatedCourses = { ...prev };
      delete updatedCourses[activityId];
      return updatedCourses;
    });
  };

  return (
    <SelectedCoursesContext.Provider
      value={{
        addCourse,
        removeCourse,
        selectedCourses,
      }}
    >
      {children}
    </SelectedCoursesContext.Provider>
  );
};
