import {
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';

import { useNotification } from '@/react/components/notification';
import { useGetPlanKey } from '@/react/data/idp/usePlan';
import { queryKey as usePlanActivityQueryKey } from '@/react/data/idp/usePlanActivity';
import { IdpClient } from './idp-client';
import { Activity, ActivityResponseType, Plan } from './interfaces';

export const mutationKey = ['updatePlanActivity'];

const defaultMessages = {
  error: 'Erro ao alterar atividade.',
  success: 'Atividade alterada com sucesso.',
};

export function useUpdatePlanActivity(
  activity: Activity
): useUpdatePlanActivityType {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  const { mutateAsync: updatePlanActivity, isPending } = useMutation({
    meta: { activityId: activity?.id },
    mutationKey: [...mutationKey],
    mutationFn: (payload: ActivityResponseType) => {
      return IdpClient.updatePlanActivity(activity, payload);
    },
    onSuccess: (payload: Activity) => {
      const planData = queryClient.getQueryData<Plan>([
        ...useGetPlanKey,
        activity.planId,
      ]);

      if (planData) {
        queryClient.setQueryData<Plan>([...useGetPlanKey, activity.planId], {
          ...planData,
          activities: planData.activities.map((currentActivity) =>
            currentActivity.id === activity.id
              ? { ...currentActivity, ...payload }
              : currentActivity
          ),
        });
        queryClient.invalidateQueries({
          queryKey: [...usePlanActivityQueryKey, activity.planId, activity.id],
        });
      }

      toast({
        content: defaultMessages.success,
        type: 'success',
      });
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);

      toast({
        content: defaultMessages.error,
        type: 'error',
      });
    },
  });

  return {
    updatePlanActivity,
    isPending,
  };
}

type useUpdatePlanActivityType = {
  updatePlanActivity: UseMutateFunction<Activity, unknown, any, unknown>;
  isPending: boolean;
};
