import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { getSvg } from '@/react/utils/assets';
import { PageContainer } from '@/react/components';

export const ProductNotFoundPage = () => (
  <PageContainer>
    <Stack py={10} textAlign="center" alignItems="center" spacing={2}>
      <Box
        component="img"
        src={getSvg('empty_state_catalog.svg')}
        sx={{ width: 160 }}
      />
      <Typography variant="subtitle2">Curso não encontrado.</Typography>
      <Typography variant="body2">
        Este curso pode ter sido removido ou atualizado. Que tal explorar outros
        cursos no catálogo?
      </Typography>
      <Button href="/cursos" variant="contained" color="primary">
        Ir para o catálogo
      </Button>
    </Stack>
  </PageContainer>
);
