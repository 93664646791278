import * as React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Logo } from '@/react/components/appbar/logo.component';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';

export const PrintHeader: React.FC = () => {
  const { isFetching, info } = useBootstrapData();

  if (isFetching) return null;

  return (
    <Container
      className="media-screen-remove"
      maxWidth={false}
      sx={{
        width: '100%',
        zIndex: 9999,
      }}
    >
      <Stack
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack gap={3}>
          <Typography
            color="primary.200"
            variant="h6"
            sx={{ textTransform: 'uppercase' }}
          >
            Plano de desenvolvimento
          </Typography>
          <Typography variant="h5">{info.nome}</Typography>
        </Stack>
        <div>
          <Logo width={64} />
        </div>
      </Stack>
    </Container>
  );
};
