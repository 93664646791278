import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';

import { PageContainer } from '@/react/components';
import { ShowActivityDialog } from '@/react/components/idp/show-activity';
import { ConfirmStartPlanDialog } from '@/react/components/idp/dialogs/confirm-start-plan-dialog.component';
import { StartPlanDisabledDialog } from '@/react/components/idp/dialogs/start-plan-disabled-dialog.component';
import {
  PlanStatus,
  Activity,
  ActivityStatuses,
} from '@/react/data/idp/interfaces';
import { zodResolver } from '@hookform/resolvers/zod';
import { updatePlanSchema } from '@/react/data/idp/schemas';
import {
  EstimatedEndsAt,
  StartedAt,
  EndedAt,
} from '@/react/components/idp/show-plan/date-display.component';
import { SectionHeader, PageSkeleton } from '@/react/components/idp/shared';
import { Action } from '@/react/components/idp/show-plan/action';
import { ConfirmRestartPlanDialog } from '@/react/components/idp/dialogs/confirm-restart-plan-dialog.component';
import { InlineEditInput } from '@/react/components/form/inline-edit-input.component';
import { ActivitiesCardList } from '@/react/components/idp/activities-list';
import { useGetPlan, useUpdatePlan } from '@/react/data/idp';
import { ConfirmDeletePlanDialog } from '@/react/components/idp/dialogs/confirm-delete-plan-dialog.component';
import { ActivityProgress } from '@/react/components/idp/activity-progress.component';
import { useSetTitle } from '@/react/hooks/useSetTitle';
import { ContentBox } from '../shared/content-box.component';
import { getMonthLabel } from '@/react/utils/time';
import { FinishPlan } from '@/react/components/idp/finish-plan';
import { PlanStatusLabels } from '@/react/data/idp/plans/constants';

const DateDisplayWrapper = ({ children }) => (
  <Grid size={{ xs: 12, sm: 4 }} sx={{ display: 'flex', alignItems: 'center' }}>
    {children}
  </Grid>
);

enum EDITING_FIELD {
  TITLE = 'title',
  DESCRIPTION = 'description',
  DURATION_IN_MONTHS = 'duration_in_months',
}

const handleBackToPlanList = () => {
  window.location.href = '/pdi/planos';
};

export type ShowPlanProps = {
  activityId?: string;
  planId: string;
};

const defaultValues = {
  title: '',
  description: '',
  duration_in_months: null,
};

const ShowPlan = ({ activityId, planId }: ShowPlanProps) => {
  const { isFetching: fetchingPlan, plan } = useGetPlan(planId);
  const { updatePlan } = useUpdatePlan();
  const activities = plan?.activities || [];

  const [editingField, setEditingField] = React.useState<EDITING_FIELD>(null);
  const [isDeletePlanModalOpen, setIsDeletePlanModalOpen] =
    React.useState(false);
  const [isRestartPlanDialogOpen, setIsRestartPlanDialogOpen] =
    React.useState(false);
  const [openShowActivityDialog, setOpenShowActivityDialog] =
    React.useState(false);
  const inputRefs = {
    [EDITING_FIELD.TITLE]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DESCRIPTION]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DURATION_IN_MONTHS]: React.useRef<HTMLInputElement>(null),
  };

  const { control, formState, getValues, reset } = useForm({
    defaultValues,
    resolver: zodResolver(updatePlanSchema),
  });

  useEffect(() => {
    if (
      !fetchingPlan &&
      plan.status === PlanStatus.DRAFT &&
      plan?.profileBasedRecommendation?.id &&
      !plan?.profileBasedRecommendation?.completed_at
    ) {
      window.location.assign(
        `/pdi/planos/recomendacoes/${plan.profileBasedRecommendation.id}`
      );
    }
  }, [plan]);

  useSetTitle(plan?.title ?? 'Plano sem título');

  const handleChange = async () => {
    const values = {
      ...getValues(),
    };

    try {
      await updatePlan({ planId, payload: values });
      setEditingField(null);
    } catch (error) {
      Sentry.captureException(error);
      setEditingField(null);
    }
  };

  const mountDurationOptions = () => {
    const options = [];
    for (let i = 1; i <= 18; i += 1) {
      options.push(
        <MenuItem key={i} value={i}>
          {getMonthLabel(i)}
        </MenuItem>
      );
    }
    return options;
  };

  const actions = [
    {
      label: 'Excluir plano',
      onClick: () => {
        setIsDeletePlanModalOpen(true);
      },
      Icon: <DeleteIcon />,
    },
  ];

  if (plan?.status == PlanStatus.CLOSED) {
    actions.push({
      label: 'Retomar plano',
      onClick: () => {
        setIsRestartPlanDialogOpen(true);
      },
      Icon: <RestoreIcon />,
    });
  }

  useEffect(() => {
    const values = {
      title: plan?.title || defaultValues.title,
      description: plan?.description || defaultValues.description,
      duration_in_months: plan?.durationInMonths
        ? plan?.durationInMonths
        : defaultValues.duration_in_months,
    };

    reset(values);
  }, [plan]);

  useEffect(() => {
    inputRefs[editingField]?.current?.focus();
  }, [editingField]);

  useEffect(() => {
    activityId && setOpenShowActivityDialog(true);
  }, [activityId]);

  const handleInlineEditFieldsChange = async (
    fieldOnChange: (value: any) => void,
    value: string
  ) => {
    try {
      fieldOnChange(value);
      await handleChange();

      return true;
    } catch (error) {
      return false;
    }
  };

  const enableStart = formState.isValid && activities.length > 0;

  const InProgressDialog = enableStart
    ? ConfirmStartPlanDialog
    : StartPlanDisabledDialog;

  const activitiesSeparatedByStatus =
    Activity.getAmountOfActivitiesByStatus(activities);

  const hasProgressBar =
    (plan?.status === PlanStatus.IN_PROGRESS ||
      plan?.status === PlanStatus.CLOSED) &&
    activities.length > 0;

  const StatusChip = {
    [PlanStatus.DRAFT]: <Chip label={PlanStatusLabels.DRAFT} color="warning" />,
    [PlanStatus.IN_PROGRESS]: (
      <Chip label={PlanStatusLabels.IN_PROGRESS} color="info" />
    ),
    [PlanStatus.CLOSED]: (
      <Chip label={PlanStatusLabels.CLOSED} color="success" />
    ),
  };

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingPlan ? (
          <PageSkeleton />
        ) : (
          plan && (
            <Grid container spacing={3}>
              <Grid size={{ xs: 12 }} className="media-print-remove">
                <SectionHeader
                  actions={actions}
                  handleBackNavigation={handleBackToPlanList}
                  title={
                    plan.status === PlanStatus.DRAFT ? 'Novo plano' : 'Voltar'
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                <ContentBox p={3}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12 }}>
                      <Controller
                        control={control}
                        name="title"
                        render={({ field, fieldState: { error } }) => (
                          <FormControl error={!!error} fullWidth>
                            <Typography
                              component={InlineEditInput}
                              data-testid="title-input"
                              disabled={plan.status === PlanStatus.CLOSED}
                              error={!!error}
                              fullWidth
                              onAccept={(value) =>
                                handleInlineEditFieldsChange(
                                  field.onChange,
                                  value
                                )
                              }
                              onClickOutside={(value) =>
                                handleInlineEditFieldsChange(
                                  field.onChange,
                                  value
                                )
                              }
                              placeholder="Dê um título para o seu plano"
                              value={field.value}
                              variant="h5"
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Controller
                        control={control}
                        name="description"
                        render={({ field, fieldState: { error } }) => (
                          <FormControl error={!!error} fullWidth>
                            <FormLabel htmlFor="description">
                              <Typography variant="body3" fontWeight="bold">
                                Descrição
                              </Typography>
                            </FormLabel>
                            <Typography
                              component={InlineEditInput}
                              data-testid="title-input"
                              disabled={plan.status === PlanStatus.CLOSED}
                              error={!!error}
                              fullWidth
                              onAccept={(value) =>
                                handleInlineEditFieldsChange(
                                  field.onChange,
                                  value
                                )
                              }
                              onClickOutside={(value) =>
                                handleInlineEditFieldsChange(
                                  field.onChange,
                                  value
                                )
                              }
                              placeholder="Adicionar descrição"
                              value={field.value}
                              variant="body3"
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <DateDisplayWrapper>
                      <Controller
                        name="duration_in_months"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FormControl
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'baseline',
                              }}
                            >
                              <FormLabel htmlFor="duration_in_months">
                                <Typography
                                  variant="body3"
                                  fontWeight="bold"
                                  mr={1}
                                >
                                  Duração:
                                </Typography>
                              </FormLabel>
                              <Select
                                {...field}
                                variant="outlined"
                                displayEmpty
                                id="duration_in_months"
                                disabled={plan.status === PlanStatus.CLOSED}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  handleChange();
                                }}
                                renderValue={(value) =>
                                  value
                                    ? getMonthLabel(value)
                                    : 'Adicionar duração'
                                }
                                size="small"
                                value={field.value || ''}
                              >
                                {mountDurationOptions()}
                              </Select>
                            </FormControl>
                          );
                        }}
                      />
                    </DateDisplayWrapper>

                    {plan.status !== PlanStatus.DRAFT && (
                      <DateDisplayWrapper>
                        <StartedAt plan={plan} />
                      </DateDisplayWrapper>
                    )}
                    {plan.status === PlanStatus.IN_PROGRESS && (
                      <DateDisplayWrapper>
                        <EstimatedEndsAt plan={plan} />
                      </DateDisplayWrapper>
                    )}
                    {plan.status === PlanStatus.CLOSED && (
                      <DateDisplayWrapper>
                        <EndedAt plan={plan} />
                      </DateDisplayWrapper>
                    )}
                  </Grid>
                </ContentBox>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <ContentBox p={3} height={'fit-content'}>
                  <Grid container spacing={3}>
                    {StatusChip[plan.status]}
                    {hasProgressBar && (
                      <Grid size={{ xs: 12 }}>
                        <ActivityProgress
                          data={{
                            [ActivityStatuses.IN_PROGRESS]:
                              activitiesSeparatedByStatus[
                                ActivityStatuses.IN_PROGRESS
                              ],
                            [ActivityStatuses.COMPLETED]:
                              activitiesSeparatedByStatus[
                                ActivityStatuses.COMPLETED
                              ],
                            [ActivityStatuses.PENDING]:
                              activitiesSeparatedByStatus[
                                ActivityStatuses.PENDING
                              ],
                          }}
                        />
                      </Grid>
                    )}
                    {plan.status === PlanStatus.DRAFT && (
                      <Grid size={{ xs: 12 }}>
                        <Box mb={3} p={2} sx={{ backgroundColor: '#FBECFF' }}>
                          <Typography variant="body3">
                            Preencha o plano com as atividades desejadas antes
                            de iniciá-lo. Após isso, acompanhe e atualize seu
                            progresso, podendo editá-lo sempre que precisar.
                          </Typography>
                        </Box>
                        <Action
                          label="Iniciar plano"
                          Dialog={({ open, onClose }) => (
                            <InProgressDialog
                              open={open}
                              onClose={onClose}
                              plan={plan}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {plan.status === PlanStatus.IN_PROGRESS && (
                      <Grid size={{ xs: 12 }}>
                        <Action
                          label="Concluir Plano"
                          description="Conclua o plano para sinalizar que você alcançou seus objetivos e está pronto para encerrar esta jornada!"
                          Dialog={({ open, onClose }) => (
                            <FinishPlan
                              open={open}
                              onClose={onClose}
                              plan={plan}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </ContentBox>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ActivitiesCardList
                  activities={activities}
                  loading={fetchingPlan}
                  plan={plan}
                />
              </Grid>
              <ConfirmDeletePlanDialog
                plan={plan}
                open={isDeletePlanModalOpen}
                onClose={() => setIsDeletePlanModalOpen(false)}
              />
              <ConfirmRestartPlanDialog
                plan={plan}
                open={isRestartPlanDialogOpen}
                onClose={() => setIsRestartPlanDialogOpen(false)}
              />
              {openShowActivityDialog && (
                <ShowActivityDialog
                  activityId={activityId}
                  onClose={() => setOpenShowActivityDialog(false)}
                  open={true}
                  planId={plan.id}
                  planStatus={plan.status}
                />
              )}
            </Grid>
          )
        )}
      </Box>
    </PageContainer>
  );
};

export { ShowPlan };
