import { LearningPaths } from '@/react/packages/learning-paths';
import { Component } from '@angular/core';

@Component({
  selector: 'app-learning-paths',
  templateUrl: './learning-paths.component.html',
})
export class LearningPathsComponent {
  LearningPathsComponent = LearningPaths;
}
