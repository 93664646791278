import { Skeleton, Stack } from '@mui/material';
import * as React from 'react';
import { ResourceApprovalDescriptionProps } from './intefaces';
import { RefundRequestList } from './refund-request-list.component';
import { ResourceApprovalDetailsAccordion } from './resource-apporval-details-accordion';
import { ResourceApprovalRequestData } from './resource-approval-request-data.component';

export const ResourceApprovalSkeleton: React.FC<
  ResourceApprovalDescriptionProps
> = ({ resourceApprovalsDetails, isLoading }) => {
  if (isLoading) {
    return (
      <Stack spacing={2} mt={2}>
        <Skeleton height={160} variant="rectangular" width="100%" />
        <Skeleton height={180} variant="rectangular" width="100%" />
        <Skeleton height={160} variant="rectangular" width="100%" />
      </Stack>
    )
  }

  if (!resourceApprovalsDetails) {
    return <div>Erro ao carregar detalhes do recurso</div>;
  }

  return (
    <>
      <ResourceApprovalRequestData
        resourceApprovalsDetails={resourceApprovalsDetails}
        isLoading={isLoading}
      />
      <ResourceApprovalDetailsAccordion
        resourceApprovalsDetails={resourceApprovalsDetails}
      />
      <RefundRequestList
        resourceApprovalUuid={resourceApprovalsDetails.uuid}
        refundRequests={resourceApprovalsDetails.refund_requests} 
      />
    </>
  );
};
