import * as React from 'react';
import CONST from '@/constants';
import { Box, CardMedia, Container, Paper, Typography } from '@mui/material';

const IFRAME_HEIGHT_IN_PX = 6400;

type DashboardProps = {
  companyUuid: string;
};

export const Dashboard: React.ElementType<DashboardProps> = ({
  companyUuid,
}) => {
  const lookerParams = {
    'ds88.company_uuid': companyUuid,
    'ds138.company_uuid': companyUuid,
    'ds144.company_uuid': companyUuid,
    'ds236.company_uuid': companyUuid,
  };

  const lookerParamsAsString = JSON.stringify(lookerParams);
  const encodedLookerParams = encodeURIComponent(lookerParamsAsString);

  return (
    <Container>
      <Paper elevation={5} sx={{ my: 2 }} style={{ padding: '32px' }}>
        <Box mb={2}>
          <Typography gutterBottom variant="h4" my={2}>
            Relatórios
          </Typography>
        </Box>
        <CardMedia
          component="iframe"
          src={`${CONST.LOOKER_DASHBOARD_URL}?params=${encodedLookerParams}`}
          sx={{ border: 0, height: IFRAME_HEIGHT_IN_PX }}
        />
      </Paper>
    </Container>
  );
};

export default Dashboard;
