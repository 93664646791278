import * as assets from './assets';
import type { QuestionNode } from '@/react/components/quiz/quiz.interfaces';
import { filterNull, IS_BROWSER } from 'powership';
import { ResultUseBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import {
  EditingProfileData,
  editingProfileSettings,
} from '@/react/components/profile-quiz/editing-profile-settings';

preloadAssets();

const quizFlowQuestions: Partial<EditingProfileData> = {
  presentation_welcome: editingProfileSettings.presentation_welcome,
  presentation_update_preferences:
    editingProfileSettings.presentation_update_preferences,
  location_info: editingProfileSettings.location_info,
  scholarity: editingProfileSettings.scholarity,
  current_job: editingProfileSettings.current_job,
  seniority: editingProfileSettings.seniority,
  goals: editingProfileSettings.goals,
  desired_job: editingProfileSettings.desired_job,
  select_course_interests: editingProfileSettings.select_course_interests,
  select_monthly_budget: editingProfileSettings.select_monthly_budget,
  input_contact_email: editingProfileSettings.input_contact_email,
  presentation_finished: editingProfileSettings.presentation_finished,
  presentation_ask_before_close:
    editingProfileSettings.presentation_ask_before_close,
};

export function createProfileQuizQuestions(
  props: ResultUseBootstrapData
): QuestionNode[] {
  const { isNewcomer, taxonomy, profile } = props;

  const isEdit = !isNewcomer;

  return filterNull<QuestionNode>([
    isNewcomer && quizFlowQuestions.presentation_welcome(),
    isEdit && quizFlowQuestions.presentation_update_preferences(),
    quizFlowQuestions.location_info({ profile }),
    quizFlowQuestions.scholarity({ profile }),
    quizFlowQuestions.current_job({ profile }),
    quizFlowQuestions.seniority({ profile }),
    quizFlowQuestions.goals({ profile }),
    quizFlowQuestions.desired_job({ profile }),
    quizFlowQuestions.select_course_interests({
      profile,
      taxonomy,
    }),
    quizFlowQuestions.select_monthly_budget({ profile }),
    quizFlowQuestions.input_contact_email({ profile }),
    quizFlowQuestions.presentation_finished(),
    quizFlowQuestions.presentation_ask_before_close(),
  ]);
}

function preloadAssets() {
  if (!IS_BROWSER) return;
  Object.values(assets).forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}
