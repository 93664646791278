import React from 'react';
import { PageContainer } from '@/react/components';
import {
  SectionHeader,
} from '@/react/components/refund/refund-management/shared';
import { useResourceApprovalsDetails } from '@/react/data/subsidy/useResourceApprovalsDetails';
import { ResourceApprovalSkeleton } from './resource-approval-skeleton.component';
const handleBackButton = () => {
  window.location.assign('/empresa/gestao-de-reembolsos');
};

export const ResourceApprovalDetails = ({
  resourceApprovalUuid,
}: {
  resourceApprovalUuid: string;
}) => {
  const { resourceApprovalsDetails, isLoading } = useResourceApprovalsDetails(resourceApprovalUuid);

  return (
    <PageContainer>
      <SectionHeader
        title="Detalhes do pedido"
        handleBackNavigation={handleBackButton}
      />
      <ResourceApprovalSkeleton
        resourceApprovalsDetails={resourceApprovalsDetails}
        isLoading={isLoading}
      />
    </PageContainer>
  );
};

export default ResourceApprovalDetails;
