import { CatalogCourse } from '@/react/data/catalog/interfaces';
import { Box, Button, ToggleButton, Typography } from '@mui/material';
import React from 'react';

interface SelectedCourseCardProps {
  onToggle?: () => void;
  onRemove?: () => void;
  viewOnly?: boolean;
  selected?: boolean;
  removable?: boolean;
  course: CatalogCourse;
}

export const SelectedCourseCard = ({
  onToggle,
  onRemove,
  selected,
  viewOnly,
  removable,
  course,
}: SelectedCourseCardProps) => {
  const isToggle = !viewOnly && !removable;

  const handleRemoveRecommendedCourse = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    onRemove && onRemove();
    e.stopPropagation();
  };

  return (
    <Box
      component={isToggle ? ToggleButton : 'div'}
      key={course.id}
      value={course}
      selected={selected}
      onClick={isToggle ? onToggle : undefined}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: ({ palette }) =>
          `1px solid ${isToggle && selected ? palette.primary[700] : palette.grey[200]}`,
        borderRadius: 1,
        padding: 2,
        cursor: isToggle ? 'pointer' : 'default',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        textTransform="none"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: course?.institution?.logo_background_color,
              borderRadius: 0.5,
              width: 32,
              height: 32,
            }}
          >
            <img
              src={course?.institution?.logo_url}
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                width: '100%',
              }}
            />
          </Box>
          <Typography
            variant="caption"
            color={isToggle ? undefined : 'neutral.600'}
          >
            {course?.institution?.name}
          </Typography>
        </Box>
        <Typography
          textAlign="left"
          variant="body3"
          fontWeight="600"
          color={isToggle ? undefined : 'neutral.900'}
          my={1}
        >
          {course?.course_name}
        </Typography>
        <Typography
          variant="caption"
          color={isToggle ? undefined : 'neutral.600'}
        >
          {course?.course_type?.label}
        </Typography>
      </Box>
      {removable && (
        <Button variant="text" onClick={handleRemoveRecommendedCourse}>
          Remover
        </Button>
      )}
    </Box>
  );
};
