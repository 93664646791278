import React from 'react';

import { ArrowForward } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import CardLink from '@/react/components/card-link.component';

export type LearningTypeCardProps = {
  description: string;
  image: string;
  label: string;
  link: string;
  buttonText?: string;
};

export const LearningTypeCard = ({
  label,
  image,
  description,
  link,
  buttonText,
}: LearningTypeCardProps) => {
  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardActionArea
        component={CardLink}
        href={link}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'column' },
          height: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <CardMedia
          component="img"
          image={image}
          sx={{
            flex: '0 0 100px',
            minHeight: { xs: '100%', sm: '128px' },
            objectPosition: { xs: 'center', sm: '0 30%' },
            overflow: 'hidden',
            borderRadius: 0,
            width: '100%',
          }}
        />
        <CardContent>
          <Typography gutterBottom variant="h6">
            {label}
          </Typography>
          <Typography variant="body3">{description}</Typography>
        </CardContent>
        {buttonText && (
          <CardActions>
            <Button variant="text" endIcon={<ArrowForward />}>
              {buttonText}
            </Button>
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
};
