import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';

import { ActivityStatusSelect, AiChip } from '@/react/components/idp/shared';
import { CourseTypes } from '@/react/data/catalog/interfaces';
import { Activity, PlanStatus } from '@/react/data/idp/interfaces';
import { ActivityType } from '@/react/data/idp/profile/interfaces';
import { mutationKey as useUpdatePlanActivityMutationKey } from '@/react/data/idp/useUpdatePlanActivity';
import { mutationKey as useUpdatePlanActivityStatusMutationKey } from '@/react/data/idp/useUpdatePlanActivityStatus';
import CardLink from '../../card-link.component';
import { ShowActivityDialog } from '../show-activity';
import { RecommendedCoursesButton } from './recommended-courses-button';
import { SelectedCourseCard } from './selected-course-card';

export interface ActivityCardProps {
  activity: Activity;
  planId: string;
  planStatus: PlanStatus;
  disableClick?: boolean;
}

interface ButtonByActivityTypeProps {
  activityType: ActivityType;
  courseType: CourseTypes;
  onClick: () => void;
  disabled?: boolean;
}
const lineClampedTypography = (lineClamp: number) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  // Since SX converts number to 'number px' (e.g. 1 = 1px), we need to convert it to string so it will consider it as a valid value
  WebkitLineClamp: String(lineClamp),
});

const ButtonByActivityType = ({
  activityType,
  courseType,
  onClick,
  disabled,
}: ButtonByActivityTypeProps) => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  if (activityType !== ActivityType.COURSE) {
    return (
      <Button
        fullWidth
        variant="outlined"
        startIcon={<SearchIcon />}
        onClick={onButtonClick}
        disabled={disabled}
        size="large"
      >
        Buscar na plataforma
      </Button>
    );
  }

  return (
    <RecommendedCoursesButton
      onClick={onClick}
      courseType={courseType}
      fullWidth
    />
  );
};

const ActivityCard = ({
  planId,
  planStatus,
  activity,
  disableClick,
}: ActivityCardProps) => {
  const [openShowActivityDialog, setOpenShowActivityDialog] =
    React.useState(false);
  const isMutatingPlanActivityStatus = useIsMutating({
    mutationKey: [...useUpdatePlanActivityStatusMutationKey],
    predicate: (mutation) =>
      mutation.state.variables?.activityId === activity.id,
  });
  const isMutatingPlanActivity = useIsMutating({
    mutationKey: [...useUpdatePlanActivityMutationKey],
    predicate: (mutation) => mutation.options.meta?.activityId === activity.id,
  });
  const { title, description, recommended_activity, resource_data } = activity;

  const shouldShowSeeRecommendedCoursesButton =
    !resource_data && planStatus !== PlanStatus.CLOSED;

  const shouldShowActivityStatus = planStatus === PlanStatus.IN_PROGRESS;
  const loading = !!isMutatingPlanActivityStatus || !!isMutatingPlanActivity;
  const disabled = loading || disableClick;

  return (
    <>
      <Card square sx={{ height: '100%', position: 'relative' }}>
        {loading && (
          <LinearProgress
            sx={{
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        )}
        <CardActionArea
          component={CardLink}
          onClick={() => setOpenShowActivityDialog(true)}
          sx={{ height: '100%' }}
          disabled={disabled}
        >
          <CardContent
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Stack spacing={3}>
              {shouldShowActivityStatus && (
                <Box>
                  <ActivityStatusSelect
                    activity={activity}
                    planId={planId}
                    planStatus={planStatus}
                  />
                </Box>
              )}
              <Stack spacing={1}>
                <Typography variant="h6" sx={lineClampedTypography(3)}>
                  {title}
                </Typography>
                {description && (
                  <Typography variant="body3" sx={lineClampedTypography(4)}>
                    {description}
                  </Typography>
                )}
                {activity?.recommended_activity && (
                  <Box mt={1}>
                    <AiChip />
                  </Box>
                )}
              </Stack>
            </Stack>

            <Stack mt={3} flex={1} display="flex" justifyContent="end">
              {resource_data && (
                <SelectedCourseCard course={resource_data} viewOnly />
              )}
              {shouldShowSeeRecommendedCoursesButton && (
                <Box className="media-print-hide">
                  <ButtonByActivityType
                    activityType={recommended_activity?.activity_type}
                    courseType={recommended_activity?.course_type}
                    onClick={() => setOpenShowActivityDialog(true)}
                    disabled={disabled}
                  />
                </Box>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      {openShowActivityDialog && (
        <ShowActivityDialog
          activityId={activity?.id}
          onClose={() => setOpenShowActivityDialog(false)}
          open={openShowActivityDialog}
          planId={planId}
          planStatus={planStatus}
        />
      )}
    </>
  );
};

export { ActivityCard };
