import { useQuery } from '@tanstack/react-query';

import { useNotification } from '@/react/components/notification';
import { IdpClient } from './idp-client';
import { Activity } from './interfaces';

export const queryKey = ['usePlanActivities'];

export function usePlanActivities(planId: string): usePlansType {
  const { toast } = useNotification();
  const result = useQuery<Activity[]>({
    enabled: !!planId,
    queryKey: [...queryKey, planId],
    queryFn: async () => {
      return IdpClient.listPlanActivities(planId);
    },
    refetchOnMount: false,
  });

  if (result.error) {
    toast({
      content: 'Erro ao buscar atividades do plano',
      type: 'error',
    });
  }

  const activities = result.data || [];

  return {
    activities,
    error: !!result.error,
    isFetching: result.isFetching,
  };
}

type usePlansType = {
  activities: Activity[];
  error: boolean;
  isFetching: boolean;
};
