import {
  RefundRequestStatus,
  RefundResourceStatus,
} from '@/react/data/subsidy/interfaces';
import { RefundRequestSteps } from '../../interfaces';
import { isEditResourceApprovalPath } from '../../../utils/path-utils';

const closingAccordionStatus = [
  'refund_request_pending_approval',
  'refund_request_revision_required',
];

export function determineExpansionInvoiceAccordion({
  step,
  resourceApproval,
  refundStatus,
  isShowPath,
  isEditPath,
}) {
  if (step !== RefundRequestSteps.INVOICE_ATTACHMENT) return false;

  switch (resourceApproval.status) {
    case RefundResourceStatus.approved:
      if (isShowPath()) {
        return true;
      }
      break;

    case RefundResourceStatus.pending_approval:
      if (isEditResourceApprovalPath()) {
        return false;
      }
      break;

    case RefundRequestStatus.pending_approval:
      if (isShowPath()) {
        return false;
      } else if (isEditPath()) {
        return true;
      }
      break;

    case RefundRequestStatus.revision_required:
      return true;

    default:
      break;
  }

  if (closingAccordionStatus.includes(refundStatus)) {
    return true;
  }

  return false;
}
