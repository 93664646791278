import React from 'react';
import { Button, DialogContent, Typography } from '@mui/material';

import Dialog, { DialogProps } from '@mui/material/Dialog';

import { BaseDialogTitle } from '@/react/components/dialog/base-dialog-title.component';
import { BaseDialogActions } from '@/react/components/dialog/base-dialog-actions.component';
import { CourseSelection } from './course-selection';

interface CourseSelectionDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  onSelect: (course: any) => void;
}
export const CourseSelectionDialog = ({
  onClose,
  open,
  onSelect,
  ...dialogProps
}: CourseSelectionDialogProps) => (
  <Dialog
    onClose={onClose}
    fullWidth
    maxWidth="sm"
    open={open}
    {...dialogProps}
  >
    <BaseDialogTitle onClose={onClose}>
      Incluir curso
      <Typography variant="body3">
        Busque na plataforma Galena o curso que irá te ajudar no desenvolvimento
        da atividade.
      </Typography>
    </BaseDialogTitle>
    <DialogContent>
      <CourseSelection
        onSubmit={(course) => {
          onSelect(course);
          onClose();
        }}
      />
    </DialogContent>
    <BaseDialogActions onClose={onClose}>
      <Button onClick={onClose} variant="text">
        Cancelar
      </Button>

      <Button type="submit" variant="contained" form="course-selection-form">
        Salvar
      </Button>
    </BaseDialogActions>
  </Dialog>
);
