import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

export interface BreadCrumb {
  label: string;
  route?: string[];
  queryParams?: any;
}

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private routerService: Router) {}

  navigate(route: any[], extras?: NavigationExtras) {
    this.routerService.navigate(route, extras);
  }

  home() {
    this.navigate(['cursos']);
  }
}
