import React, { useEffect } from 'react';
import loadingWalk from '@/react/assets/gifs/loading-walk.gif';
import { Box, Dialog, Typography } from '@mui/material';

export const FinishingPlanDialog = ({ onNext, open }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onNext();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onNext]);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth transitionDuration={0}>
      <Box sx={{ padding: 5 }}>
        <Box
          component="img"
          src={loadingWalk}
          sx={{ height: 160, width: 160, margin: '0 auto 8px' }}
        />
        <Typography variant="h6">Concluindo plano...</Typography>
      </Box>
    </Dialog>
  );
};
