import axios from 'axios';

import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';
import {
  ActivitiesResponseType,
  Activity,
  ActivityResponseType,
  CreatePlanType,
  Plan,
  PlanResponse,
  PlansResponseType,
  PlanStatus,
} from './interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import {
  ProfileBasedRecommendation,
  RecommendedPlanActivity,
} from '@/react/data/idp/profile/interfaces';

export type ErrorResponseType = {
  error: string;
  exception: string;
  status: number;
  traces: any;
};

export class ErrorResponse {
  public error: string;
  public exception: string;
  public status: number;
  public traces: any;

  constructor(args: ErrorResponseType) {
    const { error, exception, status, traces } = args;

    this.error = error;
    this.exception = exception;
    this.status = status;
    this.traces = traces;
  }
}

export class IdpClient {
  static BASE_URL = `${CONST.FALCON_API_URL}/idp/api`;

  static client = withAuthorization(
    axios.create({
      baseURL: IdpClient.BASE_URL,
    })
  );

  static createPlan = async (payload: CreatePlanType): Promise<Plan> => {
    const res = await IdpClient.client.post('plans', payload);
    return res.data;
  };

  static showPlan = async (planId: string): Promise<Plan> => {
    const res = await IdpClient.client.get<PlanResponse>(`plans/${planId}`);
    return Plan.fromServerPayload(res.data);
  };

  static plans = async (params: {} = {}): Promise<Plan[]> => {
    const res = await IdpClient.client.get<PlansResponseType>('plans', {
      params,
    });
    return res.data?.plans?.map((plan: PlanResponse) =>
      Plan.fromServerPayload(plan)
    );
  };

  static updatePlan = async (
    planId: string,
    payload: CreatePlanType
  ): Promise<Plan> => {
    const res = await IdpClient.client.put<PlanResponse>(
      `plans/${planId}`,
      payload
    );
    return Plan.fromServerPayload(res.data);
  };

  static startPlan = async (planId: string): Promise<any> => {
    const res = await IdpClient.client.post(`plans/${planId}/plan_statuses`, {
      status: PlanStatus.IN_PROGRESS,
    });
    return res.data;
  };

  static listPlanActivities = async (planId: string): Promise<Activity[]> => {
    const res = await IdpClient.client.get<ActivitiesResponseType>(
      `plans/${planId}/activities`
    );
    return res.data?.activities?.map((activity: ActivityResponseType) =>
      Activity.fromServerPayload(activity)
    );
  };

  static createActivity = async (
    planId: string,
    activity: Activity
  ): Promise<Activity> => {
    const res = await IdpClient.client.post(
      `plans/${planId}/activities`,
      activity
    );
    return Activity.fromServerPayload(res.data);
  };

  static showPlanActivity = async (
    planId: string,
    activityId: string
  ): Promise<Activity> => {
    const res = await IdpClient.client.get<ActivityResponseType>(
      `plans/${planId}/activities/${activityId}`
    );
    return Activity.fromServerPayload(res.data);
  };

  static updatePlanActivity = async (
    activity: Activity,
    payload: ActivityResponseType
  ): Promise<Activity> => {
    const res = await IdpClient.client.put<ActivityResponseType>(
      `plans/${activity.planId}/activities/${activity.id}`,
      payload
    );
    return Activity.fromServerPayload(res.data);
  };

  static updatePlanActivityStatus = async ({
    planId,
    activityId,
    status,
  }): Promise<Activity> => {
    const res = await IdpClient.client.patch(
      `plans/${planId}/activities/${activityId}/update_status`,
      {
        status,
      }
    );

    return res.data;
  };

  static finishPlan = async (planId: string): Promise<any> => {
    const res = await IdpClient.client.post(`plans/${planId}/plan_statuses`, {
      status: PlanStatus.CLOSED,
    });

    return res.data;
  };

  static deletePlan = async (planId: string): Promise<any> => {
    const res = await IdpClient.client.delete(`plans/${planId}`);
    return res.data;
  };

  static deleteActivity = async (
    planId: string,
    activityId: String
  ): Promise<any> => {
    const res = await IdpClient.client.delete(
      `plans/${planId}/activities/${activityId}`
    );
    return res.data;
  };

  static reorderActivities = async (
    planId: string,
    activitiesIds: { id: string }[]
  ): Promise<Activity[]> => {
    const res = await IdpClient.client.patch(
      `plans/${planId}/order_activities`,
      {
        plan: {
          activities_attributes: activitiesIds.map((activity, index) => ({
            id: activity.id,
            position: index + 1,
          })),
        },
      }
    );
    return res.data;
  };

  static deleteRecommendation = async (
    recommendationId: string
  ): Promise<any> => {
    const res = await IdpClient.client.delete(
      `profile_based_recommendations/${recommendationId}`
    );
    return res.data;
  };

  static createRecommendationPlan = async (
    currentStep: CreatePlanWizardSteps
  ): Promise<any> => {
    const res = await IdpClient.client.post('profile_based_recommendations', {
      current_step: currentStep,
    });
    return res.data;
  };

  static updateProfileBasedRecommendation = async (
    profileBasedRecommendationId: string,
    data: Partial<ProfileBasedRecommendation>
  ): Promise<any> => {
    const res = await IdpClient.client.patch(
      `profile_based_recommendations/${profileBasedRecommendationId}`,
      data
    );
    return res.data;
  };

  static getProfileBasedRecommendations = async (
    recommendationId: string
  ): Promise<any> => {
    const res = await IdpClient.client.get(
      `profile_based_recommendations/${recommendationId}`
    );
    return res.data;
  };

  static updateProfileBasedRecommendations = async (
    recommendationId: string,
    currentStep: CreatePlanWizardSteps
  ) => {
    const res = await IdpClient.client.patch(
      `profile_based_recommendations/${recommendationId}`,
      {
        current_step: currentStep,
      }
    );
    return res.data;
  };

  static completeProfileBasedRecommendations = async (
    recommendationId: string,
    activities: RecommendedPlanActivity['id'][] = [],
    currentStep: CreatePlanWizardSteps
  ) => {
    const res = await IdpClient.client.post(
      `profile_based_recommendations/${recommendationId}/complete`,
      {
        activities,
        current_step: currentStep,
      }
    );
    return res.data;
  };

  static updatePlanRecommendationsSkills = async (
    stepId: CreatePlanWizardSteps,
    recommendationId: string,
    skills
  ) => {
    const res = await IdpClient.client.post(
      `profile_based_recommendations/${recommendationId}/process_recommendation`,
      {
        current_step: stepId,
        developing_skills: skills,
      }
    );
    return res.data;
  };
}
