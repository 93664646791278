import React from 'react';

import { Stack, Typography } from '@mui/material';

import { LearningTypeList, PageContainer } from '@/react/components';
import { mockData } from './mock-data';

export const LearningPaths = () => {
  const paths = mockData.map((item) => ({
    ...item,
    image: item.header_url,
    link: `/trilhas/${item.id}`,
  }));

  return (
    <PageContainer>
      <Stack spacing={1} sx={{ flexGrow: 1 }} py={3}>
        <Typography variant="h4">Trilhas de aprendizagem</Typography>
        <Typography variant="body3">
          Descubra as Trilhas de Aprendizagem da sua empresa e desenvolva suas
          habilidades com conteúdos selecionados para você!
        </Typography>
      </Stack>
      <LearningTypeList data={paths} buttonsText="Começar trilha" />
    </PageContainer>
  );
};
