import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const StyledList = styled('ol')`
  counter-reset: item;
  list-style: none;
  margin: 0;
  padding-left: ${({ theme }) => theme.spacing(3)};

  li {
    counter-increment: item;
    font-size: ${({ theme }) => theme.typography.body3.fontSize};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    position: relative;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li:before {
    color: ${({ theme }) => theme.palette.primary[200]};
    content: counter(item) '.';
    display: block;
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    left: ${({ theme }) => theme.spacing(-3)};
    position: absolute;
    top: 0;
  }
`;

export const localStorageKey = 'hide-how-refund-works-dialog';

const HowRefundWorksDialog = ({
  open: forceOpen,
  onClose: externalCallback,
  showContinueButton,
}: {
  open?: boolean;
  onClose?: () => void;
  showContinueButton?: boolean;
}) => {
  const bypassOpen = !!localStorage.getItem(localStorageKey);
  const [open, setOpen] = React.useState(!!forceOpen);
  const [neverOpenAgain, setNeverOpenAgain] = React.useState(bypassOpen);
  const [buttonLabel, setButtonLabel] = React.useState('Fechar');

  React.useEffect(() => {
    if (forceOpen || !bypassOpen) {
      showContinueButton && setButtonLabel('Continuar');
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [bypassOpen, forceOpen]);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNeverOpenAgain(event.target.checked);
  };

  const handleClose = async () => {
    if (neverOpenAgain) {
      localStorage.setItem(localStorageKey, 'true');
    } else {
      localStorage.removeItem(localStorageKey);
    }

    if (typeof externalCallback === 'function') {
      await externalCallback();
    }

    setOpen(false);
  };

  const handleNewRefund = () => {
    window.location.assign('/meus-reembolsos/novo');
    handleClose();
  };

  const buttonAction = showContinueButton ? handleNewRefund : handleClose;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Como funciona o reembolso</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: ({ palette }) => palette.grey[500],
          position: 'absolute',
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          p={3}
          mb={2}
          sx={{
            p: 3,
            backgroundColor: ({ palette }) => palette.primary['100'],
          }}
        >
          <StyledList>
            <li>
              Você solicita aprovação de uso do reembolso para o item desejado.
            </li>
            <li>
              Sua empresa analisa a solicitação de acordo com suas políticas
              internas.
            </li>
            <li>
              Após aprovação, você envia o comprovante de compra e seus dados
              bancários.
            </li>
            <li>
              As informações e documentos são avaliados para garantir que sejam
              válidos e legíveis.
            </li>
            <li>
              O pagamento é feito em até 7 dias úteis após a validação dos seus
              documentos.
            </li>
          </StyledList>
        </Box>
        <Typography variant="body3">
          <strong>Dica:</strong> Você pode esperar para adquirir o item depois
          de ter a aprovação da sua empresa para usar o reembolso (passo 2).
        </Typography>
      </DialogContent>
      <DialogActions>
        {!bypassOpen && (
          <FormControlLabel
            data-testid="never-open-again-checkbox"
            control={
              <Checkbox onChange={handleCheckbox} checked={neverOpenAgain} />
            }
            label={
              <Typography variant="body3">Não mostrar novamente</Typography>
            }
            sx={{ marginRight: 'auto' }}
          />
        )}
        <Button
          data-testid={showContinueButton ? 'button-continue' : 'button-action'}
          color="primary"
          variant="contained"
          onClick={buttonAction}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HowRefundWorksDialog;
