import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { RouterService } from '../router.service';
import { loadBootstrapData } from '@/react/data/bootstrap/useBootstrapData';

export const featureIdpGuard: CanActivateChildFn = async () => {
  const routerService = inject(RouterService);
  const { plan } = await loadBootstrapData();

  if (!plan?.features?.find((feature) => feature.name === 'idp')) {
    routerService.home();
    return false;
  }

  return true;
};
