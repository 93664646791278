import { useQuery } from '@tanstack/react-query';

import { useNotification } from '@/react/components/notification';
import { IdpClient } from './idp-client';
import { ProfileBasedRecommendation } from './interfaces';
import { ProfileBasedRecommendationActivitiesStatus } from '@/react/data/idp/profile';

const RETRY_INTERVAL_IN_MS = 5000;
export const queryKey = ['useGetProfileBasedRecommendationActivities'];

export function useGetProfileBasedRecommendationActivities(
  recommendationId: string
) {
  const { toast } = useNotification();
  let status = ProfileBasedRecommendationActivitiesStatus.IN_PROGRESS;

  const { data, error, isPending, isRefetching, refetch } =
    useQuery<ProfileBasedRecommendation>({
      queryFn: async () => {
        try {
          const result =
            await IdpClient.getProfileBasedRecommendations(recommendationId);
          return result;
        } catch (error) {
          toast({
            content: 'Erro ao buscar recomendação de plano',
            type: 'error',
          });
          return null;
        }
      },
      queryKey: [...queryKey, recommendationId],
      refetchInterval(query) {
        if (
          query.state.data === null ||
          query.state.data?.processing_status ===
            ProfileBasedRecommendationActivitiesStatus.FAIL
        ) {
          return false;
        }

        return RETRY_INTERVAL_IN_MS;
      },
    });

  const recommendedActivities = data?.recommended_activities || null;
  const isGenerating = !recommendedActivities;

  if (
    !!error ||
    data === null ||
    data?.processing_status === ProfileBasedRecommendationActivitiesStatus.FAIL
  ) {
    status = ProfileBasedRecommendationActivitiesStatus.FAIL;
  } else if (
    !isPending &&
    !isRefetching &&
    !isGenerating &&
    data.processing_status ===
      ProfileBasedRecommendationActivitiesStatus.SUCCESS
  ) {
    status = ProfileBasedRecommendationActivitiesStatus.SUCCESS;
  }

  return {
    recommendedActivities,
    error: !!error,
    isGenerating,
    status,
    refetch,
  };
}
