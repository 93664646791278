import { inject } from '@angular/core';
import { UrlTree, CanActivateFn } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { RouterService } from '../router.service';
import { SubsidiesService } from '../subsidies.service';

export const SubsidiesCreateRefundGuard: CanActivateFn = (): Observable<
  boolean | UrlTree
> => {
  const routerService = inject(RouterService);
  const subsidiesService = inject(SubsidiesService);

  return from(subsidiesService.fetchPolicies()).pipe(
    switchMap(() =>
      from(checkEligibilityAndNavigate(routerService, subsidiesService))
    )
  );

  async function checkEligibilityAndNavigate(
    routerService: RouterService,
    subsidiesService: SubsidiesService
  ): Promise<boolean | UrlTree> {
    const navigateToRefunds = () => {
      routerService.navigate(['meus-reembolsos'], {
        queryParams: { redirectUrl: window.location.href },
      });
      return false;
    };

    try {
      await subsidiesService.fetchPolicies();

      if (subsidiesService.eligible_for_request_refund) {
        return true;
      }

      return navigateToRefunds();
    } catch (err) {
      return navigateToRefunds();
    }
  };
};
