export enum PlanStatus {
  CLOSED = 'closed',
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
}

export enum PlanStatusLabels {
  CLOSED = 'Plano finalizado',
  DRAFT = 'Plano em rascunho',
  IN_PROGRESS = 'Plano em andamento',
}
