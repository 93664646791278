import React, { useState } from 'react';
import { Button, DialogContent, Typography } from '@mui/material';

import Dialog, { DialogProps } from '@mui/material/Dialog';

import { BaseDialogTitle } from '@/react/components/dialog/base-dialog-title.component';
import { BaseDialogActions } from '@/react/components/dialog/base-dialog-actions.component';
import { Activity } from '@/react/data/idp/interfaces';
import { ReorderActivities } from './reorder-activities';
import { useReorderActivities } from '@/react/data/idp/useReorderActivities';

interface ReorderActivitiesDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  planId: string;
  activities: Activity[];
}

export const ReorderActivitiesDialog = ({
  onClose,
  open,
  planId,
  activities,
  ...dialogProps
}: ReorderActivitiesDialogProps) => {
  const [items, setItems] = useState(activities);

  const { reorderActivities } = useReorderActivities(planId);

  const handleSubmit = () => {
    reorderActivities(items);
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        open={open}
        {...dialogProps}
      >
        <BaseDialogTitle onClose={onClose}>
          Reordenar atividades
          <Typography variant="body3">
            Altere a ordem arrastando as atividades
          </Typography>
        </BaseDialogTitle>
        <DialogContent>
          <ReorderActivities
            activities={activities}
            planId={planId}
            updateActivities={setItems}
          />
        </DialogContent>
        <BaseDialogActions onClose={onClose}>
          <Button onClick={onClose} variant="text">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Salvar
          </Button>
        </BaseDialogActions>
      </Dialog>
    </>
  );
};
