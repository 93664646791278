import bgTechnical from '@/react/assets/images/technical.png';

export const mockData = [
  {
    id: 123,
    label: 'Trilha de Aprendizagem 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
  {
    id: 123,
    label: 'Trilha de Aprendizagem 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
  {
    id: 123,
    label: 'Trilha de Aprendizagem 3',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
  {
    id: 123,
    label: 'Trilha de Aprendizagem 4',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
  {
    id: 123,
    label: 'Trilha de Aprendizagem 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
  {
    id: 123,
    label: 'Trilha de Aprendizagem 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    header_url: bgTechnical,
  },
];
