import {
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';

import { useNotification } from '@/react/components/notification';
import { useGetPlanKey } from '@/react/data/idp/usePlan';
import { IdpClient } from './idp-client';
import { Activity } from './interfaces';

const defaultMessages = {
  error: 'Erro ao criar atividade.',
  success: 'Atividade criada com sucesso!',
};

export function useCreatePlanActivity(
  planId: string
): useCreatePlanActivityType {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  const { mutateAsync: createPlanActivity, isPending } = useMutation({
    mutationFn: (activity: Activity) => {
      return IdpClient.createActivity(planId, activity);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...useGetPlanKey, planId],
      });

      toast({
        content: defaultMessages.success,
        type: 'success',
      });
    },
    onError: (error: any) => {
      Sentry.captureException(error);

      toast({
        content: defaultMessages.error,
        type: 'error',
      });
    },
  });

  return {
    createPlanActivity,
    isPending,
  };
}

type useCreatePlanActivityType = {
  createPlanActivity: UseMutateFunction<Activity, unknown, any, unknown>;
  isPending: boolean;
};
