import { useQuery } from '@tanstack/react-query';
import { ResourceApprovalDetails } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export const useResourceApprovalsDetailsQueryKey = (uuid: string) => ['useResourceApprovalDetails', uuid];

export function useResourceApprovalsDetails(resourceApprovalUuid: string) {
  const result = useQuery({
    queryKey: useResourceApprovalsDetailsQueryKey(resourceApprovalUuid),
    enabled: !!resourceApprovalUuid,
    refetchOnMount: true,
    queryFn: async ({ signal }) => {
      return SubsidyClient.getResourceApprovalDetails({
        resourceApprovalUuid,
        signal,
      });
    },
  });

  const resourceApprovalsDetails = result.data?.resource_approval;

  return {
    resourceApprovalsDetails,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    error: !!result.error,
  };
}
