import * as React from 'react';
import { Chip, Typography, Stack } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const AiChip = () => (
  <>
    <Chip
      label={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <AutoAwesomeIcon sx={{ color: 'primary.dark', fontSize: 16 }} />
          <Typography variant="caption">recomendado por IA</Typography>
        </Stack>
      }
      size="small"
      sx={{ color: 'primary.dark', backgroundColor: 'primary.light' }}
    />
  </>
);
