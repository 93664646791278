import * as React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

import { Activity, ActivityStatuses } from '@/react/data/idp/activities';

const labels = {
  [ActivityStatuses.IN_PROGRESS]: 'Em andamento',
  [ActivityStatuses.COMPLETED]: 'Feito',
  [ActivityStatuses.PENDING]: 'A fazer',
};

interface ActivityProgressSlot {
  percentage?: number;
  quantity: number;
}

export interface ActivityProgressProps {
  data: {
    [ActivityStatuses.IN_PROGRESS]: ActivityProgressSlot;
    [ActivityStatuses.COMPLETED]: ActivityProgressSlot;
    [ActivityStatuses.PENDING]: ActivityProgressSlot;
  };
  legend?: boolean;
  title?: boolean;
}

const ActivityProgressSlot = ({ backgroundColor, id, label, percentage }) => {
  return (
    <Tooltip title={label} placement="top">
      <Box
        component="span"
        data-testid={`activity-progress-${id}`}
        sx={{
          backgroundColor,
          overflow: 'hidden',
          textIndent: '100%',
          whiteSpace: 'nowrap',
          width: `${percentage}%`,
        }}
      >
        {percentage}
      </Box>
    </Tooltip>
  );
};

const ActivityProgressLegend = ({ backgroundColor, label }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={(theme) => ({
          backgroundColor,
          height: theme.spacing(2),
          width: theme.spacing(2),
          borderRadius: theme.spacing(2),
        })}
      />
      <Typography
        variant="body3"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const ActivityProgress = ({
  data,
  legend = true,
  title = true,
}: ActivityProgressProps) => {
  const formatDataSlot = ({
    item,
    key,
  }: {
    item: ActivityProgressSlot;
    key: ActivityStatuses;
  }) => {
    const theme = useTheme();
    const { percentage = 0, quantity } = item;
    const { backgroundColor } = Activity.getActivityStatusColor(
      key,
      theme.palette
    );
    const label = `${labels[key]}: ${quantity} (${percentage.toFixed(0)}%)`;

    return {
      backgroundColor,
      label,
      percentage,
      quantity,
    };
  };

  const renderData = (data: ActivityProgressProps['data']) => {
    const items = Object.keys(data).map((key) => {
      const item = data[key];
      const { backgroundColor, label, percentage } = formatDataSlot({
        item,
        key,
      } as {
        item: ActivityProgressSlot;
        key: ActivityStatuses;
      });

      return (
        <ActivityProgressSlot
          backgroundColor={backgroundColor}
          id={key}
          key={key}
          label={label}
          percentage={percentage}
        />
      );
    });

    return items;
  };

  const renderLegend = (data: ActivityProgressProps['data']) => {
    const legend = Object.keys(data).map((key) => {
      const item = data[key];
      const { backgroundColor, label } = formatDataSlot({
        item,
        key,
      } as {
        item: ActivityProgressSlot;
        key: ActivityStatuses;
      });

      return (
        <ActivityProgressLegend
          backgroundColor={backgroundColor}
          key={key}
          label={label}
        />
      );
    });

    return (
      <Box
        mt={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        {legend}
      </Box>
    );
  };

  const renderTitle = () => {
    if (!title) {
      return;
    }

    return (
      <Typography gutterBottom variant="body3" fontWeight="bold">
        Progresso {data[ActivityStatuses.COMPLETED].percentage.toFixed(0)}%
      </Typography>
    );
  };

  return (
    <Box>
      {renderTitle()}
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral[400],
          display: 'flex',
          flex: 1,
          height: theme.spacing(1),
          width: '100%',
        })}
      >
        {renderData(data)}
      </Box>
      {legend && renderLegend(data)}
    </Box>
  );
};
