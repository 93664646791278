import * as React from 'react';
import { Box, Typography, Skeleton, Stack } from '@mui/material';

import { ResourceTypes } from '@/react/data/subsidy/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { State as WizardState } from '@/react/components/wizard/state/interfaces';

import { WizardProvider } from '@/react/components/wizard/state/context';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import Wizard from '@/react/components/wizard/wizard.component';

import { ResourceApprovalCheckEligibility } from './refund-summary/resource-approval-check-eligibility.component';
import ExternalRefundForm from './external-refund-form/external-refund-form.component';
import ResourceApprovalSummary from './refund-summary/resource-approval-summary.component';
import LearningAidForm from '@/react/components/refund/refund-request/description-step/external-refund-form/learning-aid-form/learning-aid-form.component';

const Title = ({ children }) => {
  return (
    <Box>
      <Typography variant="h6" color="primary.900">
        {children}
      </Typography>
    </Box>
  );
};

const stepConfigurations = [
  WizardStepIds.CHECK_RESOURCE_ELIBILITY,
  WizardStepIds.SUMMARY,
  WizardStepIds.EXTERNAL_COURSE_FORM,
  WizardStepIds.EXTERNAL_BOOK_FORM,
  WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
  WizardStepIds.EXTERNAL_OTHER_FORM,
];

const stepComponents = {
  [WizardStepIds.CHECK_RESOURCE_ELIBILITY]: { component: ResourceApprovalCheckEligibility },
  [WizardStepIds.SUMMARY]: {
    component: ResourceApprovalSummary,
    titleElement: <Title>Reembolso disponível</Title>,
  },
  [WizardStepIds.EXTERNAL_COURSE_FORM]: { component: ExternalRefundForm },
  [WizardStepIds.EXTERNAL_BOOK_FORM]: {
    component: () => <LearningAidForm resourceType={ResourceTypes.BOOK} />,
  },
  [WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM]: {
    component: () => (
      <LearningAidForm resourceType={ResourceTypes.EVENT_OR_LECTURE} />
    ),
  },
  [WizardStepIds.EXTERNAL_OTHER_FORM]: {
    component: () => <LearningAidForm resourceType={ResourceTypes.OTHERS} />,
  },
};

export const DescriptionEditStep = () => {
  const stepsIds = Object.values(stepConfigurations);
  const steps = stepsIds.reduce((acc, stepId) => {
    const stepConfig = stepComponents[stepId];
    acc[stepId] = {
      ...stepConfig,
    };
    return acc;
  }, {});

  return (
    <>
      <Wizard steps={steps} />
    </>
  );
};

const labelToIndex = {
  course: WizardStepIds.EXTERNAL_COURSE_FORM,
  book: WizardStepIds.EXTERNAL_BOOK_FORM,
  event_or_lecture: WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
  others: WizardStepIds.EXTERNAL_OTHER_FORM,
};

const createInitialState = ({ resourceApproval }) => {
  const state: WizardState = {
    currentStepId: labelToIndex[resourceApproval.type.label],
    values: {
      externalResourceTypeForm: resourceApproval.type.label,
      itemSourceForm: 'external',
      resourceType: resourceApproval.type.label,
    },
  };

  return state;
};

const DescriptionEditStepContext = () => {
  const { resourceApproval, loading } = useRefundRequest();

  if (loading) {
    return (
      <Stack spacing={3}>
        <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <Skeleton
              data-testid="skeleton-loading"
              variant="rectangular"
              height={68}
            />
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            <Skeleton
              data-testid="skeleton-loading"
              variant="rectangular"
              height={68}
            />
          </Typography>
        </Box>
      </Stack>
    );
  }

  const initialState = createInitialState({
    resourceApproval,
  });

  return (
    <WizardProvider initialState={initialState}>
      <DescriptionEditStep />
    </WizardProvider>
  );
};

export default DescriptionEditStepContext;
