import React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const Styled = {
  SelectedChip: styled(Chip)(({ theme }) => ({
    color: theme.palette.primary[700],
    borderColor: theme.palette.primary[700],
    backgroundColor: theme.palette.primary[100],
    '&&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
    '& .MuiChip-deleteIcon *': {
      color: theme.palette.primary[700],
    },
  })),
  SelectionHelper: styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    top: -20px;
    height: 20px;
    margin-bottom: 10px;
    background: white;
    display: flex;
    width: 100%;
  `,
  Content: styled(Stack)`
    overflow: hidden;
    overflow-y: auto;
  `,
};

interface TagSelectProps {
  options: { label: string; value: string; checked: boolean }[];
  onChange: (index: number) => void;
}

export const TagSelect = ({ options, onChange }: TagSelectProps) => {
  return (
    <>
      <Styled.Content
        direction="row"
        flexWrap={'wrap'}
        spacing={2}
        useFlexGap
        width={'100%'}
        data-testid="tag-select"
      >
        {options.map((option, index) => {
          const ChipComponent = option.checked ? Styled.SelectedChip : Chip;

          return (
            <ChipComponent
              key={option.value}
              label={option.label}
              onClick={() => onChange(index)}
              variant="outlined"
              onDelete={option.checked ? () => onChange(index) : null}
              deleteIcon={<CheckIcon />}
            />
          );
        })}
      </Styled.Content>
    </>
  );
};
