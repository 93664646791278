import { useQuery } from '@tanstack/react-query';
import { SubsidyClient } from './subsidy-client';
import { RefundRequestResponse } from '@/react/data/subsidy/interfaces';

export const useRefundRequestQueryKey = (uuid: string) => [
  'useRefundRequest',
  uuid,
];

export function useRefundRequest(refundRequestUuid: string) {
  const result = useQuery<RefundRequestResponse>({
    queryKey: useRefundRequestQueryKey(refundRequestUuid),
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      return SubsidyClient.getRefundRequest({
        refundRequestUuid,
        signal,
      });
    },
  });

  const refundRequest = result?.data?.refund_request;

  return {
    refundRequest,
    isFetching: result.isFetching,
    isLoading: result.isLoading,
    error: !!result.error,
  };
}
