import React from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import Partners from '@/react/assets/images/partners.svg';
import { usePlanActivities, usePlans } from '@/react/data/idp';
import { PlansByStatus, PlanStatus } from '@/react/data/idp/plans';
import {
  PlanCardDraft,
  PlanCardInProgress,
} from '@/react/components/idp/home/components';

export const IdpSection = () => {
  const { isFetching: fetchingPlans, plans } = usePlans();

  return fetchingPlans ? (
    <Skeleton variant="rounded" width="100%" height={242} />
  ) : (
    <Grid container>
      <PlanCardSelector plans={plans} />
    </Grid>
  );
};

const PlanCardSelector = ({ plans }: { plans: PlansByStatus }) => {
  const { activities, isFetching: fetchingActivities } = usePlanActivities(
    plans[PlanStatus.IN_PROGRESS]?.id
  );

  if (plans[PlanStatus.IN_PROGRESS]) {
    return (
      <Grid size={12}>
        <PlanCardInProgress
          activities={activities}
          loadingActivities={fetchingActivities}
          plan={plans[PlanStatus.IN_PROGRESS]}
        />
      </Grid>
    );
  }

  if (plans[PlanStatus.DRAFT]) {
    return (
      <Grid size={{ xs: 12, md: 6 }}>
        <PlanCardDraft
          data-testid="planItemDraft"
          plan={plans[PlanStatus.DRAFT]}
        />
      </Grid>
    );
  }

  return (
    <Grid size={{ xs: 12, md: 6 }}>
      <EmptyPlanCard />
    </Grid>
  );
};

const EmptyPlanCard = () => (
  <Card sx={{ width: '100%' }}>
    <CardContent>
      <Box sx={{ backgroundColor: 'neutral.100', p: 3, display: 'flex' }}>
        <Box component="img" src={Partners} sx={{ width: 102, mr: 2 }} />
        <Stack gap={2}>
          <Typography variant="body1" color="primary.900">
            Organize o seu aprendizado!
          </Typography>
          <Typography variant="body2">
            Crie um plano estruturado com ajuda da nossa Inteligência Artificial
            para desenvolver as competências necessárias para o seu próximo
            passo de carreira.
          </Typography>
        </Stack>
      </Box>
      <Button variant="text" sx={{ mt: 2 }} href="/pdi/planos/recomendacoes">
        Criar plano de desenvolvimento <AutoAwesomeIcon sx={{ ml: 1 }} />
      </Button>
    </CardContent>
  </Card>
);
