export const ColaboradorRoutes = {
  MANAGE_DEPENDENTS: 'dependentes',
  MY_REFUNDS: 'my_refunds',
  MY_SUBSIDIES: 'meus-reembolsos',
  REFUND_APPROVAL: 'aprovacao',
  REFUND_DESCRIPTION: 'descricao',
  REFUND_INVOICE: 'comprovante',
  REFUND_PAYMENT: 'pagamento',
  REFUND_REQUEST: 'novo',
  REFUND_POLICIES: 'minhas-politicas',
  REFUND_VALIDATION: 'validacao',
  NOTIFICATIONS: 'notifications',
  PENDING: 'pending',
  OVERVIEW: 'overview',
  PESQUISA: 'pesquisa',
  REFUND_FORM: 'refund_form',
  REFUND_FORM_EDIT: 'editar-comprovante',
  RESOURCE_APPROVAL_FORM_EDIT: 'editar-solicitacao',
};

export const ColaboradorBreadcrumbs = {
  ACOMPANHAR_SOLICITACOES: 'Acompanhar Solicitações',
  SOLICITACOES_PENDENTES: 'Em Análise',
};
