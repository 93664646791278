import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from '@/react/utils/date';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { isEditPath } from '@/react/components/refund/utils/path-utils';

import theme from '@/react/@mui/theme';

export const ResumeAttachment = () => {
  const { resourceApproval, subsidyPolicy } = useRefundRequest();

  const accordion = useAccordion();
  const [showEditLink, setShowEditLink] = React.useState(false);

  React.useEffect(() => {
    if (!accordion.steps.invoiceAttachment.expanded) {
      const timer = setTimeout(() => {
        setShowEditLink(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShowEditLink(false);
    }
  }, [accordion.steps.invoiceAttachment.expanded]);

  return (
    <>
      {!resourceApproval?.refund_request?.uuid ? null : (
        <Grid container>
          <Grid
            item
            xs={12}
            justifyContent="center"
            sx={({ palette, spacing }) => ({
              color: palette.neutral[800],
              marginBottom: spacing(2),
              paddingBottom: spacing(0.5),
              paddingTop: spacing(0.5),
            })}
          >
            <Typography variant="body3">
              {`Enviado em: ${formatDate(resourceApproval?.refund_request.created_at)}`}
            </Typography>

            {resourceApproval.status === RefundRequestStatus.pending_approval &&
              subsidyPolicy &&
              !isEditPath() &&
              (showEditLink ? (
                <Grid item xs={12} md={3} sx={{ mt: '22px', pt: 0 }}>
                  <Link
                    color={theme.palette.primary[200]}
                    fontWeight={600}
                    href={`/meus-reembolsos/solicitacao/${resourceApproval.uuid}/pedido/${resourceApproval.refund_request.uuid}/editar-comprovante`}
                    onClick={(event) => event.stopPropagation()}
                  >
                    Editar
                  </Link>
                </Grid>
              ) : null)}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const HeaderInvoiceAttachment = () => {
  const { resourceApproval } = useRefundRequest();
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {([
        'canceledRefundRequestStepThree',
        'declinedRefundRequestStepFour',
        'waitingForDocumentationRevisionStepFour',
        'pendingRefundPaymentStepFive',
        'paidRefundRequestStepFive',
        'needsRefundRequestRevisionStepFour',
      ].includes(mappedStatus) ||
        resourceApproval.status === RefundRequestStatus.approved) && (
        <ResumeAttachment />
      )}
    </>
  );
};
