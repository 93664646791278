import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CursosRoutingModule } from './cursos-routing.module';
import { HomeComponent } from './home/home.component';
import { CustomCategoryComponent } from './custom-category/custom-category.component';
import { RegistrationComponent } from './registration/registration.component';
import { CourseGaleryComponent } from './course-galery/course-galery.component';
import { UsersName } from './home/users-name/users-name.component';
import { CourseThemesPage } from '@/pages/cursos/course-themes/home.component';
import { ProductPageComponentReact } from './product-page/product-page.component';
import { ProductNotFoundPageComponent } from './product-not-found-page/product-not-found-page.component';

@NgModule({
  declarations: [
    ProductPageComponentReact,
    ProductNotFoundPageComponent,
    HomeComponent,
    CourseThemesPage,
    CustomCategoryComponent,
    RegistrationComponent,
    CourseGaleryComponent,
    UsersName,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    CursosRoutingModule,
  ],
})
export class CursosModule {}
