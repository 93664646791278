import * as     React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

export type ConfirmRefundRequestApproveDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (comment: string) => void;
};

const ConfirmRefundRequestApproveDialog: React.FC<
  ConfirmRefundRequestApproveDialogProps
> = ({
  open: externalOpen,
  onClose,
  onConfirm,
}) => {
  const [open, setOpen] = React.useState(externalOpen);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  const handleConfirm = () => {
    onConfirm(comment.trim());
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Aprovar reembolso</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body3" sx={{ fontWeight: 400 }}>
            Atenção! Você está prestes a aprovar um pedido de reembolso com documentação recusada pela Galena. Ao aprovar o pedido, você estará concordando com a documentação enviada conforme as regras de reembolso da sua empresa. Após aprovação, a ação não poderá ser desfeita e o solicitante receberá o valor do pedido.
          </Typography>
          <TextField
            label="Comentário"
            placeholder="Escreva a justificativa aqui..."
            multiline
            rows={4}
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{
            padding: '14px 16px',
            backgroundColor: 'neutral.50',
            color: 'neutral.800',
            width: 101,
            height: 48,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'neutral.50',
              color: 'neutral.800',
             }
          }} 
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '14px 16px',
            width: 128,
            height: 48,
            borderRadius: 0,
            backgroundColor: 'yellow.500',
            '&:hover': { backgroundColor: 'yellow.500' }
          }}
          onClick={handleConfirm}
        >
          Aprovar item
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmRefundRequestApproveDialog;
