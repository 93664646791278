import { StatusChip } from '@/react/components/refund/utils/status-chip';
import { statusMapRefundRequest } from '@/react/components/refund/utils/use-refund-status';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import * as React from 'react';

const dataFormatted = (refundRequest) => {
  return {
    created_at: new Date(refundRequest.created_at).toLocaleDateString(
      'pt-BR'
    ),
    refundable_amount: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(refundRequest.refundable_amount)),
  };
};

const refundResquest = ({ resourceApprovalUuid, refund_request }) => {
  const { created_at, refundable_amount } = dataFormatted(refund_request);
  const refundDetailslink = `empresa/gestao-de-reembolsos/${resourceApprovalUuid}/detalhes/${refund_request.uuid}`;

  return (
    <>
      <Grid
        item
        xs={12}
        sx={({ palette }) => ({
          border: `1px solid ${palette.neutral[300]}`,
          p: 2,
        })}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Data da solicitação
            </Typography>
            <Typography variant="body3">{created_at}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body3" fontWeight={600}>
              Reembolso solicitado
            </Typography>
            {refundable_amount && <Typography variant="body3">{refundable_amount}</Typography>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body3" fontWeight={600}>
              Situação
            </Typography>
            <StatusChip statusApproval={statusMapRefundRequest[refund_request.status]} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'flex-start',
                '@media (min-width: 900px)': {
                  justifyContent: 'flex-end',
                },
                pr: 2,
              }}
            >
              <Link href={refundDetailslink} variant="inherit" rel="noopener">
                Ver detalhes
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const RefundRequestList = ({ resourceApprovalUuid, refundRequests }) => {
  return (
    <Box
      height={'100%'}
      width={'100%'}
      display="flex"
      alignItems="center"
      p={2}
      my={2}
      sx={({ palette }) => ({
        minHeight: 156,
        border: `1px solid ${palette.neutral[300]}`,
        backgroundColor: `${palette.neutral[50]}`,
      })}
    >
      <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
          <Typography
            variant="body1"
            style={{ fontWeight: '600' }}
            color="primary.200"
          >
            Histórico de reembolsos
          </Typography>
        </Grid>
        {(refundRequests === null || refundRequests.length === 0) ? (
          <Typography variant="body3">
            Este usuário ainda não solicitou reembolsos para este item.
          </Typography>
        ) : (
          refundRequests.map((refund_request, index) => (
            <Grid item xs={12} key={index + nanoid()}>
              {refundResquest({ resourceApprovalUuid, refund_request })}
            </Grid> 
          ))
        )}
      </Grid>
    </Box>
  );
};
