import React from 'react';
import { FormControl, FormLabel, Typography } from '@mui/material';

import { Plan, PlanStatus } from '@/react/data/idp/interfaces';

interface DateDisplayProps {
  title: string;
  date: string;
}

const DateDisplay = ({ title, date }: DateDisplayProps) => (
  <FormControl fullWidth>
    <FormLabel sx={{ marginBottom: '0' }}>
      <Typography variant="body3">
        <b>{title}:</b> {date}
      </Typography>
    </FormLabel>
  </FormControl>
);

export const StartedAt = ({ plan }: { plan: Plan }) =>
  plan.status === PlanStatus.DRAFT ? null : (
    <DateDisplay title="Início" date={plan.startedAt} />
  );

export const EstimatedEndsAt = ({ plan }: { plan: Plan }) =>
  plan.status !== PlanStatus.IN_PROGRESS ? null : (
    <DateDisplay title="Fim estimado" date={plan.estimatedEndsAt} />
  );

export const EndedAt = ({ plan }: { plan: Plan }) =>
  plan.status !== PlanStatus.CLOSED ? null : (
    <DateDisplay title="Fim (data da conclusão)" date={plan.endedAt} />
  );
