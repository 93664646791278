import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { editingProfileSettings } from '@/react/components/profile-quiz/editing-profile-settings';
import { componentByKind } from '@/react/components/quiz/components/component-mapping';
import { ConfirmDialog } from '@/react/components/dialog/confirm-dialog.component';
import { useBootstrapData } from '@/react/data/bootstrap';
import { GOALS } from '@/react/data/recommendation/constants';
import { patchRecommendationProfile } from '@/react/data/recommendation';
import { Profile } from '@/react/data/recommendation/interfaces';
import { PROFILE_CONTEXTS } from './constants';

const contexts = {
  [PROFILE_CONTEXTS.GOAL]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ goal }) => ({ goal: goal[0] }),
    parseValue: (value) => value?.[0] || [],
    settings: editingProfileSettings.goals,
    title: 'Objetivo de desenvolvimento',
    validate: ({ goal, desired_job }): boolean => {
      const goalValue = goal?.[0];
      const hasDesiredJob = desired_job && Object.keys(desired_job).length;

      if (goalValue && goalValue !== GOALS.CARRER_CHANGE) {
        return true;
      } else if (hasDesiredJob) {
        return true;
      }

      return false;
    },
  },
  [PROFILE_CONTEXTS.SCHOLARITY]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ scholarity }) => ({
      scholarity: scholarity[0],
    }),
    parseValue: (value) => value?.[0] || [],
    settings: editingProfileSettings.scholarity,
    title: 'Formação',
    validate: ({ scholarity }): boolean => {
      const value = scholarity?.[0];
      return !!value;
    },
  },
  [PROFILE_CONTEXTS.CURRENT_JOB]: {
    getValueFromSettings: (settings) => settings.meta?.jobReloadedFromProfile,
    parseStateToProfile: ({ current_job }) => ({
      current_job: current_job,
    }),
    parseValue: (value) => [value?.id],
    settings: editingProfileSettings.current_job,
    title: 'Cargo atual',
    validate: ({ current_job }): boolean => {
      return current_job && !!Object.keys(current_job).length;
    },
  },
  [PROFILE_CONTEXTS.DESIRED_JOB]: {
    getValueFromSettings: (settings) => settings.meta?.jobReloadedFromProfile,
    parseStateToProfile: ({ desired_job }) => ({
      desired_job: desired_job,
    }),
    parseValue: (value) => [value?.id],
    settings: editingProfileSettings.desired_job,
    title: 'Cargo desejado',
    validate: ({ desired_job }): boolean => {
      return desired_job && !!Object.keys(desired_job).length;
    },
  },
  [PROFILE_CONTEXTS.SENIORITY]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ seniority }) => ({
      seniority: seniority[0],
    }),
    parseValue: (value) => value?.[0] || [],
    settings: editingProfileSettings.seniority,
    title: 'Senioridade',
    validate: ({ seniority }): boolean => {
      const value = seniority?.[0];
      return !!value;
    },
  },
  [PROFILE_CONTEXTS.COURSE_TYPES]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ course_types }) => ({
      course_types,
    }),
    parseValue: (value) => value || [],
    settings: editingProfileSettings.select_course_interests,
    title: 'Tipos de curso',
    validate: ({ course_types }): boolean => {
      return !!course_types?.length;
    },
  },
  [PROFILE_CONTEXTS.KNOWLEDGE_AREAS]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ knowledge_areas }, taxonomy) => ({
      knowledge_areas: taxonomy.knowledge_areas
        .filter((el) => knowledge_areas.map((k) => +k).includes(+el.id))
        .filter(Boolean),
    }),
    parseValue: (value) => value || [],
    settings: editingProfileSettings.select_most_interesting_themes,
    title: 'Temas',
    validate: ({ knowledge_areas }): boolean => {
      return !!knowledge_areas?.length;
    },
  },
  [PROFILE_CONTEXTS.LANGUAGES]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ languages }) => ({
      languages,
    }),
    parseValue: (value) => value || [],
    settings: editingProfileSettings.select_interesting_languages,
    title: 'Idiomas',
    validate: ({ languages }): boolean => {
      return !!languages?.length;
    },
  },
  [PROFILE_CONTEXTS.ADDRESS]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ address }) => {
      const [state, city] = address;

      return {
        address_state: state,
        address_city: city,
      };
    },
    parseValue: (value) => value || [],
    settings: editingProfileSettings.location_info,
    title: 'Localização',
    validate: ({ address }): boolean => {
      if (!address) return false;

      const [state, city] = address;
      return !!state && !!city;
    },
  },
  [PROFILE_CONTEXTS.MODALITIES]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ modalities }) => ({
      modalities,
    }),
    parseValue: (value) => value || [],
    settings: editingProfileSettings.select_best_learning_modality,
    title: 'Modalidade',
    validate: ({ modalities }): boolean => {
      return !!modalities?.length;
    },
  },
  [PROFILE_CONTEXTS.MONTHLY_BUDGET]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ monthly_budget }) => {
      const [min_budget_per_month, max_budget_per_month] = monthly_budget[0]
        ?.split('-')
        .map((value) => (value === 'null' ? null : value));

      return {
        min_budget_per_month,
        max_budget_per_month,
      };
    },
    parseValue: (value) => value || [],
    settings: editingProfileSettings.select_monthly_budget,
    title: 'Orçamento mensal',
    validate: ({ monthly_budget }): boolean => {
      return !!monthly_budget?.length;
    },
  },
  [PROFILE_CONTEXTS.WEEKLY_AVAILABILITY]: {
    getValueFromSettings: (settings) => settings.draftState.value,
    parseStateToProfile: ({ weekly_availability }) => {
      const [min_hours_available_per_week, max_hours_available_per_week] =
        weekly_availability[0]
          ?.split('-')
          .map((value) => (value > 0 ? Number(value) : null));

      return {
        min_hours_available_per_week,
        max_hours_available_per_week,
      };
    },
    parseValue: (value) => value || [],
    settings: editingProfileSettings.time_available_per_week,
    title: 'Disponibilidade semanal',
    validate: ({ weekly_availability }): boolean => {
      return !!weekly_availability?.length;
    },
  },
};

const BuildEditComponent = ({ settings, value, setValue, parseValue }) => {
  const componentKind = settings.kind;

  const props = {
    errors: [],
    meta: settings.meta,
    props: settings.props,
    setErrors: () => {},
    setMetadata: (meta) => {
      setValue(
        meta.jobLoadedFromSearch
          ? meta.jobLoadedFromSearch
          : meta.jobReloadedFromProfile
      );
    },
    setValue,
    value: parseValue(value),
    ...settings.props,
  };

  return React.createElement(componentByKind[componentKind], props);
};

export interface ProfileEditDialogProps
  extends Omit<
    React.ComponentProps<typeof ConfirmDialog>,
    'children' | 'onClose' | 'onConfirm'
  > {
  profile: Partial<Profile>;
  context: PROFILE_CONTEXTS;
  onClose: () => void;
}

export const ProfileEditDialog = ({
  context,
  onClose,
  profile,
  ...dialogProps
}: ProfileEditDialogProps) => {
  const { taxonomy } = useBootstrapData();
  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState<{ [k in PROFILE_CONTEXTS]: any }>(
    {} as any
  );
  const { patchProfile } = patchRecommendationProfile();

  const currentContext = contexts[context];
  const isCareerChange = (() => {
    if (context !== PROFILE_CONTEXTS.GOAL) return false;

    return (
      currentContext.parseValue(state[PROFILE_CONTEXTS.GOAL]) ===
      GOALS.CARRER_CHANGE
    );
  })();

  const onSave = async () => {
    setDisabled(true);
    let profileData = { ...profile };

    Object.keys(state).forEach((key) => {
      const { parseStateToProfile } = contexts[key];
      profileData = { ...profileData, ...parseStateToProfile(state, taxonomy) };
    });

    await patchProfile(profileData);
    setDisabled(false);

    onClose();
  };

  useEffect(() => {
    if (profile) {
      const settings = currentContext.settings({
        profile,
        taxonomy,
      });

      const editContextValue = currentContext.getValueFromSettings(settings);
      if (editContextValue) {
        setState({ ...state, [context]: editContextValue });
      }
    }
  }, [profile]);

  useEffect(() => {
    const context = PROFILE_CONTEXTS.DESIRED_JOB;
    if (isCareerChange) {
      const settings = contexts[context].settings({
        profile,
        taxonomy,
      });

      setState({
        ...state,
        desired_job: contexts[context].getValueFromSettings(settings),
      });
    } else {
      if (state[context]) {
        const newState = { ...state };
        delete newState[context];

        setState(newState);
      }
    }
  }, [isCareerChange]);

  const renderProfileComponent = () => {
    const questionSettings = currentContext.settings({
      profile,
      taxonomy,
    });

    return (
      <Stack spacing={3} data-testid={`profile-edit-dialog-${context}`}>
        <BuildEditComponent
          value={state[context]}
          setValue={(newValue) => {
            setState({ ...state, [context]: newValue });
          }}
          settings={questionSettings}
          parseValue={currentContext.parseValue}
        />

        {isCareerChange &&
          (() => {
            const context = PROFILE_CONTEXTS.DESIRED_JOB;
            const settings = contexts[context].settings({
              profile,
              taxonomy,
            });

            return (
              <>
                <BuildEditComponent
                  settings={settings}
                  value={state[context]}
                  setValue={(value) => setState({ ...state, [context]: value })}
                  parseValue={contexts[context].parseValue}
                />
              </>
            );
          })()}
      </Stack>
    );
  };

  return (
    <ConfirmDialog
      actionLabels={{
        cancel: 'Cancelar',
        confirm: 'Salvar',
      }}
      disableConfirmButton={!currentContext.validate(state) || disabled}
      disableEscapeKeyDown={false}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      onConfirm={onSave}
      open={!!context}
      title={currentContext.title}
      {...dialogProps}
    >
      <Box sx={{ pt: 2 }}>{renderProfileComponent()}</Box>
    </ConfirmDialog>
  );
};
