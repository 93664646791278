import { FalconApiService } from '@/services/falcon-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubsidiesService {
  readonly router: string = 'subsidy/api';

  eligible_for_request_refund: boolean = false;

  constructor(private apiService: FalconApiService) {
    this.fetchPolicies();
  }

  async fetchPolicies() {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(`${this.router}/policies`)
        .subscribe(
          (response) => {
            this.eligible_for_request_refund = response.policies !== null || response.policies.length > 0;
            resolve(response);
          },
          (err) => {
            reject(`Error fetching subsidy policies: ${err}`);
          }
        );
    });
  }
}
