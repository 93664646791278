import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  Skeleton
} from '@mui/material';
import { useCheckResourceEligibility } from '@/react/data/subsidy/useCheckResourceEligibility';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import {
  possibleFormStepIds,
  formatCreateResourceApproval,
  handleGoBack,
 } from '@/react/components/refund/refund-request/description-step/refund-summary/utils';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { getSvg } from 'src/app/utils/helpers';

export const ResourceApprovalCheckEligibility = () => {
  const [isEligible, setIsEligible] = useState<boolean | null>(null);
  const { subsidyPolicy } = useRefundRequest();
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const filledStep = possibleFormStepIds.find((stepId) => wizardValues[stepId]);
  const formValues = wizardValues[filledStep] ?? {};
  const formattedResourceApproval = formatCreateResourceApproval({
    formValues,
    policyUuid: subsidyPolicy?.uuid,
    formType: filledStep,
  });
  const checkResourceEligibility = useCheckResourceEligibility(formattedResourceApproval);

  useEffect(() => {
    const checkEligibility = async () => {
      const eligibility = await checkResourceEligibility();
      setIsEligible(eligibility);
    };

    checkEligibility();
  }, [checkResourceEligibility]);

  useEffect(() => {
    if (isEligible === true) {
      wizardActions.navigate(WizardStepIds.SUMMARY);
    }
  }, [isEligible, wizardActions]);

  if (isEligible === null) {
    return (
      <Box>
        <Skeleton height={160} variant="rectangular" width="100%" />
      </Box>
    )
  }
  
  return (
    <>
      <Box>
        <Box
          component="img"
          src={getSvg('resource_without_eligibility.svg')}
          sx={{ width: 128, marginBottom: 3 }}
        />
        <Typography
          variant="h6"
          color="primary.900"
          sx={{ marginBottom: 1 }}>
          Reembolso indisponível
        </Typography>
        <Typography variant="body3">
          O item que você solicitou não é contemplado pelas suas políticas, revise o item solicitado e suas políticas para garantir que o item possa ser reembolsado.
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }}/>
      <Stack
        direction="row"
        justifyContent={{ xs: 'space-between', sm: 'right' }}
        spacing={2}
        marginTop={3}
        sx={{
          flexWrap: { xs: 'wrap-reverse', md: 'nowrap' },
          '& .MuiButton-root': {
            width: '100%',
            maxWidth: { md: '148px' },
            minWidth: '148px',
          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleGoBack(wizardActions, wizardValues)}
        >
          Voltar
        </Button>
      </Stack>
    </>
  );
};
