import * as React from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid2 as Grid,
  Link,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { MarkdownRenderer } from '@/react/components/shared/markdown-renderer';

import readme from '../../../../../../README.md';
import example from './example.md';

const ReactMarkdownExample = ({ children: markdown }) => {
  const [customComponents, setCustomComponents] = React.useState(undefined);

  const handleAlternateStyle = () => {
    if (customComponents) {
      setCustomComponents(undefined);
    } else {
      setCustomComponents({
        h1: ({ children }) => (
          <Alert
            icon={false}
            severity="success"
            variant="filled"
            sx={{ mb: 2 }}
          >
            <Typography component="h4" variant="h4" color="neutral.50">
              {children} (override)
            </Typography>
          </Alert>
        ),
        h2: ({ children }) => (
          <Alert icon={false} severity="info" variant="filled">
            <Typography component="h5" variant="h5" color="neutral.50">
              {children} (override)
            </Typography>
          </Alert>
        ),
      });
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="body2">package:</Typography>
        <Link href="https://github.com/remarkjs/react-markdown">
          remarkjs/react-markdown
        </Link>
      </Box>
      <Box>
        <FormControlLabel
          control={<Switch onChange={handleAlternateStyle} />}
          label="Override style (custom components)"
        />
      </Box>
      <Card>
        <CardContent>
          <MarkdownRenderer
            componentsMap={customComponents}
            markdown={markdown}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

const TextEditor = ({
  content,
  onChange,
}: {
  content: string;
  onChange: (value: string) => void;
}) => {
  const [markdownFile, setMarkdownFile] = React.useState('readme');

  const handleChangeFile = (
    event: React.MouseEvent<HTMLElement>,
    file: string | null
  ) => {
    setMarkdownFile(file);
  };

  React.useEffect(() => {
    if (markdownFile === 'readme') {
      onChange(readme);
    } else {
      onChange(example);
    }
  }, [markdownFile]);

  return (
    <Stack spacing={3}>
      <ToggleButtonGroup
        color="primary"
        value={markdownFile}
        exclusive
        onChange={handleChangeFile}
      >
        <ToggleButton value="readme">README.md</ToggleButton>
        <ToggleButton value="example">example.md</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ position: 'relative' }}>
        <KeyboardIcon
          sx={{
            fontSize: 34,
            position: 'absolute',
            right: '15px',
            top: '12px',
            zIndex: 1,
          }}
        />
        <TextField
          label="Markdown"
          fullWidth
          id="markdown"
          multiline
          placeholder="Write markdown here"
          minRows={5}
          maxRows={30}
          variant="outlined"
          value={content}
          onChange={(e) => onChange(e.target.value)}
        />
      </Box>
    </Stack>
  );
};

export const MarkdownExample = () => {
  const [markdownContent, setMarkdownContent] = React.useState(readme);

  return (
    <Container disableGutters fixed maxWidth="xl">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 8 }}>
          <Box py={3}>
            <ReactMarkdownExample>{markdownContent}</ReactMarkdownExample>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Box py={3}>
            <TextEditor
              content={markdownContent}
              onChange={setMarkdownContent}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
