import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';

import { useNotification } from '@/react/components/notification';
import { useGetPlanKey } from '@/react/data/idp/usePlan';
import { ActivityStatuses } from './interfaces';
import { IdpClient } from './idp-client';
import { Plan } from './interfaces';

export const mutationKey = ['updatePlanActivityStatus'];

const defaultMessages = {
  error: 'Erro ao alterar status da atividade.',
  success: 'Status da atividade alterado com sucesso.',
};

export function useUpdatePlanActivityStatus(planId: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  const { mutateAsync: updatePlanActivityStatus, isPending } = useMutation({
    mutationKey: [...mutationKey],
    mutationFn: ({
      activityId,
      status,
    }: {
      activityId: string;
      status: ActivityStatuses;
    }) => {
      return IdpClient.updatePlanActivityStatus({ planId, activityId, status });
    },
    onSuccess: async (_, variables) => {
      const planData = queryClient.getQueryData<Plan>([
        ...useGetPlanKey,
        planId,
      ]);

      if (planData) {
        queryClient.setQueryData<Plan>([...useGetPlanKey, planId], {
          ...planData,
          activities: planData.activities.map((currentActivity) =>
            currentActivity.id === variables.activityId
              ? { ...currentActivity, status: variables.status }
              : currentActivity
          ),
        });
      }

      toast({
        content: defaultMessages.success,
        type: 'success',
      });
    },
    onError: (error: AxiosError) => {
      Sentry.captureException(error);

      toast({
        content: defaultMessages.error,
        type: 'error',
      });
    },
  });

  return {
    updatePlanActivityStatus,
    isPending,
  };
}
