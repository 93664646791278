import * as React from 'react';

import { SectionHeader } from '@/react/components/idp/shared';

import { Alert, Box, Button, Grid2 as Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { PageContainer } from '@/react/components';
import { ToggleButtonGroup } from '@/react/components/toggle-buttons';
import { useCreatePlan } from '@/react/data/idp';
import { CreatePlanWizardSteps } from '../create-plan';
import { useCreateRecommendation } from '@/react/data/idp/useCreateRecommendation';
import { RecommendationOption } from './recommendation-option.component';
import { ContentBox } from '@/react/components/idp/shared/content-box.component';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import ModeIcon from '@mui/icons-material/Mode';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const RecommendationTypes = {
  AI_RECOMMENDATION: 'aiRecommendation',
  NO_RECOMMENDATION: 'noRecommendation',
} as const;

export const validationSchema = z.object({
  recommendationType: z
    .string()
    .min(1, { message: 'Selecione uma opção para continuar.' }),
});

const handleBackToPlanList = () => {
  window.location.assign('/pdi/planos');
};

export const RecommendationTypeSelect = () => {
  const { createRecommendation } = useCreateRecommendation({
    onError: handleBackToPlanList,
    onSuccess: (recommendation) => {
      window.location.assign(`/pdi/planos/recomendacoes/${recommendation.id}`);
    },
  });
  const defaultValues = {
    recommendationType: '',
  };

  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { createPlan, isPending } = useCreatePlan();

  const handleChange = (_, value) => {
    if (value !== null) {
      setValue('recommendationType', value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const handleCreatePlan = async () => {
    await createPlan(
      { title: null },
      {
        onSuccess: (plan) => {
          window.location.assign(`/pdi/planos/${plan.id}`);
        },
      }
    );
  };

  const handleNext = async (data) => {
    if (data.recommendationType === RecommendationTypes.NO_RECOMMENDATION) {
      handleCreatePlan();
      return;
    }

    await createRecommendation(CreatePlanWizardSteps.DURATION);
  };

  const { recommendationType } = watch();

  const iconStyles = (theme) => ({
    color: theme.palette.primary[200],
    height: 28,
    width: 28,
    margin: 'auto',
  });

  return (
    <PageContainer maxWidth="lg">
      <Box sx={{ py: 3 }}>
        <SectionHeader
          handleBackNavigation={handleBackToPlanList}
          title="Voltar"
        />
        <ContentBox mt={2} p={3}>
          <Typography variant="h6" color="primary.200">
            Como você gostaria de criar seu plano?
          </Typography>

          <Box mt={3}>
            <form onSubmit={handleSubmit(handleNext)}>
              <ToggleButtonGroup
                aria-label="recommendation type"
                color="primary"
                exclusive
                fullWidth
                onChange={handleChange}
                size="large"
                value={recommendationType}
              >
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <RecommendationOption
                      icon={<AutoAwesomeIcon sx={iconStyles} />}
                      value={RecommendationTypes.AI_RECOMMENDATION}
                      title="Recomendado por Inteligência Artificial"
                      description="Defina seus objetivos e deixe nossa IA criar um plano personalizado para você. Após a recomendação, você pode ajustar e personalizar o plano como desejar."
                      selected={
                        recommendationType ===
                        RecommendationTypes.AI_RECOMMENDATION
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid size={{ xs: 12, md: 6 }}>
                    <RecommendationOption
                      icon={<ModeIcon sx={iconStyles} />}
                      value={RecommendationTypes.NO_RECOMMENDATION}
                      title="Sem Recomendação"
                      description="Crie seu plano manualmente, sem a ajuda da IA. Você terá total controle sobre todas as etapas do plano."
                      selected={
                        recommendationType ===
                        RecommendationTypes.NO_RECOMMENDATION
                      }
                      onChange={handleChange}
                    />
                  </Grid>

                  {formState.isSubmitted &&
                    formState.errors['recommendationType'] && (
                      <Grid size={12}>
                        <Alert severity="error">
                          {
                            formState.errors['recommendationType']
                              .message as string
                          }
                        </Alert>
                      </Grid>
                    )}
                </Grid>
              </ToggleButtonGroup>

              <Grid container pt={3} justifyContent="flex-end">
                <WizardStepActions>
                  <Button
                    color="primary"
                    loading={isPending}
                    onClick={() => {
                      trigger();
                    }}
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    Continuar
                  </Button>
                </WizardStepActions>
              </Grid>
            </form>
          </Box>
        </ContentBox>
      </Box>
    </PageContainer>
  );
};
