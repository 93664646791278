import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const Styled = {
  OutlinedInput: styled(OutlinedInput)(({ theme }) => ({
    backgroundColor: theme.palette.neutral[50],
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 260,
    },
  })),
};

export const SearchInput = ({ currentUrl = '' }) => {
  const [value, setValue] = React.useState('');

  useEffect(() => {
    const queryString = currentUrl.split('?').at(1) || '';
    const searchTerm = new URLSearchParams(queryString).get('curso') || '';

    setValue(searchTerm);
  }, [currentUrl]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClear = () => {
    setValue('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSearch = () => {
    if (value) {
      window.location.assign(`/cursos/novo_catalogo?terms=${value}`);
    }
  };

  return (
    <Styled.OutlinedInput
      sx={{ width: 250 }}
      endAdornment={
        <InputAdornment position="end">
          {value && (
            <IconButton onClick={handleClear} edge="end">
              <CloseRoundedIcon />
            </IconButton>
          )}
          <IconButton onClick={handleSearch} edge="end">
            <SearchRoundedIcon />
          </IconButton>
        </InputAdornment>
      }
      inputProps={{ 'aria-label': 'search' }}
      placeholder="Buscar curso"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size="small"
      value={value}
    />
  );
};
