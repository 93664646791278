import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'component-react-wrapper',
  template: `
    <ng-react-mui
      [component]="Component"
      [props]="componentProps"
    ></ng-react-mui>
  `,
})
export class ReactWrapperComponent implements OnInit {
  Component = null;
  componentProps: any = {};

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { reactComponent, componentProps } = this.route.snapshot.data;

    this.Component = reactComponent;
    this.componentProps = componentProps;
  }
}
