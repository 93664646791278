import {
  ActivityResponseType,
  ActivityStatuses,
  RecommendedActivity,
} from '@/react/data/idp/activities/interfaces';
import { Theme } from '@mui/material';
import { CatalogCourse } from '../../catalog/interfaces';

export default class Activity {
  description: string;
  id: string;
  planId: string;
  recommended_activity?: RecommendedActivity;
  resource_data?: CatalogCourse;
  recommendedData?: string;
  recommendedId?: string;
  resourceId?: number;
  resourceUrl?: string;
  status: ActivityStatuses;
  title: string;

  constructor(args: Activity) {
    const {
      description,
      id,
      planId,
      recommendedData,
      recommendedId,
      resourceId,
      resourceUrl,
      status,
      title,
      recommended_activity,
      resource_data,
    } = args;

    this.description = description;
    this.id = id;
    this.planId = planId;
    this.recommendedData = recommendedData;
    this.recommendedId = recommendedId;
    this.resourceId = resourceId;
    this.resourceUrl = resourceUrl;
    this.status = status;
    this.title = title;
    this.recommended_activity = recommended_activity;
    this.resource_data = resource_data;
  }

  static fromServerPayload(payload: ActivityResponseType): Activity {
    return new Activity({
      description: payload.description,
      id: payload.id,
      planId: payload.plan_id,
      recommendedData: payload.recommended_data,
      recommendedId: payload.recommended_id,
      resourceId: payload.resource_id,
      resourceUrl: payload.resource_url,
      status: payload.status,
      title: payload.title,
      recommended_activity: payload.recommended_activity,
      resource_data: payload.resource_data,
    });
  }

  static getAmountOfActivitiesByStatus(
    activities: Activity[]
  ): Record<ActivityStatuses, { quantity: number; percentage: number }> {
    const totalActivities = activities.length;

    const initialStatusCounts: Record<
      ActivityStatuses,
      { quantity: number; percentage: number }
    > = {
      [ActivityStatuses.IN_PROGRESS]: { quantity: 0, percentage: 0 },
      [ActivityStatuses.COMPLETED]: { quantity: 0, percentage: 0 },
      [ActivityStatuses.PENDING]: { quantity: 0, percentage: 0 },
    };

    const statusCounts = activities.reduce((acc, activity) => {
      if (!acc[activity.status]) {
        acc[activity.status] = { quantity: 0, percentage: 0 };
      }
      acc[activity.status].quantity++;
      return acc;
    }, initialStatusCounts);

    for (const status in statusCounts) {
      statusCounts[status].percentage =
        (statusCounts[status].quantity / totalActivities) * 100;
    }

    return statusCounts;
  }

  static getActivityStatusColor(
    status: ActivityStatuses,
    themePalette: Theme['palette']
  ): {
    backgroundColor: string;
    color: string;
  } {
    let backgroundColor;
    let color;

    switch (status) {
      case ActivityStatuses.COMPLETED:
        backgroundColor = themePalette.success[700];
        color = themePalette.neutral[50];
        break;
      case ActivityStatuses.IN_PROGRESS:
        backgroundColor = themePalette.info.main;
        color = themePalette.neutral[50];
        break;
      case ActivityStatuses.PENDING:
        backgroundColor = themePalette.warning[100];
        color = themePalette.neutral[900];
        break;
    }

    return {
      backgroundColor,
      color,
    };
  }
}
