import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import {
  ResourceTypes,
  EligibleResourceType,
} from '@/react/data/subsidy/interfaces';

export const possibleFormStepIds = [
  WizardStepIds.CATALOG_FORM,
  WizardStepIds.EXTERNAL_COURSE_FORM,
  WizardStepIds.EXTERNAL_BOOK_FORM,
  WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
  WizardStepIds.EXTERNAL_OTHER_FORM,
];

const stepIdToResourceTypeMapping = {
  [WizardStepIds.EXTERNAL_BOOK_FORM]: ResourceTypes.BOOK,
  [WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM]:
    ResourceTypes.EVENT_OR_LECTURE,
  [WizardStepIds.EXTERNAL_OTHER_FORM]: ResourceTypes.OTHERS,
};

export const formatCreateResourceApproval = ({ formValues, policyUuid, formType }) => {
  if (formType === WizardStepIds.CATALOG_FORM) {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues?.amount,
        data: {
          course: {
            course_type: formValues?.courseType,
            duration_type:
              formValues?.durationType?.trim() !== ''
                ? formValues?.durationType
                : null,
            duration: formValues?.duration ?? null,
            id: formValues?.course?.id,
            institution_name: formValues?.institution?.name,
            modality: formValues?.modality,
            offer_identifier: formValues?.offerIdentifier,
            period: formValues?.period?.value,
            source: ResourceCourseSources.CATALOG,
            workload: formValues?.workload ?? null,
            ...(formValues.campus &&
              formValues.city &&
              formValues.state && {
                address: {
                  campus: `${formValues?.campus?.label} - ${formValues?.campus?.value}`,
                  city: formValues?.city?.value,
                  state: formValues?.state?.value,
                },
              }),
          },
        },
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues?.course?.label,
        request_reason:
          formValues?.requestReason?.trim() !== ''
            ? formValues?.requestReason
            : null,
        sale_type: formValues?.salesType,
        type: ResourceTypes.COURSE,
      },
    };
  } else if (formType === WizardStepIds.EXTERNAL_COURSE_FORM) {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        data: {
          course: {
            course_type: formValues.courseType,
            duration_type:
              formValues?.durationType?.trim() !== ''
                ? formValues?.durationType
                : null,
            duration: formValues.duration ?? null,
            id: formValues.course?.id ?? null,
            institution_name: formValues.institution,
            modality: formValues.modality,
            period: formValues.period?.trim() !== '' ? formValues.period : null,
            source: ResourceCourseSources.EXTERNAL,
            workload: formValues.workload ?? null,
            ...(formValues.campus &&
              formValues.campus?.trim() !== '' &&
              formValues.city &&
              formValues.city?.trim() !== '' &&
              formValues.state &&
              formValues.state?.trim() !== '' && {
                address: {
                  campus: `${formValues.campus} - ${formValues.campus}`,
                  city: formValues.city,
                  state: formValues.state,
                },
              }),
          },
        },
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues.course,
        request_reason:
          formValues?.requestReason?.trim() !== ''
            ? formValues?.requestReason
            : null,
        sale_type: formValues.saleType,
        type: ResourceTypes.COURSE,
      },
    };
  } else {
    return {
      policy: {
        uuid: policyUuid,
      },
      resource: {
        amount: formValues.amount,
        link: formValues?.link?.trim() !== '' ? formValues?.link : null,
        name: formValues.name,
        request_reason:
          formValues?.requestReason?.trim() !== ''
            ? formValues?.requestReason
            : null,
        sale_type: formValues.salesType,
        type: stepIdToResourceTypeMapping[formType],
      },
    };
  }
};

export const formatUpdateResourceApproval = ({ formValues, courseId, formType }) => {
  if (formType === WizardStepIds.EXTERNAL_COURSE_FORM) {
    return {
      sale_type: formValues.saleType,
      name: formValues.course,
      type: ResourceTypes.COURSE,
      amount: formValues.amount,
      request_reason:
        formValues?.requestReason?.trim() !== ''
          ? formValues?.requestReason
          : null,
      link: formValues?.link?.trim() !== '' ? formValues?.link : null,

      data: {
        course: {
          id: courseId ?? null,
          institution_name: formValues.institution,
          workload: formValues.workload ?? null,
          course_type: formValues.courseType,
          modality: formValues.modality,
          duration: formValues.duration ?? null,
          duration_type:
            formValues?.durationType?.trim() !== ''
              ? formValues?.durationType
              : null,
          period: formValues.period?.trim() !== '' ? formValues.period : null,
          source: ResourceCourseSources.EXTERNAL,
          ...(formValues.campus &&
            formValues.campus?.trim() !== '' &&
            formValues.city &&
            formValues.city?.trim() !== '' &&
            formValues.state &&
            formValues.state?.trim() !== '' && {
              address: {
                campus: `${formValues.campus} - ${formValues.campus}`,
                city: formValues.city,
                state: formValues.state,
              },
            }),
        },
      },
    };
  } else {
    return {
      amount: formValues.amount,
      link: formValues?.link?.trim() !== '' ? formValues?.link : null,
      name: formValues.name,
      request_reason:
        formValues?.requestReason?.trim() !== ''
          ? formValues?.requestReason
          : null,
      sale_type: formValues.salesType,
      type: stepIdToResourceTypeMapping[formType],
    };
  }
};

export const handleGoBack = (wizardActions, wizardValues) => {
  if (wizardValues.itemSourceForm === EligibleResourceType.CATALOG) {
    wizardActions.navigate(WizardStepIds.CATALOG_FORM);
  } else {
    const externalFormMapping = {
      [ResourceTypes.COURSE]: WizardStepIds.EXTERNAL_COURSE_FORM,
      [ResourceTypes.BOOK]: WizardStepIds.EXTERNAL_BOOK_FORM,
      [ResourceTypes.EVENT_OR_LECTURE]:
        WizardStepIds.EXTERNAL_EVENT_OR_LECTURE_FORM,
      [ResourceTypes.OTHERS]: WizardStepIds.EXTERNAL_OTHER_FORM,
    };

    wizardActions.navigate(externalFormMapping[wizardValues.resourceType]);
  }
};
