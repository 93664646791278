import axios from 'axios';

import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';

import {
  CatalogSearchRequest,
  CatalogSearchResponse,
  ProductOfferSearchResult,
  ProductCourseSearchResult,
  CourseTypes,
  Institution,
} from './interfaces';

export class CatalogClient {
  static BASE_URL = CONST.API_ENDPOINT;

  constructor() {}

  static client = withAuthorization(
    axios.create({
      baseURL: CatalogClient.BASE_URL,
    })
  );

  static getCatalog = async (
    options: {
      requestParams?: CatalogSearchRequest;
      signal?: AbortSignal;
    } = {}
  ): Promise<CatalogSearchResponse> => {
    const { requestParams, signal } = options;
    const url = `/catalog/search`;
    const res = await CatalogClient.client.post(
      url,
      { ...requestParams },
      { signal }
    );

    return res.data;
  };

  static getCourseById = async (
    courseId: number
  ): Promise<ProductCourseSearchResult> => {
    const url = `/catalog/course/${courseId}`;
    const res = await CatalogClient.client.get(url);

    return res.data;
  };

  static getCourseFiltersById = async ({
    courseId,
    queryParams,
  }: {
    courseId: number;
    queryParams: string;
  }): Promise<ProductOfferSearchResult> => {
    const url = `/catalog/course/${courseId}/offers${queryParams}`;
    const res = await CatalogClient.client.get(url);

    return res.data;
  };

  static getInstitutionByCourseType = async ({
    courseType,
  }: {
    courseType: CourseTypes;
  }): Promise<Institution[]> => {
    const url = `/course_types/${courseType}/institutions`;
    const res = await CatalogClient.client.get(url);
    return res.data.data.institutions;
  };
}
