import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { getSvg } from '@/utils/helpers';
import { CatalogCourse } from '@/react/data/catalog/interfaces';
import { useRecommendedActivities } from '@/react/data/recommendation/useRecommendedActivities';
import { SelectedCourseCard } from '../shared/selected-course-card';

interface RecommendedCoursesDialogProps {
  activityId?: string;
  onClose: () => void;
  onSave?: (course: CatalogCourse) => void;
  open: boolean;
}

const Styled = {
  ListWrapper: styled(Stack)(({ theme }) => ({
    maxHeight: 420,
    overflow: 'auto',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    //TODO: implement global scrollbar style
    '&::-webkit-scrollbar': { width: 4 },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[200],
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary[900],
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  })),
};

const Loading = () => (
  <Stack spacing={1}>
    {[...Array(3)].map((_, index) => (
      <Skeleton
        key={index}
        data-testid="skeleton"
        height={100}
        variant="rounded"
        width="100%"
      />
    ))}
  </Stack>
);

const EmptyState = () => (
  <Box>
    <img src={getSvg('empty_state_catalog.svg')} role="img" width={150} />
    <Typography variant="h6">Não foi possível carregar os cursos</Typography>
    <Typography variant="body2">
      Ocorreu um problema ao buscar as recomendações. Tente novamente mais
      tarde, ou explore outros cursos.
    </Typography>
  </Box>
);

export const RecommendedCoursesDialog = ({
  activityId,
  onClose,
  onSave,
  open,
}: RecommendedCoursesDialogProps) => {
  const { recommendedActivity, isFetching } = useRecommendedActivities({
    activityId,
    enabled: open,
  });
  const [selectedCourse, setSelectedCourse] = useState<CatalogCourse | null>(
    null
  );

  const courses = recommendedActivity?.activity_based_course?.catalog_courses;

  const handleSave = () => {
    if (onSave) {
      onSave(selectedCourse);
    }
    handleClose();
  };

  const handleClose = () => {
    setSelectedCourse(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          color: ({ palette }) => palette.grey[500],
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle mt={3} mb={-2}>
        Incluir curso
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Confira as recomendações de cursos e selecione aquele que melhor se
          encaixa no seu plano.
        </Typography>
        <Styled.ListWrapper>
          {isFetching && <Loading />}
          {!isFetching && !courses?.length && <EmptyState />}
          {!isFetching &&
            courses?.length > 0 &&
            courses.map((course) => (
              <SelectedCourseCard
                selected={selectedCourse?.id === course.id}
                key={course.id}
                course={course}
                onToggle={() => setSelectedCourse(course)}
              />
            ))}
        </Styled.ListWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
