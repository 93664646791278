import * as React from 'react';
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';

import { Activity, ActivityStatusLabel } from '@/react/data/idp/interfaces';

export type ActivitiesListProps = {
  activities: Activity[];
  limit?: number;
};

export const Loading = () => {
  return (
    <Stack gap="2px">
      <Skeleton variant="rectangular" height={30} width="100%" />
      <Skeleton variant="rectangular" height={30} width="100%" />
      <Skeleton variant="rectangular" height={30} width="100%" />
      <Skeleton variant="rectangular" height={30} width="100%" />
    </Stack>
  );
};

export const ActivitiesList = ({
  activities: allActivities,
  limit = Infinity,
}: ActivitiesListProps) => {
  const theme = useTheme();
  const activities = allActivities.slice(0, limit);
  const hiddenActivitiesCount =
    (limit > 0 && allActivities.length - activities.length) || 0;

  if (!activities.length) {
    return null;
  }

  const planId = activities[0].planId;

  return (
    <Stack spacing={1} alignItems="start">
      <List
        disablePadding
        sx={{
          bgColor: 'background.paper',
          borderColor: 'divider',
          borderStyle: 'solid',
          borderWidth: 1,
          borderBottomWidth: 0,
          width: '100%',
        }}
      >
        {activities.map((activity) => {
          const { backgroundColor, color } = Activity.getActivityStatusColor(
            activity.status,
            theme.palette
          );

          return (
            <ListItem
              disablePadding
              divider
              key={activity.id}
              secondaryAction={
                <Chip
                  label={ActivityStatusLabel[activity.status]}
                  size="small"
                  style={{ backgroundColor, color }}
                />
              }
            >
              <ListItemButton
                LinkComponent="a"
                href={`/pdi/planos/${activity.planId}/atividades/${activity.id}`}
              >
                <ListItemText
                  primary={
                    <Typography variant="body3">{activity.title}</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {!!hiddenActivitiesCount && (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Typography color="neutral.500" variant="caption">
            +{hiddenActivitiesCount} atividades
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
