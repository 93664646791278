import * as React from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';

import PlanRecommendationLoading from '@/react/assets/gifs/loading-walk.gif';
import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { StepsIds } from '@/react/components/idp/create-plan/create-plan.component';
import { useGetProfileBasedRecommendationActivities } from '@/react/data/idp';
import { ProfileBasedRecommendationActivitiesStatus } from '@/react/data/idp/profile/constants';
import { ContentBox } from '../shared/content-box.component';

type DescriptionSectionProps = {
  title: string;
  description: string;
  button?: ButtonProps;
};

const DescriptionSection = ({
  title,
  description,
  button,
}: DescriptionSectionProps) => (
  <>
    <Box mt={3}>
      <Typography variant="h6">{title}</Typography>
    </Box>
    <Box mt={1}>
      <Typography variant="body3">{description}</Typography>
    </Box>

    {button && (
      <Box mt={3}>
        <Button
          role="button"
          onClick={button.onClick}
          color="primary"
          variant={button.variant}
        >
          {button.children}
        </Button>
      </Box>
    )}
  </>
);
export const GenerateRecommendation: React.ElementType<
  WizardStepComponent & {
    profileBasedRecommendationId: string;
    generateRecommendation: (stepId: StepsIds, skills: any) => Promise<void>;
  }
> = ({ profileBasedRecommendationId, generateRecommendation, onNext }) => {
  const { recommendedActivities, status, refetch } =
    useGetProfileBasedRecommendationActivities(profileBasedRecommendationId);

  const { values } = useWizard();

  const skillSelection = values[CreatePlanWizardSteps.SKILL_SELECTION];

  const generateAgain = async () => {
    await generateRecommendation(
      StepsIds.GENERATE_RECOMMENDATION,
      skillSelection
    );
    refetch();
  };

  React.useEffect(() => {
    if (
      !!onNext &&
      status === ProfileBasedRecommendationActivitiesStatus.SUCCESS
    ) {
      onNext({ recommendedActivities });
    }
  }, [status]);

  const sections = {
    [ProfileBasedRecommendationActivitiesStatus.IN_PROGRESS]: {
      title: 'Gerando recomendações...',
      description:
        'Estamos processando suas respostas para criar um plano alinhado ao seu objetivo e às habilidades que você deseja desenvolver.',
    },

    [ProfileBasedRecommendationActivitiesStatus.FAIL]: {
      title: 'Não conseguimos gerar suas recomendações.',
      description:
        'Não concluímos as suas recomendações, você pode “Gerar novamente” ou voltar mais tarde para acessar suas recomendações.',
      button: {
        onClick: generateAgain,
        children: 'Gerar novamente',
        variant: 'contained',
      },
    },
  };

  const descriptionSectionProps = sections[status];

  return (
    <ContentBox textAlign="center" mt={2} p={3}>
      <Box
        component="img"
        src={PlanRecommendationLoading}
        sx={{ width: 128 }}
      />

      <DescriptionSection {...descriptionSectionProps} />
    </ContentBox>
  );
};
