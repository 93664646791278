import * as React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  Card,
  CardContent,
  CardActionArea,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { getRecommendationProfile } from '@/react/data/recommendation';
import { useProfile } from '@/react/components/idp/create-plan/state';
import { formatDuration } from '@/react/utils/time';
import { formatMoney } from '@/react/utils/money';
import {
  LanguagesLabels,
  ScholaritiesLabels,
  SenioritiesLabels,
  GOALS,
} from '@/react/data/recommendation/constants';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { Modalities, Profile } from '@/react/data/recommendation/interfaces';
import { CourseTypesLabels } from '@/react/data/catalog/constants';
import { ProfileEditDialog } from '@/react/components/idp/profile-edit';
import { ContentBox } from '@/react/components/idp/shared/content-box.component';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} defaultExpanded disableGutters {...props} />
))(() => ({
  backgroundColor: 'transparent',

  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: 20 }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const Section = ({
  children,
  id,
  title,
}: {
  children: React.ReactNode;
  id: string;
  title: string;
}) => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls={`section-${id}-content`}
        id={`section-${id}-header`}
      >
        <Typography variant="body3" fontWeight="semibold">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails data-testid={`${id}-section`}>
        <Stack spacing={2}>{children}</Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const SectionSkeleton = () => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <Skeleton width="25%" />
      </Typography>
      <Stack spacing={2}>
        <Skeleton variant="rounded" height={80} />
      </Stack>
    </Box>
  );
};

const ProfileItem = ({
  children,
  id,
  title,
}: {
  children?: React.ReactNode;
  id: string;
  title: string;
}) => {
  const { actions: profileActions } = useProfile();
  const empty = !children;

  const handleClick = () => {
    profileActions.edit(id);
  };

  const Blank = () => (
    <Typography component="p" variant="body3">
      Adicionar
    </Typography>
  );

  return (
    <Card
      sx={(theme) => ({
        ...(empty && {
          backgroundColor: theme.palette.warning[100],
          borderColor: theme.palette.warning.dark,
        }),
      })}
    >
      <CardActionArea onClick={handleClick}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            width: '100%',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              component="p"
              variant="body3"
              fontWeight="bold"
              sx={{ mb: 1 }}
            >
              {title}
            </Typography>
            {children || <Blank />}
          </Box>
          <Box>
            {empty ? (
              <AddIcon fontSize="small" />
            ) : (
              <ChevronRightRoundedIcon fontSize="small" />
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const Chips = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      {children}
    </Stack>
  );
};

const ObjectivesSection = ({ profile }: { profile: Partial<Profile> }) => {
  const goalDescription = (() => {
    switch (profile.goal) {
      case GOALS.CARRER_DEVELOPMENT:
        return `Evoluir como ${profile.current_job?.name_pt}`;
      case GOALS.CARRER_CHANGE:
        return `Mudança de carreira para ${profile.desired_job?.name_pt}`;
      default:
        return 'Desenvolvimento pessoal';
    }
  })();

  return (
    <Section id="objectives" title="Objetivos">
      <ProfileItem id="goal" title="Objetivo de desenvolvimento">
        {profile.goal && (
          <Typography component="p" variant="body3">
            {goalDescription}
          </Typography>
        )}
      </ProfileItem>
    </Section>
  );
};

const ExperiencesSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="experiences" title="Experiências">
      <ProfileItem id="scholarity" title="Formação">
        {profile.scholarity && (
          <Typography component="p" variant="body3">
            {ScholaritiesLabels[profile.scholarity]}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="current_job" title="Cargo atual">
        {profile.current_job.name_pt && (
          <Typography component="p" variant="body3">
            {profile.current_job.name_pt}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="seniority" title="Senioridade">
        {profile.seniority && (
          <Typography component="p" variant="body3">
            {SenioritiesLabels[profile.seniority]}
          </Typography>
        )}
      </ProfileItem>
    </Section>
  );
};

const InterestsSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="interests" title="Interesses">
      <ProfileItem id="course_types" title="Tipos de curso">
        {!!profile.course_types.length && (
          <Chips>
            {profile.course_types.map((type) => (
              <Chip
                key={type}
                label={CourseTypesLabels[type]}
                sx={{ textTransform: 'capitalize' }}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="knowledge_areas" title="Temas">
        {!!profile.knowledge_areas.length && (
          <Chips>
            {profile.knowledge_areas.map((area) => (
              <Chip
                key={area.id}
                label={area.description_pt_br}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="languages" title="Idiomas">
        {!!profile.languages.length && (
          <Chips>
            {profile.languages.map((language) => (
              <Chip
                key={language}
                label={LanguagesLabels[language]}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
    </Section>
  );
};

const PreferencesSection = ({ profile }: { profile: Partial<Profile> }) => {
  return (
    <Section id="preferences" title="Preferências">
      <ProfileItem id="address" title="Localização">
        {(profile.address_city || profile.address_state) && (
          <Typography component="p" variant="body3">
            {profile?.address_city} - {profile?.address_state}
          </Typography>
        )}
      </ProfileItem>
      <ProfileItem id="modalities" title="Modalidades">
        {!!profile.modalities.length && (
          <Chips>
            {profile.modalities.map((modality) => (
              <Chip
                key={modality}
                label={Modalities[modality]}
                variant="outlined"
              />
            ))}
          </Chips>
        )}
      </ProfileItem>
      <ProfileItem id="monthly_budget" title="Orçamento mensal">
        <Typography component="p" variant="body3">
          {profile.max_budget_per_month
            ? `Até ${formatMoney(profile.max_budget_per_month, false)}`
            : 'Qualquer valor'}
        </Typography>
      </ProfileItem>
      <ProfileItem id="weekly_availability" title="Disponibilidade semanal">
        {(profile.min_hours_available_per_week ||
          profile.max_hours_available_per_week) && (
          <Typography component="p" variant="body3">
            {!!profile.min_hours_available_per_week &&
              `Mais de ${formatDuration('hour', profile.min_hours_available_per_week)}`}
            {!!profile.max_hours_available_per_week &&
              `Até ${formatDuration('hour', profile.max_hours_available_per_week)}`}
          </Typography>
        )}
      </ProfileItem>
    </Section>
  );
};

const LoadingContent = () => {
  return (
    <Stack spacing={3}>
      <SectionSkeleton />
      <SectionSkeleton />
    </Stack>
  );
};

const Content = () => {
  const { isPending, profile } = getRecommendationProfile();
  const { actions: profileActions, context: profileContext } = useProfile();

  if (isPending) return <LoadingContent />;
  if (!profile) return null;

  return (
    <>
      <Box>
        <ObjectivesSection profile={profile} />
        <ExperiencesSection profile={profile} />
        <InterestsSection profile={profile} />
        <PreferencesSection profile={profile} />
      </Box>
      {!!profileContext && (
        <ProfileEditDialog
          context={profileContext}
          onClose={() => profileActions.edit(null)}
          open={!!profileContext}
          profile={profile}
        />
      )}
    </>
  );
};

export const PlanProfile: React.ElementType<WizardStepComponent> = ({
  onNext,
  onPrevious,
}) => {
  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious();
    }
  };

  const handleNext = () => {
    if (onNext) {
      onNext();
    }
  };

  return (
    <ContentBox mt={2} p={3}>
      <Stack gap={1}>
        <Box>
          <Typography variant="h6">
            Quais são seus objetivos e interesses para este plano?
          </Typography>
          <Typography variant="body3">
            Antes de seguir, revise os objetivos e interesses, a recomendação é
            gerada com base nestas informações. Essa ação pode alterar as suas
            recomendações na plataforma.
          </Typography>
        </Box>
        <Box>
          <Content />
        </Box>
      </Stack>
      <WizardStepActions>
        <Button color="primary" onClick={handlePrevious} variant="text">
          Voltar
        </Button>
        <Button
          color="primary"
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </Button>
      </WizardStepActions>
    </ContentBox>
  );
};
