import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { formatOfferFiltersParams } from '@/react/data/catalog/utils';
import { useCourseDetails } from '@/react/data/catalog/useCourseDetails';
import { useOfferFilters } from '@/react/data/catalog/useOfferFilters';
import {
  useFilters,
  getFilterValuesFromUrl,
} from '@/react/components/filters/state';
import { CourseDetailsContent, CourseDetailsHeader } from './course-details';
import { createSchema } from './offer-filters/offer-filters.schema';
import OfferDetails from './offer-details';
import { useProductPage } from './state';
import { ProductPageAsyncResources } from './state/interfaces';
import { useCourseSubscriptions } from '@/react/data/course/useCourseSubscriptions';
import { Routes as CoursesRoutes } from '@/pages/cursos/cursos.routes';

const BG_HEIGHT_IN_PX = 460;

const Styled = {
  HeaderBackground: styled('div')`
    background-color: ${({ theme }) => theme.palette.primary[900]};
    height: auto;
    padding: ${({ theme }) => theme.spacing(3, '0')};
    width: 100%;
    z-index: -1;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 0;
    }
  `,
  HeaderWrapper: styled('div')`
    display: flex;
    height: 100%;
  `,
  HeaderContent: styled('div')`
    width: 100%;
  `,
};

const handleNotFound = () =>
  window.location.assign(`cursos/${CoursesRoutes.NOT_FOUND}`);

export const ProductPage = ({ courseId }: { courseId: number }) => {
  const initialValues = getFilterValuesFromUrl();
  const {
    data: courseData,
    error: courseRequestError,
    institution,
    isFetching: courseDetailsLoading,
  } = useCourseDetails(courseId);
  const { actions: filtersActions, values: filtersValues } = useFilters();
  const { updateState: updateProductPageState } = useProductPage();

  const requestFiltersValues = React.useMemo(() => {
    const location = filtersValues.location || initialValues.location;
    const hub = filtersValues.hub || initialValues.hub;
    const period = filtersValues.period || initialValues.period;

    return {
      hub,
      location,
      period,
    };
  }, [filtersValues, initialValues]);

  const offerRequestParams = formatOfferFiltersParams({
    courseId,
    ...requestFiltersValues,
  });

  const {
    data: offerData,
    error: offerRequestError,
    isFetching: offerLoading,
  } = useOfferFilters({
    courseId,
    queryParams: offerRequestParams,
  });

  const {
    data: subscriptionsData,
    error: subscriptionsRequestError,
    isFetching: subscriptionsLoading,
  } = useCourseSubscriptions(courseId);

  const abortSchemaCreation =
    offerRequestError || offerLoading || !filtersValues || !offerData;

  const resetInvalidFilters = () => {
    const filters = Array.from(Object.keys(filtersValues)).filter(
      (key) => key !== 'location'
    );

    filtersActions.reset(filters);
  };

  React.useEffect(() => {
    if (abortSchemaCreation) {
      return;
    }

    let schema = createSchema(offerData);

    if (filtersValues.location) {
      const [state, city] = filtersValues.location;

      if (state && city) {
        Object.values(schema).forEach((node) => {
          if (node.id === 'hub') {
            node.props.disabled = false;
          }
        });
      }
    }

    if (filtersValues.location && filtersValues.hub) {
      Object.values(schema).forEach((node) => {
        if (node.id === 'period') {
          node.props.disabled = false;
        }
      });
    }

    filtersActions.setSchema(schema);
  }, [offerLoading, JSON.stringify(filtersValues), JSON.stringify(offerData)]);

  if (courseRequestError) {
    const { status } = courseRequestError;

    switch (status) {
      case 404:
        handleNotFound();
        break;
    }
  }

  if (offerRequestError) {
    const { status } = offerRequestError;

    switch (status) {
      case 400:
        resetInvalidFilters();
        break;
      case 404:
        handleNotFound();
        break;
    }
  }

  if (subscriptionsRequestError) {
    const { status } = subscriptionsRequestError;

    switch (status) {
      case 404:
        handleNotFound();
        break;
    }
  }

  React.useEffect(() => {
    if (offerLoading) {
      updateProductPageState((state) => ({
        ...state,
        loading: [...state.loading, ProductPageAsyncResources.offer],
      }));
    } else {
      if (!offerRequestError) {
        const offer = offerData.cheapest_offer;

        updateProductPageState((state) => ({
          ...state,
          offer,
          isSingleOffer: offerData.offers_quantity === 1,
        }));
      }

      updateProductPageState((state) => ({
        ...state,
        loading: state.loading.filter(
          (key) => key !== ProductPageAsyncResources.offer
        ),
      }));
    }
  }, [offerLoading]);

  React.useEffect(() => {
    if (courseDetailsLoading) {
      updateProductPageState((state) => ({
        ...state,
        loading: [...state.loading, ProductPageAsyncResources.course],
      }));
    } else {
      updateProductPageState((state) => ({
        ...state,
        course: courseData,
        institution,
        loading: state.loading.filter(
          (key) => key !== ProductPageAsyncResources.course
        ),
      }));
    }
  }, [courseDetailsLoading]);

  React.useEffect(() => {
    if (subscriptionsLoading) {
      updateProductPageState((state) => ({
        ...state,
        loading: [...state.loading, ProductPageAsyncResources.subscriptions],
      }));
    } else {
      updateProductPageState((state) => ({
        ...state,
        subscriptions: subscriptionsData,
        loading: state.loading.filter(
          (key) => key !== ProductPageAsyncResources.subscriptions
        ),
      }));
    }
  }, [subscriptionsLoading]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Styled.HeaderBackground>
        <Container fixed maxWidth="xl">
          <Grid
            container
            spacing={{ xs: 2, sm: 4 }}
            sx={{
              px: { xs: 1, md: '0' },
              height: () => ({
                xs: 'auto',
                md: BG_HEIGHT_IN_PX,
              }),
            }}
          >
            <Grid item xs={12} md={8}>
              <Styled.HeaderWrapper
                sx={{
                  flexDirection: 'column',
                  height: (theme) => ({
                    xs: 'auto',
                    md: `calc(${BG_HEIGHT_IN_PX}px - ${theme.spacing(4)})`,
                  }),
                  justifyContent: 'center',
                }}
              >
                <Styled.HeaderContent>
                  <CourseDetailsHeader />
                </Styled.HeaderContent>
              </Styled.HeaderWrapper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Styled.HeaderWrapper sx={{ alignItems: 'center' }}>
                <Styled.HeaderContent>
                  <Box
                    sx={{
                      marginBottom: { xs: 0, md: 4 },
                      marginTop: { xs: 1, md: 4 },
                    }}
                  >
                    <OfferDetails />
                  </Box>
                </Styled.HeaderContent>
              </Styled.HeaderWrapper>
            </Grid>
          </Grid>
        </Container>
      </Styled.HeaderBackground>
      <Container fixed maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, sm: 4 }}
          sx={[
            () => ({
              px: { xs: 1, md: '0' },
              '&&': {
                marginTop: '0',
              },
            }),
          ]}
        >
          <Grid item sm={12} md={7} lg={8}>
            <CourseDetailsContent />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
